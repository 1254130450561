.pl-nav-scroll {

  .desktop & {
    overflow: hidden;
    height: 100%;
  }

  .mCSB_inside>.mCSB_container {
    margin: 0;
  }
}

.pl-scroll {

  .desktop & {
    overflow: hidden;
    height: 100%;
  }

  .mCSB_inside>.mCSB_container {
    margin: 0;
  }
}

.sb-scroll {
  .mCSB_scrollTools {
    width: 7px;
  }
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #B0BEC5 !important;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}