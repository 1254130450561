@charset "UTF-8";
.types-banner--img img, .advantages-icon img {
  width: 100%;
  height: 100%;
  display: block; }

.types-banner--img img, .advantages-icon img {
  object-fit: cover; }

.header-logo .burger, .header-search--btn, .header-search--reset, .header-profile--auth, .header-personal_info--close, .burger_menu-close .burger_close, .auth_menu-close, .btn-link, .btn-expand, .form-spinner--btn, .modal-close, .cart_table-item--count_btn, .sidebar-filters--reset {
  background: none;
  border: none;
  border-radius: 0;
  outline: none; }

.auth_menu-radio_button, .form-checkbox .checkbox-item input, .checkbox_color-item input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
  opacity: 0; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

.content {
  margin: 0 auto;
  display: block; }
  @media (min-width: 64.0625em) {
    .content {
      padding: 0 32px; } }
  @media (min-width: 50em) and (max-width: 64.0525em) {
    .content {
      padding: 0 24px; } }
  @media (max-width: 49.99em) {
    .content {
      padding: 0 16px; } }
  .content--lg {
    max-width: 1680px; }
  .content--md {
    max-width: 1128px; }
  .content--sm {
    max-width: 980px; }
  .content--xs {
    max-width: 872px; }
  .content--xxs {
    max-width: 632px; }

.hide {
  display: none !important; }

.u-nowrap {
  white-space: nowrap; }

.u-align-center {
  text-align: center; }

@media (min-width: 64.0625em) {
  .desktop-hidden {
    display: none !important; } }

@media (max-width: 64.0525em) {
  .tablet-hidden {
    display: none !important; } }

@media (min-width: 50em) {
  .wide-hidden {
    display: none !important; } }

@media (max-width: 49.99em) {
  .mobile-hidden {
    display: none !important; } }

@media (max-width: 37.49em) {
  .small-hidden {
    display: none !important; } }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

* {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

template,
[hidden] {
  display: none; }

ol, ul {
  list-style: none; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  outline: none; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

iframe,
img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

address, caption, cite, code, dfn, th, var {
  font-weight: normal;
  font-style: normal; }

button,
input,
select,
textarea {
  font: inherit;
  margin: 0; }

optgroup {
  font-weight: bold; }

button,
input {
  overflow: visible; }

button,
select {
  cursor: pointer; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 0; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: inherit;
  opacity: 0.8; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: inherit;
  opacity: 0.8; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: inherit;
  opacity: 0.8; }

:-moz-placeholder {
  /* Firefox 18- */
  color: inherit;
  opacity: 0.8; }

@font-face {
  font-family: PTRoot;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/PT_Root_UI_Bold.woff"), url("../fonts/PT_Root_UI_Bold.woff2"); }

@font-face {
  font-family: PTRoot;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/PT_Root_UI_Medium.woff"), url("../fonts/PT_Root_UI_Medium.woff2"); }

@font-face {
  font-family: PTRoot;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/PT_Root_UI_Regular.woff"), url("../fonts/PT_Root_UI_Regular.woff2"); }

@font-face {
  font-family: PTRoot;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/PT_Root_UI_Light.woff"), url("../fonts/PT_Root_UI_Light.woff2"); }

html {
  min-height: 100%;
  font-size: 16px; }
  html.ovh {
    width: 100%;
    position: fixed;
    overflow-y: scroll; }

body {
  line-height: 1.4;
  font-weight: 400;
  color: #546E7A;
  font-family: PTRoot, sans-serif; }

*, *:after, *:before {
  box-sizing: border-box; }

a {
  outline: none;
  transition: all 0.3s; }

svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor; }

.main {
  background: #EEEEEE;
  position: relative;
  width: 100%;
  flex: 1 0 auto; }
  @media (min-width: 50em) {
    .main {
      transition: opacity 0.5s; }
      .blured .main {
        opacity: 0.3; } }
  @media (min-width: 37.5em) {
    .main {
      padding: 32px 0 80px; } }
  @media (max-width: 37.49em) {
    .main {
      padding: 16px 0 48px; } }

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100vh; }
  @media (min-width: 50em) {
    .wrapper {
      transition: all 0.3s; }
      .wrapper.right-origin {
        transform-origin: top right; }
      .wrapper.left-origin {
        transform-origin: top left; }
      .wrapper.blured {
        transition: transform 0.4s ease 0.1s;
        transform: scale(0.9); } }

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; }
  @media (min-width: 37.5em) and (max-width: 64.0525em) {
    .container {
      padding-top: 100px; } }
  @media (max-width: 37.49em) {
    .container {
      padding-top: 62px; } }

.header {
  flex-shrink: 0;
  background-color: #fff;
  z-index: 50;
  box-shadow: 0 2px 2px 0 rgba(84, 110, 122, 0.2); }
  @media (max-width: 64.0525em) {
    .header {
      position: fixed;
      left: 0;
      right: 0;
      top: 0; } }
  @media (min-width: 50em) {
    .header {
      transition: opacity 0.5s; }
      .blured .header {
        opacity: 0.3; } }
  .header-logo {
    display: flex;
    align-items: center;
    position: relative; }
    .header-logo .burger {
      flex-shrink: 0; }
      @media (min-width: 37.5em) {
        .header-logo .burger {
          margin-right: 24px; } }
      @media (max-width: 37.49em) {
        .header-logo .burger {
          margin-right: 16px; } }
  .header-language {
    font-size: 16px;
    font-weight: 500;
    position: relative; }
    @media (min-width: 80.0625em) {
      .header-language {
        margin-left: 24px;
        margin-right: 24px; } }
    @media (min-width: 50em) and (max-width: 80.0525em) {
      .header-language {
        margin-right: 24px;
        margin-left: 12px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .header-language {
        margin: 0 24px; } }
    @media (max-width: 29.99em) {
      .header-language {
        margin: 0 16px; } }
    @media screen and (min-width: 1025px) {
      .header-language:hover .header-language--list {
        opacity: 1;
        pointer-events: auto; } }
    @media (min-width: 64.0625em) {
      .header-language [data-lang] {
        padding-left: 40px;
        position: relative;
        display: block; }
        .header-language [data-lang]:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 32px;
          height: 24px;
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% auto;
          border: 1px solid #eee; }
      .header-language [data-lang="ru"]:before {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2024%22%20enable-background%3D%22new%200%200%2032%2024%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22m0%200v8%208%208h32v-8-8-8z%22/%3E%3Cpath%20fill%3D%22%230052b4%22%20d%3D%22m0%200h32v24h-32z%22/%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22m0%200h32v8h-32z%22/%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m0%2016h32v8h-32z%22/%3E%3C/svg%3E"); }
      .header-language [data-lang="en"]:before {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2024%22%20enable-background%3D%22new%200%200%2032%2024%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%3Cdefs%3E%3Cpath%20id%3D%220%22%20d%3D%22m19.5%2016.2l12.5%207.8v-2.2l-9-5.6z%22/%3E%3Cpath%20id%3D%221%22%20d%3D%22M12.5%207.8%200%200%200%202.2%209%207.8z%22/%3E%3C/defs%3E%3Cpath%20fill%3D%22%23f0f0f0%22%20d%3D%22m0%200h32v24h-32z%22/%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m18%200h-4v9.8h-14v4.5h14v9.7h4v-9.7h14v-4.5h-14z%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22m24.6%2016.2l7.4%204.6v-4.6z%22/%3E%3Cuse%20xlink%3Ahref%3D%22%230%22/%3E%3Cpath%20d%3D%22m28.7%2024l-9.2-5.7v5.7z%22/%3E%3C/g%3E%3Cuse%20fill%3D%22%23f0f0f0%22%20xlink%3Ahref%3D%22%230%22/%3E%3Cuse%20fill%3D%22%23d80027%22%20xlink%3Ahref%3D%22%230%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22m5.6%2016.2l-5.6%203.5v-3.5z%22/%3E%3Cpath%20d%3D%22m12.5%2017.2v6.8h-10.9z%22/%3E%3C/g%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m9%2016.2l-9%205.6v2.2l12.5-7.8z%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22M7.4%207.8%200%203.2%200%207.8z%22/%3E%3Cuse%20xlink%3Ahref%3D%22%231%22/%3E%3Cpath%20d%3D%22m3.3%200l9.2%205.7v-5.7z%22/%3E%3C/g%3E%3Cuse%20fill%3D%22%23f0f0f0%22%20xlink%3Ahref%3D%22%231%22/%3E%3Cuse%20fill%3D%22%23d80027%22%20xlink%3Ahref%3D%22%231%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22m26.4%207.8l5.6-3.5v3.5z%22/%3E%3Cpath%20d%3D%22m19.5%206.8v-6.8h10.9z%22/%3E%3C/g%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m23%207.8l9-5.6v-2.2l-12.5%207.8z%22/%3E%3C/svg%3E"); } }
    @media (max-width: 64.0525em) {
      .header-language.is-active .header-language--list {
        opacity: 1;
        pointer-events: auto; } }
    .header-language--selected {
      cursor: pointer; }
    .header-language--list {
      box-shadow: 0 20px 20px 0 rgba(84, 110, 122, 0.4);
      padding: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #fff;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s; }
      @media (min-width: 64.0625em) {
        .header-language--list {
          transform: translate(-50%, 44px); }
          .header-language--list:before {
            position: absolute;
            content: "";
            bottom: 100%;
            left: 0;
            right: 0;
            height: 44px; } }
      @media (max-width: 64.0525em) {
        .header-language--list {
          transform: translate(-50%, 24px); } }
      .header-language--list li:not(:last-child) {
        margin-bottom: 16px; }
      .header-language--list a {
        color: inherit; }
        @media screen and (min-width: 1025px) {
          .header-language--list a:hover {
            color: #1CB47A; } }
        .header-language--list a.selected {
          pointer-events: none;
          color: #1CB47A; }
  .header-currency {
    font-size: 16px;
    font-weight: 500;
    position: relative; }
    @media (min-width: 64.0625em) {
      .header-currency {
        margin-left: 0;
        margin-right: 24px; }
        .search-enabled .header-currency {
          display: none; } }
    @media (min-width: 50em) and (max-width: 64.0525em) {
      .header-currency {
        margin-right: 24px;
        margin-left: 0; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .header-currency {
        margin: 0 24px 0 0; } }
    @media (max-width: 29.99em) {
      .header-currency {
        margin: 0 16px 0 0; } }
    @media screen and (min-width: 1025px) {
      .header-currency:hover .header-currency--list {
        opacity: 1;
        pointer-events: auto; } }
    @media (max-width: 64.0525em) {
      .header-currency.is-active .header-currency--list {
        opacity: 1;
        pointer-events: auto; } }
    .header-currency--selected {
      cursor: pointer; }
    .header-currency--list {
      box-shadow: 0 20px 20px 0 rgba(84, 110, 122, 0.4);
      padding: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #fff;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s; }
      @media (min-width: 64.0625em) {
        .header-currency--list {
          transform: translate(-50%, 44px); }
          .header-currency--list:before {
            position: absolute;
            content: "";
            bottom: 100%;
            left: 0;
            right: 0;
            height: 44px; } }
      @media (max-width: 64.0525em) {
        .header-currency--list {
          transform: translate(-50%, 24px); } }
      .header-currency--list li:not(:last-child) {
        margin-bottom: 16px; }
      .header-currency--list a {
        color: inherit; }
        @media screen and (min-width: 1025px) {
          .header-currency--list a:hover {
            color: #1CB47A; } }
        .header-currency--list a.selected {
          pointer-events: none;
          color: #1CB47A; }
  .header-partials {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media (min-width: 80.0625em) {
      .header-partials {
        margin-right: 24px;
        margin-left: 24px; } }
    @media (min-width: 64.0625em) and (max-width: 80.0525em) {
      .header-partials {
        margin-right: 20px;
        margin-left: 20px; } }
    @media (min-width: 64.0625em) {
      .header-partials {
        position: relative;
        transition: all 0.25s; }
        .search-enabled .header-partials {
          background-color: #fff; } }
    @media (max-width: 64.0525em) {
      .header-partials {
        margin-right: 12px; } }
  @media (min-width: 80.0625em) {
    .header-domain {
      margin-left: 20px;
      padding-left: 20px;
      position: absolute;
      left: 100%;
      white-space: nowrap; } }
  @media (min-width: 50em) and (max-width: 80.0525em) {
    .header-domain {
      margin-left: 16px;
      padding-left: 16px;
      position: absolute;
      left: 100%;
      width: 25vw;
      font-size: 12px; } }
  @media (max-width: 49.99em) {
    .header-domain {
      display: none; } }
  .header-domain:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #B0BEC5; }
    @media (min-width: 80.0625em) {
      .header-domain:before {
        height: 48px;
        margin: auto; } }
    @media (min-width: 64.0625em) and (max-width: 80.0525em) {
      .header-domain:before {
        height: 32px;
        margin: auto; } }
  .header-search {
    position: relative;
    transition: all 0.25s; }
    @media (min-width: 64.0625em) {
      .header-search {
        width: 40px; }
        .header-search .header-search--fieldset {
          opacity: 0;
          pointer-events: none; }
        .header-search.active {
          width: 100%; }
          .header-search.active .header-search--opener {
            pointer-events: none;
            opacity: 0; }
          .header-search.active .header-search--fieldset {
            pointer-events: auto;
            opacity: 1; } }
    @media (min-width: 50em) and (max-width: 64.0525em) {
      .header-search {
        right: 24px; }
        .header-search.active {
          width: calc(100% - 48px); } }
    @media (max-width: 49.99em) {
      .header-search {
        right: 16px; }
        .header-search.active {
          width: calc(100% - 32px); } }
    @media (max-width: 64.0525em) {
      .header-search {
        position: absolute;
        bottom: 4px;
        width: 30px;
        transition: width 0.2s;
        z-index: 1; }
        .header-search.active .header-search--opener {
          pointer-events: none; }
        .header-search.active .header-search--fieldset input {
          opacity: 1;
          pointer-events: auto;
          padding: 0 60px 0 24px; }
        .header-search.active .header-search--btn {
          background: transparent;
          pointer-events: auto; }
        .header-search.active .header-search--reset {
          display: flex; } }
    @media (max-width: 37.49em) {
      .header-search {
        display: none; } }
    .header-search--opener {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      color: #7B1FA2;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 64.0625em) {
        .header-search--opener {
          width: 40px;
          height: 40px; } }
      @media (max-width: 64.0525em) {
        .header-search--opener {
          width: 30px;
          height: 30px;
          opacity: 0; } }
    .header-search--fieldset {
      width: 100%;
      display: flex;
      position: relative; }
      .header-search--fieldset input {
        width: 100%;
        border: none;
        border-radius: 20px;
        background: #EEEEEE;
        color: #546E7A;
        outline: none; }
        @media (min-width: 64.0625em) {
          .header-search--fieldset input {
            height: 40px;
            padding: 0 130px 0 24px; } }
        @media (max-width: 64.0525em) {
          .header-search--fieldset input {
            height: 30px;
            padding: 0 0 0 24px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.2s; } }
        .header-search--fieldset input:focus ~ .header-search--reset {
          opacity: 1;
          pointer-events: auto; }
    .header-search--btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #7B1FA2;
      color: #fff;
      font-weight: 600;
      border-radius: 20px; }
      @media (min-width: 64.0625em) {
        .header-search--btn {
          width: 96px; }
          .header-search--btn .icon {
            display: none; } }
      @media (max-width: 64.0525em) {
        .header-search--btn {
          pointer-events: none;
          width: 30px;
          height: 30px;
          background: #fff; }
          .header-search--btn .icon {
            color: #546E7A; }
          .header-search--btn .text {
            display: none; } }
    .header-search--reset {
      color: #546E7A;
      position: absolute;
      width: 28px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 100px;
      top: 0;
      opacity: 0;
      pointer-events: none; }
      @media (max-width: 64.0525em) {
        .header-search--reset {
          height: 30px;
          right: 30px;
          display: none; } }
      .header-search--reset .icon {
        width: 24px;
        height: 24px; }
      .filled .header-search--reset {
        opacity: 1;
        pointer-events: auto; }
  .header-profile {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .header-profile--auth {
      display: inline-flex;
      position: relative; }
      @media screen and (min-width: 1025px) {
        .header-profile--auth:hover .header-profile--auth_drop {
          opacity: 1;
          pointer-events: auto; }
        .header-profile--auth:hover .icon {
          position: relative; }
          .header-profile--auth:hover .icon:before {
            content: "";
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            width: 32px; } }
      .header-profile--auth .show-auth-menu {
        display: none; }
      @media (max-width: 64.0525em) {
        .header-profile--auth.is-active .header-profile--auth_drop {
          opacity: 1;
          pointer-events: auto; }
        .header-profile--auth .show-auth-menu {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0; } }
      .header-profile--auth_drop {
        z-index: 1;
        transition: opacity 0.25s;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background: #fff;
        top: 100%;
        box-shadow: 0 10px 20px 0 rgba(84, 110, 122, 0.5);
        padding: 0 32px 32px; }
        @media (min-width: 64.0625em) {
          .header-profile--auth_drop {
            width: 528px;
            right: -8px; } }
        @media (max-width: 64.0525em) {
          .header-profile--auth_drop {
            width: 92vw;
            max-width: 380px;
            right: -80px;
            max-height: 86vh;
            overflow: auto; } }
      .header-profile--auth_menu a {
        color: #546E7A;
        text-transform: uppercase;
        font-weight: 600;
        display: inline-flex;
        align-items: center; }
        @media screen and (min-width: 1025px) {
          .header-profile--auth_menu a:hover {
            color: #1CB47A; } }
        .header-profile--auth_menu a.active {
          color: #1CB47A; }
      .header-profile--auth_menu li {
        margin-bottom: 32px; }
      .header-profile--auth_menu .icon {
        width: 24px;
        height: 24px;
        margin-right: 10px; }
  .header-personal_info {
    display: flex;
    align-items: center; }
    .header-personal_info .link-icon {
      display: inline-flex;
      align-items: center;
      position: relative; }
    .header-personal_info .count {
      position: absolute;
      top: 0;
      left: 100%;
      transform: translate(-12px, -8px);
      font-weight: 500;
      color: #fff;
      background-color: #FF5252;
      border-radius: 24px; }
      @media (min-width: 80.0625em) {
        .header-personal_info .count {
          line-height: 24px;
          padding: 0 8px; } }
      @media (min-width: 37.5em) and (max-width: 80.0525em) {
        .header-personal_info .count {
          font-size: 12px;
          padding: 0 6px;
          line-height: 20px; } }
      @media (max-width: 37.49em) {
        .header-personal_info .count {
          font-size: 10px;
          padding: 0 4px;
          line-height: 16px; } }
    .header-personal_info--close {
      position: absolute;
      top: 12px;
      right: 12px;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20fill%3D%22%23546e7a%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012%2019%206.41z%22/%3E%3C/svg%3E");
      background-repeat: no-repeat; }
    .header-personal_info--views, .header-personal_info--favorites, .header-personal_info--basket {
      display: inline-flex;
      align-items: center;
      position: relative; }
    .header-personal_info--item {
      position: relative;
      display: inline-flex;
      align-items: center; }
      @media (min-width: 80.0625em) {
        .header-personal_info--item {
          margin-left: 32px; } }
      @media (min-width: 37.5em) and (max-width: 80.0525em) {
        .header-personal_info--item {
          margin-left: 24px; } }
      @media (max-width: 37.49em) {
        .header-personal_info--item {
          margin-left: 12px; } }
      @media screen and (min-width: 1025px) {
        .header-personal_info--item:hover .header-personal_info--short:not(.added-item) {
          opacity: 1;
          pointer-events: auto; }
        .header-personal_info--item:hover .link-icon:before {
          content: "";
          position: absolute;
          right: 100%;
          top: 0;
          bottom: 0;
          width: 32px; } }
    .header-personal_info--short {
      z-index: 1;
      transition: opacity 0.25s;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      background: #fff;
      width: 528px;
      top: 100%;
      right: -8px;
      box-shadow: 0 10px 20px 0 rgba(84, 110, 122, 0.5);
      padding: 0 32px 32px; }
      .header-personal_info--short_title {
        height: 48px;
        display: flex;
        align-items: center;
        background-color: #EEEEEE;
        margin-left: -32px;
        margin-right: -32px;
        padding: 0 32px; }
        .header-personal_info--short_title:not(:last-child) {
          margin-bottom: 24px; }
      .header-personal_info--short.added-item {
        display: none;
        padding: 0; }
        .header-personal_info--short.added-item.active {
          opacity: 1;
          pointer-events: auto;
          padding: 0 32px 32px; }
      .added-order .header-personal_info--short:not(.added-item) {
        display: none; }
      @media (max-width: 64.0525em) {
        .header-personal_info--short {
          display: none !important; } }
    .header-personal_info--amount {
      margin-top: 24px; }
      .header-personal_info--amount_title {
        font-size: 16px;
        color: #546E7A; }
      .header-personal_info--amount .summ {
        font-weight: 600;
        font-size: 48px;
        margin-right: 24px;
        white-space: nowrap; }
    .header-personal_info--actions {
      margin-top: 24px; }
      .header-personal_info--actions_title {
        font-size: 16px;
        color: #546E7A; }
    .header-personal_info--buttons {
      display: flex;
      align-items: center; }
      .header-personal_info--buttons .btn:not(:last-child) {
        margin-right: 12px; }
    .header-personal_info--total {
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 500;
      cursor: default;
      color: #546E7A; }
      @media (max-width: 37.49em) {
        .header-personal_info--total {
          display: none; } }
      .header-personal_info--total .summ {
        white-space: nowrap; }
        @media (min-width: 80.0625em) {
          .header-personal_info--total .summ {
            font-size: 16px; } }
        @media (max-width: 80.0525em) {
          .header-personal_info--total .summ {
            font-weight: 600; } }
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 64.0625em) {
      .header-top {
        height: 88px; } }
    @media (max-width: 64.0525em) {
      .header-top {
        height: 62px; } }
  .header-nav {
    display: flex;
    width: 100%; }
    @media (min-width: 64.0625em) {
      .header-nav {
        height: 48px;
        font-size: 16px; } }
    @media (min-width: 50em) and (max-width: 64.0525em) {
      .header-nav {
        height: 38px;
        font-size: 12px; } }
    @media (max-width: 49.99em) {
      .header-nav {
        height: 38px;
        font-size: 9px; } }
    .header-nav--wrap {
      background: linear-gradient(to right, #7B1FA2 0%, #18088D 100%); }
      @media (max-width: 37.49em) {
        .header-nav--wrap {
          display: none; } }
    .header-nav--list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      font-weight: 500; }
      @media (max-width: 64.0525em) {
        .header-nav--list:last-child:after {
          content: "";
          width: 30px;
          height: 38px; } }
      .header-nav--list li {
        display: flex; }
      .header-nav--list a {
        display: inline-flex;
        align-items: center;
        color: #fff;
        position: relative; }
        .header-nav--list a:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          right: 50%;
          height: 4px;
          background-color: #FF5252;
          transition: all 0.25s; }
        @media screen and (min-width: 1025px) {
          .header-nav--list a:hover:after {
            left: 0;
            right: 0; } }
        .header-nav--list a.active:after {
          left: 0;
          right: 0; }

.footer {
  flex-shrink: 0;
  background-color: #fff;
  position: relative; }
  @media (min-width: 50em) {
    .footer {
      transition: opacity 0.5s;
      padding-top: 85px; }
      .blured .footer {
        opacity: 0.3; } }
  @media (max-width: 49.99em) {
    .footer {
      padding-top: 60px; } }
  .footer-scroll_top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-50%); }
    .footer-scroll_top--btn {
      margin-left: 100%;
      transform: translateX(-100%); }
  .footer-top {
    width: 100%;
    font-weight: 500;
    color: #546E7A;
    margin-bottom: 60px; }
    @media (min-width: 50em) {
      .footer-top {
        display: flex;
        font-size: 32px; } }
    @media (max-width: 49.99em) {
      .footer-top {
        font-size: 24px; } }
    @media (min-width: 50em) {
      .footer-top--slogan {
        width: 58.333%; } }
    @media (max-width: 49.99em) {
      .footer-top--slogan {
        margin-bottom: 24px; } }
    .footer-top--telephone a {
      color: inherit; }
  .footer-nav {
    display: flex; }
    @media (max-width: 80.0525em) {
      .footer-nav {
        flex-wrap: wrap; } }
    @media (min-width: 50em) {
      .footer-nav {
        margin-bottom: 40px; } }
    .footer-nav--section {
      padding-right: 16px; }
      @media (min-width: 80.0625em) {
        .footer-nav--section {
          width: 25%; } }
      @media (min-width: 50em) and (max-width: 80.0525em) {
        .footer-nav--section {
          width: 33.33%; } }
      @media (min-width: 30em) and (max-width: 49.99em) {
        .footer-nav--section {
          width: 50%;
          margin-bottom: 32px; } }
      @media (max-width: 29.99em) {
        .footer-nav--section {
          width: 100%;
          margin-bottom: 32px; } }
      @media (min-width: 50em) and (max-width: 80.0525em) {
        .footer-nav--section.nav-payments {
          width: 100%;
          order: 4;
          margin-top: 32px; } }
      @media (max-width: 49.99em) {
        .footer-nav--section.nav-payments {
          width: 100%;
          order: 4; } }
      .footer-nav--section_title {
        font-weight: 500;
        text-transform: uppercase; }
        @media (min-width: 50em) {
          .footer-nav--section_title {
            margin-bottom: 32px; } }
        @media (max-width: 49.99em) {
          .footer-nav--section_title {
            margin-bottom: 24px; } }
      .footer-nav--section_payments {
        margin: 0 -4px;
        display: flex;
        flex-wrap: wrap; }
        .footer-nav--section_payments li {
          padding: 4px; }
        .footer-nav--section_payments a {
          display: flex;
          background-color: #EEEEEE;
          align-items: center;
          justify-content: center; }
          @media (min-width: 88.8125em) {
            .footer-nav--section_payments a {
              width: 80px;
              height: 80px; } }
          @media (max-width: 88.8025em) {
            .footer-nav--section_payments a {
              width: 60px;
              height: 60px; } }
          @media screen and (min-width: 1025px) {
            .footer-nav--section_payments a:hover {
              background: none; } }
        .footer-nav--section_payments img {
          max-width: 90%;
          max-height: 90%; }
      .footer-nav--section_links {
        font-size: 16px;
        color: #546E7A; }
        .footer-nav--section_links li {
          margin-bottom: 12px; }
        .footer-nav--section_links a {
          color: inherit; }
        @media (min-width: 30em) and (max-width: 49.99em) {
          .footer-nav--section_links.columns {
            width: 200%; } }
        @media (min-width: 30em) {
          .footer-nav--section_links.columns {
            columns: 2 auto; } }
        .footer-nav--section_links.columns li {
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid; }
  .footer-bottom {
    padding: 16px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; }
    .footer-bottom--web a {
      color: inherit; }

.burger_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  transition: transform 0.4s;
  will-change: transform; }
  @media (min-width: 50em) {
    .burger_menu {
      transform: translateX(-100%); } }
  @media (max-width: 49.99em) {
    .burger_menu {
      transform: translateX(-110%); } }
  .burger_menu.opened {
    transform: translateX(0);
    pointer-events: auto; }
  .burger_menu .content {
    height: 100%; }
  .burger_menu-blur {
    position: absolute;
    left: 0;
    width: 220%;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity 1s;
    pointer-events: none; }
    .opened .burger_menu-blur {
      opacity: 1;
      pointer-events: auto; }
  .burger_menu-wrap {
    height: 100%;
    position: relative; }
    @media (min-width: 64.0625em) {
      .burger_menu-wrap {
        width: 66.6667%; } }
    @media (min-width: 50em) and (max-width: 64.0525em) {
      .burger_menu-wrap {
        width: 80vw;
        max-width: 640px; } }
  .burger_menu-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    background: linear-gradient(45deg, #18088D 0%, #7B1FA2 100%); }
    @media (min-width: 105em) {
      .burger_menu-bg {
        left: calc(800px - 50vw);
        right: 0; } }
    @media (min-width: 50em) and (max-width: 105em) {
      .burger_menu-bg {
        left: -32px;
        right: 0; } }
    @media (max-width: 49.99em) {
      .burger_menu-bg {
        left: -32px;
        right: -32px; } }
    @media (min-width: 37.5em) {
      .burger_menu-bg {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5); } }
  .burger_menu-main {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 48px; }
    .mobile .burger_menu-main,
    .tablet .burger_menu-main {
      padding-bottom: 60px; }
  .burger_menu-close {
    display: flex;
    align-items: center;
    flex-shrink: 0; }
    @media (min-width: 64.0625em) {
      .burger_menu-close {
        height: 88px;
        margin-bottom: 96px; } }
    @media (max-width: 64.0525em) {
      .burger_menu-close {
        height: 62px;
        margin-bottom: 24px; } }
  .burger_menu-invite {
    font-weight: 300; }
    @media (max-width: 107.8025em) {
      .burger_menu-invite {
        margin-left: 24px; } }
    @media (min-width: 80.0625em) {
      .burger_menu-invite {
        font-size: 48px; } }
    @media (min-width: 30em) and (max-width: 80.0525em) {
      .burger_menu-invite {
        font-size: 32px; } }
    @media (min-width: 37.5em) {
      .burger_menu-invite {
        padding-bottom: 48px; } }
    @media (max-width: 37.49em) {
      .burger_menu-invite {
        padding-bottom: 32px; } }
    @media (max-width: 29.99em) {
      .burger_menu-invite {
        font-size: 24px; } }
    .burger_menu-invite:not(.no-auth) {
      flex-shrink: 0; }
      @media (min-width: 37.5em) {
        .burger_menu-invite:not(.no-auth) {
          min-height: 96px; } }
    @media (min-width: 37.5em) {
      .burger_menu-invite.no-auth {
        height: 96px; } }
    .mobile .burger_menu-invite.no-auth,
    .tablet .burger_menu-invite.no-auth {
      padding-bottom: 24px;
      height: 0; }
  .burger_menu-nav {
    font-weight: 500;
    display: flex;
    margin-left: 48px; }
    @media (max-width: 37.49em) {
      .burger_menu-nav {
        flex-direction: column; } }
    .burger_menu-nav--wrap {
      flex-grow: 1;
      overflow: hidden; }
      @media (min-width: 107.8125em) {
        .burger_menu-nav--wrap {
          margin-left: -48px; } }
      @media (max-width: 107.8025em) {
        .burger_menu-nav--wrap {
          margin-left: -24px; } }
      .mobile .burger_menu-nav--wrap,
      .tablet .burger_menu-nav--wrap {
        overflow: auto;
        margin-bottom: 40px; }
    .burger_menu-nav a:not(.btn) {
      color: inherit; }
  @media (min-width: 37.5em) {
    .burger_menu-site_menu {
      width: 62.5%;
      padding-right: 16px; } }
  @media (min-width: 37.5em) {
    .burger_menu-site_menu--item {
      margin-bottom: 48px; } }
  @media (max-width: 37.49em) {
    .burger_menu-site_menu--item {
      margin-bottom: 24px; } }
  .burger_menu-site_menu--item:last-child {
    margin-bottom: 0; }
  .burger_menu-site_menu--link {
    cursor: pointer; }
    @media (min-width: 80.0625em) {
      .burger_menu-site_menu--link {
        font-size: 32px; } }
    @media (min-width: 50em) and (max-width: 80.0525em) {
      .burger_menu-site_menu--link {
        font-size: 24px; } }
    @media (max-width: 49.99em) {
      .burger_menu-site_menu--link {
        font-size: 20px; } }
    .burger_menu-site_menu--link.fn-expand-btn {
      position: relative; }
      .burger_menu-site_menu--link.fn-expand-btn:before {
        content: "";
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 12px;
        height: 12px;
        border-style: solid solid none none;
        border-width: 3px;
        border-color: currentColor;
        transform: rotate(45deg) translate(-2px, 2px);
        transition: transform 0.25s; }
        @media (min-width: 64.0625em) {
          .burger_menu-site_menu--link.fn-expand-btn:before {
            margin: auto 24px auto 0; } }
        @media (max-width: 64.0525em) {
          .burger_menu-site_menu--link.fn-expand-btn:before {
            margin: auto 12px auto 0; } }
        .expanded .burger_menu-site_menu--link.fn-expand-btn:before {
          transform: rotate(135deg) translate(-2px, 2px); }
  @media (min-width: 37.5em) {
    .burger_menu-site_menu--subitem {
      margin-top: 32px; } }
  @media (max-width: 37.49em) {
    .burger_menu-site_menu--subitem {
      margin-top: 24px; } }
  .burger_menu-site_menu--sublink {
    font-size: 16px;
    text-transform: uppercase; }
  .burger_menu-auth_menu {
    text-transform: uppercase; }
    @media (min-width: 80.0625em) {
      .burger_menu-auth_menu {
        padding-top: 16px; } }
    @media (min-width: 37.5em) and (max-width: 80.0525em) {
      .burger_menu-auth_menu {
        padding-top: 8px; } }
    @media (min-width: 37.5em) {
      .burger_menu-auth_menu {
        width: 37.5%;
        padding-right: 16px;
        font-size: 16px; } }
    @media (max-width: 37.49em) {
      .burger_menu-auth_menu {
        font-size: 14px;
        margin-top: 48px; } }
    @media (min-width: 37.5em) {
      .burger_menu-auth_menu--item {
        margin-bottom: 32px; } }
    @media (max-width: 37.49em) {
      .burger_menu-auth_menu--item {
        margin-bottom: 20px; } }
    .burger_menu-auth_menu--item:last-child {
      margin-bottom: 0; }

.auth_menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  transition: transform 0.4s; }
  @media (min-width: 50em) {
    .auth_menu {
      transform: translateX(100%); } }
  @media (max-width: 49.99em) {
    .auth_menu {
      transform: translateX(110%); } }
  .auth_menu.opened {
    transform: translateX(0);
    pointer-events: auto; }
  .auth_menu .content {
    height: 100%; }
  .auth_menu-blur {
    position: absolute;
    right: 0;
    width: 200%;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.35s;
    pointer-events: none; }
    .opened .auth_menu-blur {
      opacity: 1;
      pointer-events: auto; }
  .auth_menu-wrap {
    position: relative;
    margin-left: auto;
    height: 100%; }
    @media (min-width: 64.0625em) {
      .auth_menu-wrap {
        width: 50%; } }
    @media (min-width: 50em) and (max-width: 64.0525em) {
      .auth_menu-wrap {
        width: 80vw;
        max-width: 640px; } }
  .auth_menu-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fff; }
    @media (min-width: 105em) {
      .auth_menu-bg {
        right: calc(800px - 50vw);
        left: 0; } }
    @media (min-width: 50em) and (max-width: 105em) {
      .auth_menu-bg {
        right: -32px;
        left: 0; } }
    @media (max-width: 49.99em) {
      .auth_menu-bg {
        left: -32px;
        right: -32px; } }
    @media (min-width: 37.5em) {
      .auth_menu-bg {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5); } }
  .auth_menu-close {
    position: absolute;
    z-index: 1;
    color: #546E7A; }
    @media (min-width: 37.5em) {
      .auth_menu-close {
        top: 12px;
        right: 8px; } }
    @media (max-width: 37.49em) {
      .auth_menu-close {
        top: 8px;
        right: 0; } }
    .auth_menu-close .icon {
      color: inherit; }
  .auth_menu-main {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 80.0625em) {
      .auth_menu-main {
        padding: 48px 8% 48px 16.7%; } }
    @media (min-width: 37.5em) and (max-width: 80.0525em) {
      .auth_menu-main {
        padding: 48px; } }
    @media (min-width: 30em) and (max-width: 37.49em) {
      .auth_menu-main {
        padding: 48px 32px 32px; } }
    @media (max-width: 29.99em) {
      .auth_menu-main {
        padding: 48px 8px 32px; } }
    .mobile .auth_menu-main,
    .tablet .auth_menu-main {
      overflow-y: auto;
      display: block; }
  .auth_menu-selects {
    display: flex;
    margin-bottom: 32px;
    flex-shrink: 0; }
    @media (min-width: 37.5em) {
      .auth_menu-selects {
        padding-right: 24px; } }
    .auth_menu-selects--option {
      height: 64px;
      display: inline-flex;
      align-items: center;
      padding: 0 24px;
      font-weight: 500;
      text-transform: uppercase;
      color: #546E7A;
      cursor: pointer;
      background: #fff;
      transition: all 0.25s;
      position: relative;
      user-select: none; }
      .auth_menu-selects--option:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background: #7B1FA2; }
      .auth_menu-selects--option:first-child {
        flex-shrink: 0; }
      .auth_menu-selects--option:not(:first-child) {
        width: 100%; }
  .auth_menu-forms {
    overflow: hidden;
    display: flex;
    width: 100%; }
    .auth_menu-forms [data-auth] {
      width: 100%;
      min-width: 100%; }
      .mobile .auth_menu-forms [data-auth],
      .tablet .auth_menu-forms [data-auth] {
        max-height: 50vh; }
    .auth_menu-forms--section {
      width: 100%; }
      @media (min-width: 37.5em) {
        .auth_menu-forms--section {
          padding-right: 24px; } }
      .mobile .auth_menu-forms--section,
      .tablet .auth_menu-forms--section {
        margin-bottom: 60px; }
  .auth_menu input[data-auth="signin"]:checked ~ .auth_menu-forms [data-auth="signin"],
  .auth_menu input[data-auth="signup"]:checked ~ .auth_menu-forms [data-auth="signup"] {
    order: -1; }
    .mobile .auth_menu input[data-auth="signin"]:checked ~ .auth_menu-forms [data-auth="signin"],
    .tablet .auth_menu input[data-auth="signin"]:checked ~ .auth_menu-forms [data-auth="signin"], .mobile
    .auth_menu input[data-auth="signup"]:checked ~ .auth_menu-forms [data-auth="signup"],
    .tablet
    .auth_menu input[data-auth="signup"]:checked ~ .auth_menu-forms [data-auth="signup"] {
      max-height: none; }
  .auth_menu input[data-auth="signin"]:checked ~ .auth_menu-selects [data-auth="signin"]:after,
  .auth_menu input[data-auth="signup"]:checked ~ .auth_menu-selects [data-auth="signup"]:after {
    height: 4px; }

.sidebar-sticky {
  will-change: transform;
  position: relative;
  z-index: 2; }
  @media (min-width: 64.0625em) {
    .sidebar-sticky {
      padding-bottom: 8px; } }
  @media (max-width: 64.0525em) {
    .sticky-mobile-disable .sidebar-sticky {
      position: static;
      width: auto !important;
      transform: none !important; } }
  .sidebar-sticky.sticky {
    position: fixed; }
  .sidebar-sticky.temp-sticky {
    position: relative; }

@media (max-width: 64.0525em) {
  .sticky-mobile-disable {
    height: auto !important; } }

.btn {
  border: none;
  border-radius: 0;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  transition: all 0.18s;
  outline: none;
  letter-spacing: 2px;
  white-space: nowrap; }
  .btn-link {
    color: #546E7A;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px; }
  .btn-grey {
    color: #fff;
    text-transform: uppercase;
    background-color: #B0BEC5;
    font-size: 16px;
    font-weight: 600;
    padding: 0 24px; }
  .btn-red {
    color: #FF5252;
    text-transform: lowercase;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    padding: 0 24px;
    border: 2px solid #FF5252; }
    .btn-red.large-padding {
      padding: 0 32px; }
    @media screen and (min-width: 1025px) {
      .btn-red:hover {
        color: #fff;
        background-color: #FF5252; } }
  .btn-white {
    color: #FF5252;
    text-transform: lowercase;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    padding: 0 24px;
    border: 2px solid transparent; }
    @media screen and (min-width: 1025px) {
      .btn-white:hover {
        color: #ff1f1f; } }
  .btn-green {
    color: #546E7A;
    background-color: #E4FFF9;
    font-size: 16px;
    padding: 0 24px; }
    @media (max-width: 29.99em) {
      .modal_catalog-link .btn-green {
        height: 56px;
        line-height: 1.2;
        align-items: center;
        font-size: 14px; } }
  .btn-acid {
    text-transform: uppercase;
    color: #fff;
    background-color: #1CB47A;
    font-size: 16px;
    line-height: 40px;
    padding: 0 24px;
    font-weight: 600; }
    @media screen and (min-width: 1025px) {
      .btn-acid:hover {
        background-color: #199e6b; } }
  .btn-red-fill {
    color: #fff;
    text-transform: uppercase;
    background-color: #FF5252;
    font-size: 16px;
    font-weight: 600;
    padding: 0 24px;
    border: 2px solid #FF5252; }
    @media screen and (min-width: 1025px) {
      .btn-red-fill:hover {
        border-color: #E94040;
        background-color: #E94040; } }
  .btn-expand {
    background: #546E7A;
    position: relative;
    display: block;
    color: #fff; }
    @media (min-width: 50em) {
      .btn-expand {
        width: 56px;
        height: 56px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .btn-expand {
        width: 40px;
        height: 40px; } }
    @media (max-width: 29.99em) {
      .btn-expand {
        width: 32px;
        height: 32px; } }
    .btn-expand:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-style: solid solid none none;
      border-color: currentColor;
      border-width: 3px;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      transition: all 0.25s;
      transform: rotate(135deg) translate(-2px, 2px); }
      @media (max-width: 29.99em) {
        .btn-expand:after {
          width: 12px;
          height: 12px; } }
    .expanded .btn-expand:after,
    .opened .btn-expand:after {
      transform: rotate(315deg) translate(-2px, 2px); }
  .btn--sm {
    line-height: 44px; }
  .btn--md {
    line-height: 48px; }
  .btn--lg {
    line-height: 56px; }
  .btn--full {
    width: 100%; }

.link {
  position: relative; }
  .link:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #1CB47A;
    opacity: 0;
    transform: translateY(4px);
    transition: all 0.25s;
    pointer-events: none; }
  @media screen and (min-width: 1025px) {
    .link:hover {
      color: #1CB47A; }
      .link:hover:after {
        opacity: 1;
        transform: translateY(0); } }
  .link.active {
    color: #1CB47A; }
    .link.active:after {
      opacity: 1;
      transform: translateY(0); }
  .link-simple {
    color: #546E7A; }
    @media screen and (min-width: 1025px) {
      .link-simple:hover {
        color: #1CB47A; } }
    .link-simple.active {
      color: #1CB47A; }

.arrow {
  position: relative;
  border: none;
  display: block;
  border-radius: 50%;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px 0 rgba(33, 33, 33, 0.2); }
  @media (min-width: 64.0625em) {
    .arrow {
      width: 80px;
      height: 80px; } }
  @media (max-width: 64.0525em) {
    .arrow {
      width: 60px;
      height: 60px; } }
  .arrow:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-style: solid solid none none;
    border-color: #B0BEC5;
    border-width: 3px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0; }
  .arrow-prev:after {
    transform: rotate(-135deg) translate(-2px, 2px); }
  .arrow-next:after {
    transform: rotate(45deg) translate(-2px, 2px); }

.title-heading {
  display: flex;
  line-height: 1; }
  .title-heading.margin-md {
    margin-bottom: 24px; }
  @media (min-width: 50em) {
    .title-heading.margin-xl {
      margin-bottom: 56px; } }
  @media (max-width: 49.99em) {
    .title-heading.margin-xl {
      margin-bottom: 32px; } }
  .title-heading--sub {
    font-size: 16px;
    font-weight: 400;
    align-self: flex-end;
    margin-left: 8px; }
  @media (min-width: 80.0625em) {
    .title-heading--lg {
      padding: 64px 0; } }
  @media (min-width: 50em) and (max-width: 80.0525em) {
    .title-heading--lg {
      padding: 40px 0; } }
  @media (max-width: 49.99em) {
    .title-heading--lg {
      padding: 24px 0; } }

.title-xl {
  font-weight: 600; }
  @media (min-width: 50em) {
    .title-xl {
      font-size: 48px; } }
  @media (max-width: 49.99em) {
    .title-xl {
      font-size: 32px; } }

.title-lg {
  font-weight: 500;
  color: #546E7A; }
  @media (min-width: 50em) {
    .title-lg {
      font-size: 32px; } }
  @media (max-width: 49.99em) {
    .title-lg {
      font-size: 24px; } }

@media (min-width: 64.0625em) {
  .title-article {
    padding: 48px 0 80px; } }

@media (min-width: 50em) and (max-width: 64.0525em) {
  .title-article {
    padding: 32px 0 60px; } }

@media (max-width: 49.99em) {
  .title-article {
    padding: 8px 0 40px; } }

.title-reg {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #546E7A; }

.img-heading {
  position: relative; }
  @media (min-width: 64.0625em) {
    .img-heading--bg {
      bottom: -56px; } }
  @media (max-width: 64.0525em) {
    .img-heading--bg {
      bottom: -16px; } }
  @media (min-width: 50em) {
    .img-heading--bg {
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: -50vw;
      right: -50vw;
      background-size: 100vw auto;
      background-position: center 45%; } }
  @media (max-width: 49.99em) {
    .img-heading--bg {
      display: none; } }
  .img-heading .title-heading {
    position: relative; }
    @media (min-width: 64.0625em) {
      .img-heading .title-heading {
        justify-content: center;
        margin: 0 auto;
        text-align: center; } }
    @media (min-width: 50em) {
      .img-heading .title-heading {
        padding: 56px 0; } }
    @media (max-width: 49.99em) {
      .img-heading .title-heading {
        padding: 24px 0; } }
    @media (min-width: 30em) {
      .img-heading .title-heading {
        max-width: 60%; } }
  .img-heading + * {
    position: relative; }

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .icon-auth {
    color: #7B1FA2; }
    @media (min-width: 80.0625em) {
      .icon-auth {
        width: 32px;
        height: 32px; } }
    @media (min-width: 30em) and (max-width: 80.0525em) {
      .icon-auth {
        width: 28px;
        height: 28px; } }
    @media (max-width: 29.99em) {
      .icon-auth {
        width: 20px;
        height: 20px; } }
  .icon-view {
    color: #7B1FA2; }
    @media (min-width: 80.0625em) {
      .icon-view {
        width: 32px;
        height: 32px; } }
    @media (max-width: 80.0525em) {
      .icon-view {
        width: 28px;
        height: 28px; } }
  .icon-favorite {
    color: #7B1FA2; }
    @media (min-width: 80.0625em) {
      .icon-favorite {
        width: 32px;
        height: 32px; } }
    @media (min-width: 30em) and (max-width: 80.0525em) {
      .icon-favorite {
        width: 28px;
        height: 28px; } }
    @media (max-width: 29.99em) {
      .icon-favorite {
        width: 20px;
        height: 20px; } }
  .icon-basket {
    color: #7B1FA2; }
    @media (min-width: 80.0625em) {
      .icon-basket {
        width: 28px;
        height: 28px; } }
    @media (min-width: 30em) and (max-width: 80.0525em) {
      .icon-basket {
        width: 24px;
        height: 24px; } }
    @media (max-width: 29.99em) {
      .icon-basket {
        width: 18px;
        height: 18px; } }
  .icon-search {
    color: #7B1FA2; }
    @media (min-width: 80.0625em) {
      .icon-search {
        width: 40px;
        height: 40px; } }
    @media (min-width: 64.0625em) and (max-width: 80.0525em) {
      .icon-search {
        width: 32px;
        height: 32px; } }
    @media (max-width: 64.0525em) {
      .icon-search {
        width: 20px;
        height: 20px; } }
  .icon-like {
    color: transparent;
    width: 24px;
    height: 24px;
    transition: all 0.2s; }
    @media screen and (min-width: 1025px) {
      .icon-like:hover {
        color: #1CB47A; } }
    .icon-like.liked {
      color: #1CB47A; }
  .icon-like-grey {
    width: 24px;
    height: 24px;
    transition: all 0.2s;
    color: #B0BEC5; }
    @media screen and (min-width: 1025px) {
      .icon-like-grey:hover {
        color: #1CB47A; } }
    .icon-like-grey.liked {
      color: #1CB47A; }
  .icon-like-lg {
    color: transparent;
    transition: all 0.2s; }
    @media (min-width: 50em) {
      .icon-like-lg {
        width: 54px;
        height: 50px; } }
    @media (max-width: 49.99em) {
      .icon-like-lg {
        width: 30px;
        height: 28px; } }
    @media screen and (min-width: 1025px) {
      .icon-like-lg:hover {
        color: #1CB47A; } }
    .icon-like-lg.liked {
      color: #1CB47A; }
  .icon-close {
    color: #fff;
    width: 40px;
    height: 40px; }
  .icon-remove {
    color: #B0BEC5;
    width: 24px;
    height: 24px; }

.form-input {
  position: relative; }
  .form-input input {
    display: block;
    width: 100%;
    height: 56px;
    font-size: 20px;
    font-weight: 500;
    background: #fff;
    border: none;
    border-radius: 4px 4px 0 0;
    color: #000;
    padding: 0 16px 0;
    outline: none;
    transition: background-color 0.2s, box-shadow 0.2s;
    box-shadow: 0 0 0 1px #B0BEC5 inset;
    appearance: none; }
    .invalid .form-input input {
      box-shadow: 0 0 0 2px #E94040 inset !important;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m11%207h2v2h-2m0%202h2v6h-2m1-15a10%2010%200%201%200%2010%2010%2010%2010%200%200%200%20-10%20-10m0%2018a8%208%200%201%201%208%20-8%208.011%208.011%200%200%201%20-8%208%22%20fill%3D%22%23e94040%22/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 24px auto;
      background-position: calc(100% - 16px) center;
      padding-right: 44px; }
    .form-input input:focus {
      box-shadow: 0 0 0 2px #7B1FA2 inset; }
      .form-input input:focus ~ .form-input--label {
        color: #7B1FA2;
        top: 0;
        transform: translateY(-50%) scale(0.75); }
    .form-input input[readonly] {
      pointer-events: none;
      background-color: #f3f3f3;
      color: rgba(84, 110, 122, 0.8); }
    .form-input input:-internal-autofill-selected {
      color: #546E7A !important; }
  .form-input--label {
    position: absolute;
    color: #546E7A;
    left: 10px;
    right: initial;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transform-origin: left center;
    background-color: #fff;
    padding: 0 6px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 150ms cubic-bezier(0.4, 0, 0.2, 1); }
  .form-input--message {
    font-size: 12px;
    position: absolute;
    top: 60px; }
    @media (min-width: 50em) {
      .form-input--message {
        left: 16px; } }
    @media (max-width: 49.99em) {
      .form-input--message {
        left: 0; } }
  .form-input label.invalid {
    font-size: 12px;
    position: absolute;
    top: 60px;
    color: #E94040; }
    @media (min-width: 50em) {
      .form-input label.invalid {
        left: 16px; } }
    @media (max-width: 49.99em) {
      .form-input label.invalid {
        left: 0; } }
    .form-input label.invalid:not(.valid):not([style*='display: none']) ~ .form-input--message {
      display: none; }
  .valid .form-input.filled input {
    box-shadow: 0 0 0 2px #1CB47A inset;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m11%207h2v2h-2m0%202h2v6h-2m1-15a10%2010%200%201%200%2010%2010%2010%2010%200%200%200%20-10%20-10m0%2018a8%208%200%201%201%208%20-8%208.011%208.011%200%200%201%20-8%208%22%20fill%3D%22%231cb47a%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 24px auto;
    background-position: calc(100% - 16px) center;
    padding-right: 44px; }
  .form-input.filled .form-input--label {
    top: 0;
    transform: translateY(-50%) scale(0.75); }
  .form-input-simple input {
    display: block;
    width: 100%;
    height: 40px;
    background: #EEEEEE;
    border: none;
    border-radius: 0;
    color: #546E7A;
    padding: 0 16px;
    outline: none; }

.form-select select {
  display: block;
  width: 100%;
  height: 40px;
  background-color: #EEEEEE;
  border: none;
  color: #546E7A;
  padding: 0 16px;
  outline: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%227.41%22%20viewBox%3D%220%200%2012%207.41%22%3E%0D%0A%20%20%3Cpath%20id%3D%22Path_54%22%20data-name%3D%22Path%2054%22%20d%3D%22M8.59%2C16.59%2C13.17%2C12%2C8.59%2C7.41%2C10%2C6l6%2C6-6%2C6Z%22%20transform%3D%22translate%2818%20-8.59%29%20rotate%2890%29%22%20fill%3D%22%23546e7a%22/%3E%0D%0A%3C/svg%3E%0D%0A");
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: auto 10px;
  appearance: none;
  border-radius: 0; }
  .form-select select.has-border {
    border: 1px solid #B0BEC5; }

.form-select--white select {
  background-color: #fff; }

.form-checkbox .checkbox-item {
  cursor: pointer;
  position: relative;
  line-height: 24px;
  font-size: 16px;
  color: #546E7A; }

.form-checkbox .checkbox-label {
  user-select: none;
  position: relative;
  text-indent: 32px;
  display: block; }
  .form-checkbox .checkbox-label:before, .form-checkbox .checkbox-label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    height: 18px;
    width: 18px;
    border-radius: 2px; }
  .form-checkbox .checkbox-label:before {
    border: 2px solid #B0BEC5; }
    .invalid .form-checkbox .checkbox-label:before {
      background-color: #FFCDD2; }
  .form-checkbox .checkbox-label:after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2017.6%2013.4%22%3E%3Ctitle%3E%u0420%u0435%u0441%u0443%u0440%u0441%201%3C/title%3E%3Cg%20id%3D%22%u0421%u043B%u043E%u0439_2%22%20data-name%3D%22%u0421%u043B%u043E%u0439%202%22%3E%3Cg%20id%3D%22%u0421%u043B%u043E%u0439_1-2%22%20data-name%3D%22%u0421%u043B%u043E%u0439%201%22%3E%3Cpath%20d%3D%22M5.6%2C10.6%2C1.4%2C6.4%2C0%2C7.8l5.6%2C5.6%2C12-12L16.2%2C0Z%22%20style%3D%22fill%3A%23fff%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-color: #1CB47A;
    background-size: 12px auto;
    background-position: center;
    opacity: 0; }

.form-checkbox input:checked ~ .checkbox-label:after {
  opacity: 1; }

.checkbox_color-item {
  cursor: pointer;
  position: relative;
  display: block; }

.checkbox_color-label {
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%; }
  .checkbox_color-label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #1CB47A;
    transition: opacity 0.1s;
    opacity: 0; }

.checkbox_color input:checked ~ .checkbox_color-label:after {
  opacity: 1; }

.form-spinner {
  display: flex;
  align-items: center; }
  .form-spinner--btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    color: #546E7A;
    font-size: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    box-shadow: 0 1px 4px 0 rgba(84, 110, 122, 0.5);
    background-repeat: no-repeat;
    background-size: 16px auto;
    background-position: center;
    flex-shrink: 0; }
    .form-spinner--btn.plus {
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%3E%3Cg%20fill%3D%22%23546e7a%22%3E%3Cpath%20d%3D%22m0%207h16v2h-16z%22/%3E%3Cpath%20d%3D%22m7%200h2v16h-2z%22/%3E%3C/g%3E%3C/svg%3E"); }
    .form-spinner--btn.minus {
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23546e7a%22%20d%3D%22m0%207h16v2h-16z%22/%3E%3C/svg%3E"); }
  .form-spinner--total {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    margin: 0 12px; }
    .form-spinner--total .total {
      display: block;
      opacity: 0;
      pointer-events: none;
      min-width: 30px; }
    .form-spinner--total .total-value {
      background: transparent;
      outline: none;
      border: none;
      appearance: none;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center; }
      .form-spinner--total .total-value::-webkit-inner-spin-button, .form-spinner--total .total-value::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }

.form-field {
  width: 100%;
  padding: 7px 0; }
  .form-field--md {
    margin-bottom: 1px; }
  .form-field--xl {
    margin-bottom: 9px; }
  .form-field--lg {
    margin-bottom: 25px; }

.form-buttons * {
  margin-right: 32px; }

.form-text {
  font-size: 16px;
  color: #546E7A;
  line-height: 24px; }

.form-socials ul {
  margin-top: 28px;
  display: flex;
  align-items: center; }

@media (min-width: 80.0625em) {
  .form-socials li {
    margin-right: 32px; } }

@media (min-width: 30em) and (max-width: 80.0525em) {
  .form-socials li {
    margin-right: 24px; } }

@media (max-width: 29.99em) {
  .form-socials li {
    margin-right: 16px; } }

.form-socials a {
  display: flex;
  border-radius: 50%;
  background: #B0BEC5;
  align-items: center;
  justify-content: center; }
  @media (min-width: 30em) {
    .form-socials a {
      height: 56px;
      width: 56px; } }
  @media (max-width: 29.99em) {
    .form-socials a {
      height: 40px;
      width: 40px; } }
  @media screen and (min-width: 1025px) {
    .form-socials a:hover {
      background: none; } }

.form-socials img {
  max-width: 75%;
  max-height: 75%; }

.burger {
  cursor: pointer;
  position: relative; }
  @media (min-width: 30em) {
    .burger {
      width: 32px;
      height: 32px; } }
  @media (max-width: 29.99em) {
    .burger {
      width: 28px;
      height: 30px; } }
  .burger span {
    height: 4px;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #546E7A;
    border-radius: 3px;
    transform: rotate(0deg); }
  .burger:before, .burger:after {
    content: "";
    left: 0;
    right: 0;
    height: 4px;
    width: 100%;
    position: absolute;
    background-color: #546E7A;
    border-radius: 3px; }
  .burger:before {
    top: 6px; }
  .burger:after {
    bottom: 6px; }

.logo {
  display: inline-flex;
  align-items: center;
  color: #37474F;
  font-weight: 600; }
  @media (min-width: 30em) {
    .logo {
      font-size: 24px; } }
  @media (max-width: 29.99em) {
    .logo {
      font-size: 12px; } }
  @media (min-width: 64.0625em) {
    .logo-img {
      width: 156px;
      height: 40px; } }
  @media (min-width: 30em) and (max-width: 64.0525em) {
    .logo-img {
      width: 120px;
      height: 32px; } }
  @media (max-width: 29.99em) {
    .logo-img {
      width: 80px;
      height: 22px; } }

#toggle-translate {
  display: block;
  position: fixed;
  line-height: 48px;
  bottom: 0;
  left: 0;
  color: #ff5252;
  text-transform: lowercase;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 0 24px;
  border: 2px solid #ff5252;
  z-index: 999; }

.to-admin-panel {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 100;
  background: none;
  border: none; }
  .to-admin-panel a {
    color: #ff5252;
    text-transform: uppercase;
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0 24px;
    border: 2px solid #ff5252;
    line-height: 32px;
    display: block; }

.text-box {
  line-height: 1.5;
  color: #546E7A; }
  .text-box h2 {
    font-weight: 500; }
    @media (min-width: 50em) {
      .text-box h2 {
        font-size: 32px; } }
    @media (max-width: 49.99em) {
      .text-box h2 {
        font-size: 24px; } }
    .text-box h2:not(:last-child) {
      margin-bottom: 24px; }
    .text-box h2:not(:first-child) {
      margin-top: 32px; }
  .text-box h3 {
    font-weight: 500; }
    @media (min-width: 50em) {
      .text-box h3 {
        font-size: 24px; } }
    @media (max-width: 49.99em) {
      .text-box h3 {
        font-size: 20px; } }
    .text-box h3:not(:last-child) {
      margin-bottom: 24px; }
    .text-box h3:not(:first-child) {
      margin-top: 32px; }
  .text-box h4 {
    font-weight: 500; }
    @media (min-width: 50em) {
      .text-box h4 {
        font-size: 20px; } }
    @media (max-width: 49.99em) {
      .text-box h4 {
        font-size: 18px; } }
    .text-box h4:not(:last-child) {
      margin-bottom: 24px; }
    .text-box h4:not(:first-child) {
      margin-top: 32px; }
  .text-box ol {
    counter-reset: item; }
    .text-box ol:not(:last-child) {
      margin-bottom: 16px; }
    .text-box ol li {
      padding-left: 32px;
      position: relative; }
      .text-box ol li:before {
        content: counter(item, decimal) ".";
        counter-increment: item;
        left: 0;
        position: absolute;
        top: 0; }
  .text-box ul:not(:last-child) {
    margin-bottom: 16px; }
  .text-box ul li {
    padding-left: 32px;
    position: relative; }
    .text-box ul li:before {
      content: "";
      position: absolute;
      left: 0;
      width: 24px;
      height: 1px;
      background: currentColor;
      top: 12px; }
  .text-box li:not(:last-child) {
    margin-bottom: 16px; }
  .text-box p {
    min-height: 24px; }
    .text-box p:not(:last-child) {
      margin-bottom: 16px; }
  .text-box img {
    display: block;
    margin: 24px auto;
    height: auto;
    position: relative;
    max-width: 100%; }
    @media (min-width: 80.0625em) {
      .text-box img {
        max-width: calc(4*100%/3);
        left: 50%;
        transform: translateX(-50%); } }
  .text-box .video {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 0;
    margin: 24px auto;
    position: relative;
    padding-bottom: calc(2*100%/3); }
    @media (min-width: 80.0625em) {
      .text-box .video {
        width: calc(4*100%/3);
        padding-bottom: calc(8*100%/9);
        left: 50%;
        transform: translateX(-50%); } }
    .text-box .video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
      max-height: 100%; }
  .text-box blockquote {
    position: relative;
    color: #546E7A; }
    @media (min-width: 64.0625em) {
      .text-box blockquote {
        padding-left: 128px; } }
    @media (max-width: 64.0525em) {
      .text-box blockquote {
        padding-left: 12vw; } }
    @media (min-width: 50em) {
      .text-box blockquote {
        font-size: 24px;
        margin-top: 120px; }
        .text-box blockquote:not(:last-child) {
          margin-bottom: 120px; } }
    @media (max-width: 49.99em) {
      .text-box blockquote {
        font-size: 20px;
        margin-top: 60px; }
        .text-box blockquote:not(:last-child) {
          margin-bottom: 60px; } }
    .text-box blockquote:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 8px;
      background: linear-gradient(to bottom, #7B1FA2 0%, #18088D 100%); }
  .text-box a {
    color: #1CB47A;
    position: relative; }
    @media screen and (min-width: 1025px) {
      .text-box a:hover {
        color: #199e6b; } }

.text-area {
  background: #fff; }
  @media (min-width: 64.0625em) {
    .text-area {
      padding: 80px 0; } }
  @media (min-width: 50em) and (max-width: 64.0525em) {
    .text-area {
      padding: 60px 0; } }
  @media (max-width: 49.99em) {
    .text-area {
      padding: 40px 0; } }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  @media (min-width: 37.5em) {
    .breadcrumbs {
      margin-bottom: 32px; } }
  @media (max-width: 37.49em) {
    .breadcrumbs {
      margin-bottom: 24px; } }
  .breadcrumbs-link {
    position: relative;
    color: #546E7A; }
    @media (min-width: 50em) {
      .breadcrumbs-link {
        margin-right: 36px; } }
    @media (max-width: 49.99em) {
      .breadcrumbs-link {
        margin-right: 24px; } }
    .breadcrumbs-link:after {
      content: "";
      width: 10px;
      height: 10px;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23B0BEC5%22%3E%3Cpath%20d%3D%22m6.49%2020.13l1.77%201.77%209.9-9.9-9.9-9.9-1.77%201.77%208.13%208.13-8.13%208.13%22/%3E%3C/svg%3E");
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      position: absolute;
      left: 100%;
      top: 50%;
      pointer-events: none; }
      @media (min-width: 50em) {
        .breadcrumbs-link:after {
          transform: translate(15px, -50%); } }
      @media (max-width: 49.99em) {
        .breadcrumbs-link:after {
          transform: translate(9px, -50%); } }
  .breadcrumbs-current {
    color: #546E7A;
    opacity: 0.7;
    cursor: default; }

.pagination {
  display: flex;
  align-items: center; }
  @media (min-width: 50em) {
    .pagination {
      margin: 0 -6px; } }
  @media (max-width: 49.99em) {
    .pagination {
      margin: 0 -4px; } }
  @media (min-width: 37.5em) {
    .pagination {
      justify-content: flex-end; } }
  @media (max-width: 37.49em) {
    .pagination {
      justify-content: center; } }
  .pagination a, .pagination span {
    display: inline-flex;
    border-radius: 50%;
    background-color: #fff;
    color: #546E7A;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    position: relative;
    overflow: hidden; }
    @media (min-width: 50em) {
      .pagination a, .pagination span {
        height: 56px;
        width: 56px;
        margin: 0 6px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .pagination a, .pagination span {
        height: 40px;
        width: 40px;
        margin: 0 4px; } }
    @media (max-width: 29.99em) {
      .pagination a, .pagination span {
        height: 28px;
        width: 28px;
        margin: 0 3px; } }
    .pagination a.prev:after, .pagination a.next:after, .pagination span.prev:after, .pagination span.next:after {
      content: "";
      position: absolute;
      border-style: solid solid none none;
      border-color: currentColor;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0; }
      @media (min-width: 30em) {
        .pagination a.prev:after, .pagination a.next:after, .pagination span.prev:after, .pagination span.next:after {
          border-width: 3px;
          width: 14px;
          height: 14px; } }
      @media (max-width: 29.99em) {
        .pagination a.prev:after, .pagination a.next:after, .pagination span.prev:after, .pagination span.next:after {
          border-width: 2px;
          width: 10px;
          height: 10px; } }
    .pagination a.prev:after, .pagination span.prev:after {
      transform: rotate(-135deg) translate(-2px, 2px); }
    .pagination a.next:after, .pagination span.next:after {
      transform: rotate(45deg) translate(-2px, 2px); }
    .pagination a.current, .pagination span.current {
      background-color: #546E7A;
      color: #fff;
      pointer-events: none; }
  @media screen and (min-width: 1025px) {
    .pagination a:hover {
      background-color: #546E7A;
      color: #fff; } }

.back_page {
  margin: 0 0 0 40px; }
  @media (max-width: 37.49em) {
    .back_page {
      display: none; } }
  .back_page a {
    cursor: pointer;
    color: inherit;
    background: linear-gradient(to bottom, #FF5252 0%, #FF5252 100%);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    padding: 2px 0;
    transition: background-size .3s;
    position: relative; }
    @media screen and (min-width: 1025px) {
      .back_page a:hover {
        background-size: 98% 2px; } }
    .back_page a:before {
      content: "";
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2218%22%20height%3D%2212%22%20viewBox%3D%220%200%2018%2012%22%3E%0D%0A%20%20%3Cpath%20id%3D%22Path_93%22%20data-name%3D%22Path%2093%22%20d%3D%22M21%2C11H6.83l3.58-3.59L9%2C6%2C3%2C12l6%2C6%2C1.41-1.41L6.83%2C13H21Z%22%20transform%3D%22translate%28-3%20-6%29%22%20fill%3D%22%23546e7a%22/%3E%0D%0A%3C/svg%3E%0D%0A");
      background-repeat: no-repeat;
      position: absolute;
      width: 18px;
      height: 18px;
      background-size: 100% auto;
      background-position: center;
      right: 100%;
      top: 0;
      bottom: 0;
      margin: auto 12px auto 0; }

.gallery-container {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative; }

.gallery-dots {
  width: 72%;
  height: 12%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  overflow: hidden;
  display: flex; }
  .gallery-dots--item {
    cursor: pointer;
    min-width: calc(100%/6);
    width: calc(100%/6);
    height: 100%;
    background: #fff;
    border: 1px solid #EEEEEE;
    position: relative; }
    .gallery-dots--item:first-child {
      margin-left: auto; }
    .gallery-dots--item:last-child {
      margin-right: auto; }
    .gallery-dots--item:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      opacity: 0;
      height: 4px;
      background: #FF5252; }
    .gallery-dots--item.current:after {
      opacity: 1; }
    .gallery-dots--item.current .gallery-dots--img {
      opacity: 1; }
    @media screen and (min-width: 1025px) {
      .gallery-dots--item:hover .gallery-dots--img {
        opacity: 1; } }
  .gallery-dots--img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.6;
    transition: all 0.18s; }
    .gallery-dots--img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }

.gallery-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .gallery-main--wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative; }
    @media (min-width: 50em) {
      .catalog_item .gallery-main--wrap {
        cursor: pointer; }
        .catalog_item .gallery-main--wrap:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22none%22%20stroke%3D%22%23546E7A%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22/%3E%3Cpath%20d%3D%22m21%2021l-4.35-4.35%22/%3E%3Cpath%20d%3D%22m11%208v6%22/%3E%3Cpath%20d%3D%22m8%2011h6%22/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: 14% auto;
          background-position: center;
          opacity: 0;
          transition: all 0.15s; } }
    @media screen and (min-width: 1025px) {
      .gallery-main--wrap:hover:after {
        opacity: 0.4; } }
  .gallery-main--item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 0.25s; }
    .gallery-main--item.new {
      opacity: 0; }
      .gallery-main--item.new.active {
        z-index: 1; }

@media (max-width: 29.99em) {
  .gallery-nav {
    display: none; } }

.gallery-nav--button {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto; }
  .gallery-nav--button.prev {
    left: 8px; }
  .gallery-nav--button.next {
    right: 8px; }

.modal_catalog-heading {
  margin-bottom: 32px; }

@media (min-width: 64.0625em) {
  .modal_catalog-wrap {
    max-height: 656px;
    display: flex; } }

@media (max-width: 64.0525em) {
  .modal_catalog-wrap {
    overflow: auto; } }

.modal_catalog-box {
  overflow: hidden;
  padding-bottom: 56px;
  position: relative; }
  @media (max-width: 64.0525em) {
    .modal_catalog-box {
      padding-top: 24px;
      display: flex;
      flex-direction: column; } }

.modal_catalog-gallery {
  padding: 0 4px; }
  @media (min-width: 64.0625em) {
    .modal_catalog-gallery {
      width: calc(5*100%/8); } }
  @media (max-width: 64.0525em) {
    .modal_catalog-gallery {
      width: 100%;
      max-width: 600px;
      margin: 0 auto; } }

@media (min-width: 80.0625em) {
  .modal_catalog-about {
    padding: 32px 48px; } }

@media (min-width: 64.0625em) and (max-width: 80.0525em) {
  .modal_catalog-about {
    padding: 32px; } }

@media (min-width: 64.0625em) {
  .modal_catalog-about {
    width: calc(3*100%/8);
    overflow: auto; } }

@media (min-width: 30em) and (max-width: 64.0525em) {
  .modal_catalog-about {
    padding: 0 32px 32px; } }

@media (max-width: 29.99em) {
  .modal_catalog-about {
    padding: 0 16px 32px; } }

.modal_catalog-about .text-box:not(:last-child) {
  margin-bottom: 48px; }

.modal_catalog-form_actions {
  display: flex;
  align-items: center; }
  .modal_catalog-form_actions--like {
    flex-shrink: 0;
    margin-left: 12px; }

.modal_catalog-link {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center; }

.custom_modal {
  display: flex; }
  @media (min-width: 30em) {
    .custom_modal {
      max-height: 95vh;
      padding: 40px 56px 32px 48px; } }
  @media (max-width: 29.99em) {
    .custom_modal {
      height: 100vh;
      overflow: hidden;
      padding: 95px 48px 48px; } }
  .custom_modal-main {
    width: 100%;
    overflow: auto; }
  .custom_modal-title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px; }
  .custom_modal-text {
    margin-bottom: 40px; }
  .custom_modal-actions {
    display: flex;
    justify-content: flex-end; }
    @media (min-width: 26.25em) {
      .custom_modal-actions .btn-red + .btn-red {
        margin-left: 16px; } }
    @media (max-width: 26.25em) {
      .custom_modal-actions .btn-red + .btn-red {
        margin-top: 16px; } }
    @media (max-width: 26.25em) {
      .custom_modal-actions {
        flex-direction: column; }
        .custom_modal-actions .btn {
          width: 100%; } }
  .custom_modal .icon-close {
    width: 28px;
    height: 28px; }
  @media (min-width: 30em) {
    .custom_modal .modal-close {
      right: 75px;
      top: 32px; } }
  @media (max-width: 29.99em) {
    .custom_modal .modal-close {
      right: 40px;
      top: 16px; } }

.alert {
  position: fixed;
  right: 0;
  z-index: 100;
  bottom: 40px;
  transition: all 0.35s; }
  @media (min-width: 105em) {
    .alert {
      left: calc(50% + 1680px/2 - 32px); } }
  @media (min-width: 64.0625em) and (max-width: 105em) {
    .alert {
      right: 32px; } }
  @media (min-width: 50em) and (max-width: 64.0525em) {
    .alert {
      right: 24px;
      bottom: 24px; } }
  @media (max-width: 49.99em) {
    .alert {
      right: 16px;
      bottom: 16px; } }
  .alert:not(.active) {
    z-index: -1;
    transform: translateY(-80px);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: all 0.1s; }
  .alert-section {
    padding: 20px;
    box-shadow: 0 20px 20px 0 rgba(84, 110, 122, 0.3);
    border-radius: 48px 48px 0 48px;
    width: 300px; }
    @media (min-width: 105em) {
      .alert-section {
        transform: translateX(-100%); } }
    @media (max-width: 49.99em) {
      .alert-section {
        width: 100%;
        max-width: 180px;
        border-radius: 20px 20px 0 20px;
        padding: 8px 12px 8px 8px; } }
    .alert-section[data-alert-type="success"] {
      background-color: #fff; }
      .alert-section[data-alert-type="success"] .alert-icon {
        background-color: #E4FFF9;
        color: #1CB47A; }
    .alert-section[data-alert-type="error"] {
      background-color: #FFCDD2; }
      .alert-section[data-alert-type="error"] .alert-icon {
        background-color: #fff;
        color: #FF5252; }
  .alert-main {
    display: flex; }
  .alert-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 18px;
    margin-right: 20px;
    flex-shrink: 0;
    align-self: flex-start; }
    @media (max-width: 49.99em) {
      .alert-icon {
        width: 30px;
        height: 30px;
        padding: 8px;
        margin-right: 10px; } }
  .alert-text {
    font-size: 20px;
    line-height: 1.5;
    align-self: center; }
    @media (max-width: 49.99em) {
      .alert-text {
        font-size: 14px; } }

.notification {
  position: fixed;
  right: 0;
  z-index: 100;
  bottom: 40px; }
  @media (min-width: 105em) {
    .notification {
      left: calc(50% + 1680px/2 - 32px); } }
  @media (min-width: 64.0625em) and (max-width: 105em) {
    .notification {
      right: 32px; } }
  @media (min-width: 50em) and (max-width: 64.0525em) {
    .notification {
      right: 24px;
      bottom: 24px; } }
  @media (max-width: 49.99em) {
    .notification {
      right: 16px;
      bottom: 16px; } }
  .notification-section {
    padding: 20px;
    box-shadow: 0 20px 20px 0 rgba(84, 110, 122, 0.3);
    border-radius: 48px 48px 0 48px;
    width: 300px;
    background-color: #fff;
    position: relative; }
    @media (min-width: 105em) {
      .notification-section {
        transform: translateX(-100%); } }
    @media (max-width: 49.99em) {
      .notification-section {
        width: 100%;
        max-width: 180px;
        border-radius: 20px 20px 0 20px;
        padding: 8px 12px 8px 8px; } }
    .notification-section:not(:last-child) {
      margin-bottom: 24px; }
    .notification-section .close {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      box-shadow: 0 0 4px 0 rgba(84, 110, 122, 0.5);
      cursor: pointer; }
    .notification-section.error {
      background-color: #FFCDD2; }
      .notification-section.error .notification-icon {
        background-color: #fff;
        color: #FF5252; }
  .notification-main {
    display: flex; }
  .notification-icon {
    background-color: #FCEBDD;
    color: #1CB47A;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 18px;
    margin-right: 20px;
    flex-shrink: 0;
    align-self: flex-start; }
    @media (max-width: 49.99em) {
      .notification-icon {
        width: 30px;
        height: 30px;
        padding: 8px;
        margin-right: 10px; } }
  .notification-text {
    font-size: 20px;
    line-height: 1.5;
    align-self: center; }
    @media (max-width: 49.99em) {
      .notification-text {
        font-size: 14px; } }

.modal {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s; }
  .modal.opened {
    z-index: 100;
    opacity: 1;
    pointer-events: auto; }
  .modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
  .modal-container {
    position: absolute;
    width: 100vw; }
    @media (min-width: 64.0625em) {
      .modal-container {
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%; } }
    @media (max-width: 64.0525em) {
      .modal-container {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
    @media (max-width: 29.99em) {
      .modal-container {
        padding: 0; } }
  .modal-wrap {
    background: #fff;
    box-shadow: 0 20px 20px 0 rgba(84, 110, 122, 0.3);
    overflow: hidden;
    display: flex;
    flex-direction: column; }
    @media (max-width: 64.0525em) {
      .modal-wrap {
        position: relative;
        align-self: center;
        width: 100%; } }
    @media (min-width: 30em) {
      .modal-wrap {
        max-height: 95vh; }
        [data-modal="popup"] .modal-wrap {
          border-radius: 40px 0 40px 40px; } }
    @media (max-width: 29.99em) {
      .modal-wrap {
        max-height: 100vh; } }
  .modal-close {
    position: absolute;
    top: 4px;
    z-index: 1;
    color: #546E7A; }
    @media (min-width: 30em) {
      .modal-close {
        right: 24px; } }
    @media (max-width: 29.99em) {
      .modal-close {
        right: 4px; } }
    .modal-close .icon {
      color: inherit; }
  .modal-section--box:not(.active) {
    display: none; }

.common-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  @media (min-width: 64.0625em) {
    .common-filters {
      margin: 32px 0; } }
  @media (max-width: 64.0525em) {
    .common-filters {
      margin: 24px 0; } }
  @media (min-width: 64.0625em) {
    .common-filters .item {
      margin-right: 32px; } }
  @media (max-width: 64.0525em) {
    .common-filters .item {
      margin-right: 16px; } }

@media (min-width: 64.0625em) {
  .home-heading:not(:first-child) {
    margin-top: 64px; } }

@media (max-width: 64.0525em) {
  .home-heading:not(:first-child) {
    margin-top: 32px; } }

.home-heading--link {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, #FF5252 0%, #FF5252 100%);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 2px;
  padding: 2px 0;
  color: #546E7A;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  transition: background-size .3s; }
  @media (min-width: 50em) {
    .home-heading--link {
      font-size: 16px; } }
  @media (max-width: 49.99em) {
    .home-heading--link {
      font-size: 14px; } }
  @media (min-width: 30em) {
    .home-heading--link {
      margin-right: 40px; } }
  @media (max-width: 29.99em) {
    .home-heading--link {
      width: 24px;
      height: 24px;
      overflow: hidden;
      text-indent: 100px;
      white-space: nowrap; } }
  @media screen and (min-width: 1025px) {
    .home-heading--link:hover {
      background-size: 98% 2px; } }
  .home-heading--link:before {
    content: "";
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2218%22%20height%3D%2212%22%20viewBox%3D%220%200%2018%2012%22%3E%0D%0A%20%20%3Cpath%20id%3D%22Path_93%22%20data-name%3D%22Path%2093%22%20d%3D%22M3%2C11H17.17L13.59%2C7.41%2C15%2C6l6%2C6-6%2C6-1.41-1.41L17.17%2C13H3Z%22%20transform%3D%22translate%28-3%20-6%29%22%20fill%3D%22%23546e7a%22/%3E%0D%0A%3C/svg%3E%0D%0A");
    background-repeat: no-repeat;
    position: absolute;
    width: 18px;
    height: 18px;
    background-size: 100% auto;
    background-position: center; }
    @media (min-width: 30em) {
      .home-heading--link:before {
        left: 100%;
        top: 0;
        bottom: 0;
        margin: auto 0 auto 12px; } }
    @media (max-width: 29.99em) {
      .home-heading--link:before {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; } }

@media (min-width: 80.0625em) {
  .home-types {
    margin-top: 48px;
    margin-bottom: 64px; } }

@media (max-width: 80.0525em) {
  .home-types {
    margin-top: 24px;
    margin-bottom: 32px; } }

.cart-columns {
  display: flex;
  margin: 0 -4px; }
  @media (min-width: 88.8125em) {
    .cart-columns:not(:last-child) {
      margin-bottom: 90px; } }
  @media (min-width: 64.0625em) and (max-width: 88.8025em) {
    .cart-columns:not(:last-child) {
      margin-bottom: 80px; } }
  @media (max-width: 64.0525em) {
    .cart-columns:not(:last-child) {
      margin-bottom: 40px; } }
  @media (max-width: 64.0525em) {
    .cart-columns {
      flex-direction: column; } }

.cart-sidebar {
  padding: 0 4px;
  width: 100%;
  flex-shrink: 0; }
  @media (min-width: 64.0625em) {
    .cart-sidebar {
      max-width: calc( 100%/3); } }
  @media (max-width: 64.0525em) {
    .cart-sidebar {
      margin-top: 24px; } }
  .cart-sidebar--box {
    background: #fff; }
    @media (min-width: 80.0625em) {
      .cart-sidebar--box {
        padding: 48px 32px 1px; } }
    @media (min-width: 30em) and (max-width: 80.0525em) {
      .cart-sidebar--box {
        padding: 32px 24px 1px; } }
    @media (max-width: 29.99em) {
      .cart-sidebar--box {
        padding: 16px 16px 1px; } }
  .cart-sidebar--total {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px; }
    .cart-sidebar--total dt, .cart-sidebar--total dd {
      width: 100%;
      max-width: 50%;
      padding-right: 8px; }
    .cart-sidebar--total dt {
      line-height: 24px; }
    .cart-sidebar--total dd {
      font-size: 20px;
      color: #546E7A;
      font-weight: 600; }
      .cart-sidebar--total dd.summary {
        line-height: 1; }
        @media (min-width: 80.0625em) {
          .cart-sidebar--total dd.summary {
            font-size: 48px; } }
        @media (min-width: 64.0625em) and (max-width: 80.0525em) {
          .cart-sidebar--total dd.summary {
            font-size: 32px; } }
        @media (min-width: 30em) and (max-width: 64.0525em) {
          .cart-sidebar--total dd.summary {
            font-size: 48px; } }
        @media (max-width: 29.99em) {
          .cart-sidebar--total dd.summary {
            font-size: 32px; } }
  .cart-sidebar--promocode {
    display: flex;
    width: 100%; }
    @media (min-width: 64.0625em) and (max-width: 80.0525em) {
      .cart-sidebar--promocode {
        flex-wrap: wrap; } }
    @media (max-width: 29.99em) {
      .cart-sidebar--promocode {
        flex-wrap: wrap; } }
    .cart-sidebar--promocode_btn {
      flex-shrink: 0; }
      @media (min-width: 64.0625em) and (max-width: 80.0525em) {
        .cart-sidebar--promocode_btn {
          width: 100%; } }
      @media (max-width: 29.99em) {
        .cart-sidebar--promocode_btn {
          width: 100%; } }
    .cart-sidebar--promocode .form-input-simple {
      width: 100%; }

.cart-main {
  padding: 0 4px;
  width: 100%; }

@media (min-width: 88.8125em) {
  .cart-section:not(:last-child) {
    margin-bottom: 90px; } }

@media (min-width: 64.0625em) and (max-width: 88.8025em) {
  .cart-section:not(:last-child) {
    margin-bottom: 80px; } }

@media (max-width: 64.0525em) {
  .cart-section:not(:last-child) {
    margin-bottom: 40px; } }

.cart-section--title {
  margin-bottom: 16px; }

.cart-section--box {
  background: #fff; }
  @media (min-width: 88.8125em) {
    .cart-section--box {
      padding: 48px; } }
  @media (min-width: 50em) and (max-width: 88.8025em) {
    .cart-section--box {
      padding: 32px; } }
  @media (min-width: 30em) and (max-width: 49.99em) {
    .cart-section--box {
      padding: 24px; } }
  @media (max-width: 29.99em) {
    .cart-section--box {
      padding: 16px; } }

@media (min-width: 50em) {
  .cart-section--columns {
    display: flex; } }

@media (min-width: 50em) {
  .cart-section--col {
    width: 50%; } }

@media (min-width: 88.8125em) {
  .cart-section--col.has-padding {
    padding-right: 10%; } }

@media (min-width: 50em) and (max-width: 88.8025em) {
  .cart-section--col.has-padding {
    padding-right: 8%; } }

.cart-submit {
  margin-top: 32px; }
  .cart-submit .btn {
    align-items: center; }
    @media (min-width: 50em) {
      .cart-submit .btn {
        line-height: 88px; } }
    @media (max-width: 49.99em) {
      .cart-submit .btn {
        line-height: 60px; } }
    .cart-submit .btn i {
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: 5px; }
      .cart-submit .btn i:after {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
        border-style: solid solid none none;
        border-color: currentColor;
        border-width: 3px;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        transform: rotate(45deg) translate(-2px, 2px); }

.cart_table-item {
  display: flex;
  align-items: center; }
  @media (min-width: 80.0625em) {
    .cart_table-item {
      margin-left: -24px; } }
  @media (min-width: 50em) and (max-width: 80.0525em) {
    .cart_table-item {
      margin-left: -12px; } }
  @media (min-width: 37.5em) and (max-width: 49.99em) {
    .cart_table-item {
      margin-top: -16px;
      margin-bottom: -16px;
      margin-left: -28px; } }
  @media (max-width: 37.49em) {
    .cart_table-item {
      margin-top: 12px;
      margin-left: 0;
      margin-bottom: 24px; } }
  @media (max-width: 29.99em) {
    .cart_table-item {
      flex-direction: column;
      align-items: flex-start; } }
  .cart_table-item--img {
    position: relative;
    flex-shrink: 0; }
    @media (min-width: 50em) {
      .cart_table-item--img {
        height: 96px;
        width: 96px; } }
    @media (min-width: 37.5em) and (max-width: 49.99em) {
      .cart_table-item--img {
        height: 128px;
        width: 128px; } }
    @media (min-width: 37.5em) {
      .cart_table-item--img {
        margin: 16px; } }
    @media (min-width: 30em) and (max-width: 37.49em) {
      .cart_table-item--img {
        margin-right: 24px; } }
    @media (max-width: 37.49em) {
      .cart_table-item--img {
        height: 128px;
        width: 128px;
        margin-bottom: 12px; } }
  .cart_table-item--img_box {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block; }
    .cart_table-item--img_box img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
  .cart_table-item--title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px; }
    .cart_table-item--title a {
      color: #546E7A;
      background: linear-gradient(to bottom, #FF5252 0%, #FF5252 100%);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 2px;
      padding: 2px 0;
      transition: background-size .3s;
      position: relative; }
      @media screen and (min-width: 1025px) {
        .cart_table-item--title a:hover {
          background-size: 98% 2px; } }
  .cart_table-item--category {
    font-size: 16px;
    color: #546E7A; }
  .cart_table-item--count {
    display: flex;
    align-items: center; }
    .cart_table-item--count_btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #EEEEEE;
      color: #546E7A;
      font-size: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1; }
      .cart_table-item--count_btn.minus {
        padding-bottom: 4px; }
    .cart_table-item--count_total {
      font-size: 20px;
      font-weight: 600;
      margin: 0 12px; }
      .cart_table-item--count_total:after {
        content: attr(data-total);
        display: inline-flex;
        width: 24px;
        justify-content: center; }
  .cart_table-item--old_price {
    font-size: 16px;
    text-decoration: line-through;
    white-space: nowrap; }
  .cart_table-item--actual_price {
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap; }
  .cart_table-item--remove {
    width: 24px; }

@media (min-width: 37.5em) and (max-width: 49.99em) {
  .cart_table-cell--part {
    float: left;
    width: 50%;
    display: block; } }

@media (max-width: 37.49em) {
  .cart_table-cell--part {
    display: block; } }

@media (min-width: 37.5em) and (max-width: 49.99em) {
  .cart_table-cell--count {
    margin-left: 50%;
    display: block; } }

@media (max-width: 37.49em) {
  .cart_table-cell--count {
    display: block; } }

@media (min-width: 37.5em) and (max-width: 49.99em) {
  .cart_table-cell--price {
    margin-left: 50%;
    margin-top: 8px; } }

@media (max-width: 37.49em) {
  .cart_table-cell--price {
    display: block;
    margin-top: 8px; } }

@media (min-width: 37.5em) and (max-width: 49.99em) {
  .cart_table-cell--term {
    display: flex;
    align-items: center; } }

@media (max-width: 37.49em) {
  .cart_table-cell--term {
    display: flex;
    align-items: center; } }

.cart_table-cell--term_title {
  margin-right: 8px;
  font-weight: 300; }

@media (max-width: 49.99em) {
  .cart_table-cell--action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); } }

.cart_table table {
  width: 100%;
  border-collapse: collapse; }
  @media (max-width: 49.99em) {
    .cart_table table {
      display: block; } }

.cart_table thead {
  color: #fff;
  background: #546E7A;
  text-align: left;
  font-size: 16px; }
  @media (max-width: 49.99em) {
    .cart_table thead {
      display: none; } }
  .cart_table thead th {
    height: 40px; }
    @media (min-width: 80.0625em) {
      .cart_table thead th {
        padding: 0 24px; } }
    @media (max-width: 80.0525em) {
      .cart_table thead th {
        padding: 0 12px; } }

@media (max-width: 49.99em) {
  .cart_table tbody {
    display: block; } }

.cart_table tbody tr {
  border-top: 6px solid #EEEEEE;
  background: #fff; }
  @media (max-width: 49.99em) {
    .cart_table tbody tr {
      overflow: hidden;
      display: block;
      position: relative; } }
  @media (min-width: 30em) and (max-width: 49.99em) {
    .cart_table tbody tr {
      padding: 16px 32px 16px 16px; } }
  @media (max-width: 29.99em) {
    .cart_table tbody tr {
      padding: 8px 32px 16px 8px; } }

@media (min-width: 80.0625em) {
  .cart_table td {
    padding: 0 24px; } }

@media (max-width: 80.0525em) {
  .cart_table td {
    padding: 0 12px; } }

.profile-columns {
  display: flex;
  margin: 0 -4px; }
  @media (min-width: 88.8125em) {
    .profile-columns:not(:last-child) {
      margin-bottom: 90px; } }
  @media (min-width: 64.0625em) and (max-width: 88.8025em) {
    .profile-columns:not(:last-child) {
      margin-bottom: 80px; } }
  @media (max-width: 64.0525em) {
    .profile-columns:not(:last-child) {
      margin-bottom: 40px; } }
  @media (max-width: 64.0525em) {
    .profile-columns {
      flex-direction: column; } }

.profile-main {
  padding: 0 4px;
  width: 100%; }
  @media (min-width: 64.0625em) {
    .profile-main {
      max-width: calc( 2*100%/3); } }

.profile-section:not(:last-child) {
  margin-bottom: 8px; }

.profile-section--box {
  background: #fff; }
  @media (min-width: 88.8125em) {
    .profile-section--box {
      padding: 48px 48px 8px; } }
  @media (min-width: 50em) and (max-width: 88.8025em) {
    .profile-section--box {
      padding: 32px 32px 8px; } }
  @media (min-width: 30em) and (max-width: 49.99em) {
    .profile-section--box {
      padding: 24px; } }
  @media (max-width: 29.99em) {
    .profile-section--box {
      padding: 16px; } }

@media (min-width: 50em) {
  .profile-section--columns {
    display: flex; } }

@media (min-width: 50em) {
  .profile-section--col {
    width: 50%; } }

@media (min-width: 88.8125em) {
  .profile-section--col.has-padding {
    padding-right: 10%; } }

@media (min-width: 50em) and (max-width: 88.8025em) {
  .profile-section--col.has-padding {
    padding-right: 8%; } }

.help_box-columns {
  display: flex;
  margin: 0 -4px; }
  @media (min-width: 88.8125em) {
    .help_box-columns:not(:last-child) {
      margin-bottom: 90px; } }
  @media (min-width: 64.0625em) and (max-width: 88.8025em) {
    .help_box-columns:not(:last-child) {
      margin-bottom: 80px; } }
  @media (max-width: 64.0525em) {
    .help_box-columns:not(:last-child) {
      margin-bottom: 40px; } }
  @media (max-width: 64.0525em) {
    .help_box-columns {
      flex-direction: column; } }

.help_box-main {
  padding: 0 4px;
  width: 100%; }
  @media (min-width: 64.0625em) {
    .help_box-main {
      max-width: calc( 3*100%/4); } }
  @media (min-width: 80.0625em) and (max-width: 100em) {
    .help_box-main .text-box img {
      max-width: calc( 10*100vw/15); } }

.help_box-section:not(:last-child) {
  margin-bottom: 8px; }

.help_box-section--box {
  background: #fff; }

@media (min-width: 50em) {
  .help_box-section--columns {
    display: flex; } }

.help_box-question {
  display: flex;
  justify-content: space-between; }
  @media (min-width: 50em) {
    .help_box-question {
      min-height: 72px; } }
  @media (min-width: 30em) and (max-width: 49.99em) {
    .help_box-question {
      min-height: 60px; } }
  @media (max-width: 29.99em) {
    .help_box-question {
      min-height: 48px; } }
  .help_box-question--expanded_btn {
    display: flex; }
  .help_box-question .btn-expand {
    height: auto;
    flex-shrink: 0;
    background: transparent;
    color: #546E7A; }
    @media (min-width: 50em) {
      .help_box-question .btn-expand {
        min-height: 72px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .help_box-question .btn-expand {
        min-height: 60px; } }
    @media (max-width: 29.99em) {
      .help_box-question .btn-expand {
        min-height: 48px; } }
  .help_box-question .question {
    font-weight: 500;
    display: flex;
    align-items: center; }
    @media (min-width: 50em) {
      .help_box-question .question {
        padding: 16px 48px;
        font-size: 20px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .help_box-question .question {
        padding: 12px 16px;
        font-size: 18px; } }
    @media (max-width: 29.99em) {
      .help_box-question .question {
        padding: 12px 16px;
        font-size: 18px; } }

@media (min-width: 50em) {
  .help_box-answer {
    padding: 32px 8px; } }

@media (min-width: 30em) and (max-width: 49.99em) {
  .help_box-answer {
    padding: 24px 8px; } }

@media (max-width: 29.99em) {
  .help_box-answer {
    padding: 16px 8px; } }

.orders_table {
  position: relative; }
  @media (max-width: 29.99em) {
    .orders_table {
      margin: 0 -16px; } }
  .orders_table-head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1; }
    .orders_table-head.sticky {
      position: fixed; }
    @media (min-width: 50em) {
      .orders_table-head {
        height: 52px; } }
    @media (max-width: 49.99em) {
      .orders_table-head {
        height: 40px; } }
    .orders_table-head--row {
      display: flex;
      align-items: center;
      height: 100%; }
      @media (min-width: 64.0625em) {
        .orders_table-head--row {
          margin: 0 -32px; }
          .sticky .orders_table-head--row {
            margin: 0; } }
      @media (min-width: 50em) and (max-width: 64.0525em) {
        .orders_table-head--row {
          margin: 0 -24px; }
          .sticky .orders_table-head--row {
            margin: 0; } }
      @media (min-width: 30em) and (max-width: 49.99em) {
        .orders_table-head--row {
          margin: 0 -16px; }
          .sticky .orders_table-head--row {
            margin: 0; } }
      @media (max-width: 29.99em) {
        .orders_table-head--row {
          margin: 0 -16px; } }
      .orders_table-head--row:before {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: linear-gradient(to right, #18088D 0%, #7B1FA2 100%); }
      .orders_table-head--row div {
        font-weight: 500;
        position: relative;
        line-height: 1.2;
        color: #fff;
        text-align: left; }
        @media (min-width: 50em) {
          .orders_table-head--row div {
            padding: 0 32px;
            font-size: 16px; } }
        @media (min-width: 30em) and (max-width: 49.99em) {
          .orders_table-head--row div {
            padding: 0 16px;
            font-size: 12px; } }
        @media (max-width: 29.99em) {
          .orders_table-head--row div {
            padding: 0 8px;
            font-size: 10px; } }
    .orders_table-head .content {
      height: 100%; }
  .orders_table-row {
    border-top: 8px solid #EEEEEE;
    background: #fff; }
    @media (min-width: 50em) {
      .orders_table-row td {
        height: 56px;
        padding: 0 32px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .orders_table-row td {
        height: 40px;
        padding: 0 16px;
        font-size: 12px; } }
    @media (max-width: 29.99em) {
      .orders_table-row td {
        height: 32px;
        padding: 0 8px;
        font-size: 10px; } }
  .orders_table-table {
    width: 100%;
    border-collapse: collapse;
    position: relative; }
  .orders_table-thead {
    color: #fff;
    text-align: left; }
    @media (min-width: 50em) {
      .orders_table-thead {
        font-size: 16px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .orders_table-thead {
        font-size: 12px; } }
    @media (max-width: 29.99em) {
      .orders_table-thead {
        font-size: 10px; } }
    .orders_table-thead th {
      opacity: 0;
      font-weight: 500;
      line-height: 1.2; }
      @media (min-width: 50em) {
        .orders_table-thead th {
          height: 56px;
          padding: 0 32px; } }
      @media (min-width: 30em) and (max-width: 49.99em) {
        .orders_table-thead th {
          height: 40px;
          padding: 0 16px; } }
      @media (max-width: 29.99em) {
        .orders_table-thead th {
          height: 40px;
          padding: 0 8px; } }
  .orders_table-cart .cart_table {
    display: none;
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 64.0625em) {
      .orders_table-cart .cart_table {
        max-width: calc(200%/3); } }
    @media (min-width: 50em) and (max-width: 64.0525em) {
      .orders_table-cart .cart_table {
        max-width: calc(200%/2.5); } }
    @media (min-width: 50em) {
      .orders_table-cart .cart_table table {
        margin: 24px 0; } }
    @media (max-width: 49.99em) {
      .orders_table-cart .cart_table table {
        margin: 8px 0; } }
  .orders_table-expand_btn {
    margin-left: auto; }
    @media (min-width: 50em) {
      .orders_table-expand_btn {
        margin-right: -32px; } }
    @media (min-width: 30em) and (max-width: 49.99em) {
      .orders_table-expand_btn {
        margin-right: -16px; } }
    @media (max-width: 29.99em) {
      .orders_table-expand_btn {
        margin-right: -8px; } }
    .orders_table-expand_btn:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-style: solid solid none none;
      border-color: #fff;
      border-width: 3px;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      transition: all 0.25s;
      transform: rotate(135deg) translate(-2px, 2px); }
    .opened .orders_table-expand_btn:after {
      transform: rotate(315deg) translate(-2px, 2px); }

.catalog_item-main {
  display: flex;
  margin: 0 -4px 8px; }
  @media (max-width: 64.0525em) {
    .catalog_item-main {
      flex-wrap: wrap; } }
  @media (max-width: 49.99em) {
    .catalog_item-main .catalog_item-data--about_wrap {
      display: none; } }

.catalog_item-data {
  padding: 0 4px; }
  @media (min-width: 64.0625em) {
    .catalog_item-data {
      width: 75%; } }
  @media (max-width: 64.0525em) {
    .catalog_item-data {
      width: 100%;
      margin-bottom: 8px; } }
  .catalog_item-data--general {
    margin: 0 -4px; }
    @media (min-width: 50em) {
      .catalog_item-data--general {
        display: flex; } }
  .catalog_item-data--about {
    height: 100%;
    background: #fff; }
    @media (min-width: 88.8125em) {
      .catalog_item-data--about {
        padding: 32px 24px 32px 48px; } }
    @media (min-width: 30em) and (max-width: 88.8025em) {
      .catalog_item-data--about {
        padding: 32px; } }
    @media (max-width: 29.99em) {
      .catalog_item-data--about {
        padding: 16px; } }
    @media (min-width: 50em) {
      .catalog_item-data--about_wrap {
        width: calc(4*100%/9);
        padding: 0 4px; } }
    @media (max-width: 49.99em) {
      .catalog_item-data--about_wrap {
        margin-bottom: 16px;
        margin-top: 16px; } }
    .catalog_item-data--about dl {
      font-size: 16px;
      color: #546E7A;
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .catalog_item-data--about dl dt {
        margin-right: 4px; }
    .catalog_item-data--about .adds {
      display: flex;
      align-items: center;
      margin-bottom: 16px; }
      .catalog_item-data--about .adds > * {
        margin-right: 16px; }
      .catalog_item-data--about .adds-title {
        margin-top: 24px;
        margin-bottom: 16px; }

.catalog_item-heading {
  background: #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 8px 16px; }
  @media (min-width: 50em) {
    .catalog_item-heading {
      min-height: 96px; } }
  @media (max-width: 49.99em) {
    .catalog_item-heading {
      min-height: 60px; } }
  @media (min-width: 50em) {
    .catalog_item-heading--like {
      width: 54px;
      height: 50px;
      margin-right: 24px; } }
  @media (max-width: 49.99em) {
    .catalog_item-heading--like {
      width: 30px;
      height: 28px;
      margin-right: 16px; } }

.catalog_item-gallery {
  background: #fff;
  height: 100%; }
  .catalog_item-gallery_wrap {
    padding: 0 4px; }
    @media (min-width: 50em) {
      .catalog_item-gallery_wrap {
        width: calc(5*100%/9); } }
    @media (max-width: 49.99em) {
      .catalog_item-gallery_wrap {
        margin-bottom: 8px; } }

.catalog_item-sidebar {
  background: #FCEBDD;
  height: 100%; }
  @media (min-width: 88.8125em) {
    .catalog_item-sidebar {
      padding: 64px 32px; } }
  @media (min-width: 64.0625em) and (max-width: 88.8025em) {
    .catalog_item-sidebar {
      padding: 48px 24px; } }
  @media (min-width: 30em) and (max-width: 64.0525em) {
    .catalog_item-sidebar {
      padding: 24px; } }
  @media (max-width: 29.99em) {
    .catalog_item-sidebar {
      padding: 16px; } }
  .catalog_item-sidebar--wrap {
    padding: 0 4px; }
    @media (min-width: 64.0625em) {
      .catalog_item-sidebar--wrap {
        width: 25%; } }
    @media (max-width: 64.0525em) {
      .catalog_item-sidebar--wrap {
        width: 100%; } }

.catalog_item-prices {
  font-size: 16px;
  color: #546E7A;
  margin-bottom: 24px;
  white-space: nowrap; }
  .catalog_item-prices .price-old {
    text-decoration: line-through; }
  .catalog_item-prices .price-actual {
    font-weight: 600;
    color: #546E7A; }
    @media (min-width: 80.0625em) {
      .catalog_item-prices .price-actual {
        font-size: 48px; } }
    @media (max-width: 80.0525em) {
      .catalog_item-prices .price-actual {
        font-size: 32px; } }

.catalog_item-more_info {
  background: #fff; }
  @media (min-width: 80.0625em) {
    .catalog_item-more_info {
      padding: 48px; } }
  @media (min-width: 30em) and (max-width: 80.0525em) {
    .catalog_item-more_info {
      padding: 32px; } }
  @media (max-width: 29.99em) {
    .catalog_item-more_info {
      padding: 16px; } }
  @media (max-width: 80.0525em) {
    .catalog_item-more_info .content {
      padding: 0; } }
  @media (min-width: 80.0625em) {
    .catalog_item-more_info .adds-title:not(:last-child) {
      margin-bottom: 48px; } }
  @media (min-width: 30em) and (max-width: 80.0525em) {
    .catalog_item-more_info .adds-title:not(:last-child) {
      margin-bottom: 32px; } }
  @media (max-width: 29.99em) {
    .catalog_item-more_info .adds-title:not(:last-child) {
      margin-bottom: 16px; } }

.catalog_item-spin .text {
  font-size: 20px; }

.catalog_item-spin .form-spinner {
  padding: 24px 0; }

.listing-columns {
  display: flex;
  margin: 0 -4px; }
  @media (max-width: 64.0525em) {
    .listing-columns {
      flex-wrap: wrap; } }

.listing-sidebar {
  padding: 0 4px;
  width: 100%;
  flex-shrink: 0; }
  @media (min-width: 80.0625em) {
    .listing-sidebar {
      max-width: calc( 100%/6); } }
  @media (min-width: 64.0625em) and (max-width: 80.0525em) {
    .listing-sidebar {
      max-width: calc( 100%/5); } }
  @media (max-width: 64.0525em) {
    .listing-sidebar {
      margin-bottom: 8px; } }

.listing-main {
  padding: 0 4px;
  width: 100%; }

.listing-filters {
  background-color: #fff;
  margin-bottom: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center; }
  @media (min-width: 50em) {
    .listing-filters {
      padding: 0 24px;
      min-height: 56px; } }
  @media (max-width: 49.99em) {
    .listing-filters {
      padding: 0 16px;
      min-height: 40px; } }
  @media (max-width: 37.49em) {
    .listing-filters {
      display: none; } }
  .listing-filters a {
    text-transform: lowercase;
    color: #546E7A;
    margin-right: 36px; }

.listing-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px; }

.listing-item {
  padding: 0 4px;
  margin-bottom: 8px; }
  .listing-item.col-1-6 {
    width: 100%; }
    @media (min-width: 80.0625em) {
      .listing-item.col-1-6 {
        max-width: calc( 100%/6); } }
    @media (min-width: 64.0625em) and (max-width: 80.0525em) {
      .listing-item.col-1-6 {
        max-width: calc( 100%/5); } }
    @media (min-width: 50em) and (max-width: 64.0525em) {
      .listing-item.col-1-6 {
        max-width: calc( 100%/4); } }
    @media (min-width: 37.5em) and (max-width: 49.99em) {
      .listing-item.col-1-6 {
        max-width: calc( 100%/3); } }
    @media (min-width: 30em) and (max-width: 37.49em) {
      .listing-item.col-1-6 {
        max-width: calc( 100%/2); } }
  .listing-item.col-1-5 {
    width: 100%; }
    @media (min-width: 80.0625em) {
      .listing-item.col-1-5 {
        max-width: calc( 100%/5); } }
    @media (min-width: 50em) and (max-width: 80.0525em) {
      .listing-item.col-1-5 {
        max-width: calc( 100%/4); } }
    @media (min-width: 37.5em) and (max-width: 49.99em) {
      .listing-item.col-1-5 {
        max-width: calc( 100%/3); } }
    @media (min-width: 30em) and (max-width: 37.49em) {
      .listing-item.col-1-5 {
        max-width: calc( 100%/2); } }
  @media (max-width: 29.99em) {
    .listing-item {
      margin-bottom: 16px; } }
  .listing-item--section {
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.25s; }
    @media screen and (min-width: 1025px) {
      .listing-item--section:hover {
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.16); }
        .listing-carousel .listing-item--section:hover {
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16); }
        .listing-item--section:hover .listing-item--prices,
        .listing-item--section:hover .listing-item--title {
          color: #1CB47A; } }
  .listing-item--img {
    position: relative; }
    @media (min-width: 88.8125em) {
      .listing-item--img {
        padding: 32px; } }
    @media (max-width: 88.8025em) {
      .listing-item--img {
        padding: 24px; } }
  .listing-item--img_box {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block; }
    .listing-item--img_box picture,
    .listing-item--img_box img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
  .listing-item--sale {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background: #1CB47A;
    color: #fff;
    font-size: 24px;
    font-weight: 500; }
    .listing-item .listing-item--sale {
      position: absolute; }
      @media (min-width: 88.8125em) {
        .listing-item .listing-item--sale {
          left: 16px;
          top: 16px; } }
      @media (max-width: 88.8025em) {
        .listing-item .listing-item--sale {
          left: 8px;
          top: 8px; } }
  .listing-item--marks {
    position: absolute;
    left: 16px;
    display: flex;
    align-items: center; }
    @media (min-width: 88.8125em) {
      .listing-item--marks {
        bottom: 0; } }
    @media (max-width: 88.8025em) {
      .listing-item--marks {
        bottom: -8px; } }
    .listing-item--marks .mark {
      margin-right: 8px; }
  .listing-item--mark {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 6px;
    display: inline-flex; }
    .listing-item--mark.mark-new {
      background: #546E7A;
      color: #fff; }
    .listing-item--mark.mark-top {
      background: #FF5252;
      color: #fff; }
  .listing-item--data {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 88.8125em) {
      .listing-item--data {
        padding: 20px 24px; } }
    @media (max-width: 88.8025em) {
      .listing-item--data {
        padding: 16px; } }
  .listing-item--title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #546E7A; }
    .listing-item--title a {
      color: inherit; }
  .listing-item--descr {
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end; }
  .listing-item--prices {
    color: #546E7A;
    display: flex;
    flex-direction: column; }
    .listing-item--prices .old-price {
      font-size: 16px;
      text-decoration: line-through; }
    .listing-item--prices .actual-price {
      font-size: 20px;
      font-weight: 600; }
  .listing-item--icons {
    display: flex;
    align-items: center;
    margin-bottom: 8px; }
    .listing-item--icons > * {
      margin-left: 24px; }
  .listing-item--like {
    display: inline-flex;
    width: 24px;
    height: 24px; }
  .listing-item--info {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid currentColor;
    color: #B0BEC5;
    background: none;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: all 0.2s;
    outline: none; }
    @media screen and (min-width: 1025px) {
      .listing-item--info:hover {
        color: #1CB47A; } }

.listing-cta {
  text-align: center; }
  @media (min-width: 64.0625em) {
    .listing-cta {
      margin-top: 64px; } }
  @media (min-width: 37.5em) and (max-width: 64.0525em) {
    .listing-cta {
      margin-top: 48px; } }
  @media (max-width: 37.49em) {
    .listing-cta {
      margin-top: 24px; } }
  .listing-cta .btn {
    width: 100%;
    max-width: 500px; }

.short_listing {
  margin: 0 -20px 0 0;
  overflow: auto;
  max-height: calc(100vh - 275px); }
  .short_listing-item {
    display: flex;
    align-items: center;
    padding-right: 16px;
    margin-bottom: 4px; }
    .short_listing-item--link {
      width: 100%;
      display: flex;
      align-items: center;
      color: #546E7A; }
      @media screen and (min-width: 1025px) {
        .short_listing-item--link:hover {
          color: #1CB47A; } }
      @media (max-width: 29.99em) {
        .short_listing-item--link {
          flex-wrap: wrap; } }
    .short_listing-item--img {
      position: relative;
      width: 120px;
      flex-shrink: 0;
      margin-right: 8px; }
    .short_listing-item--img_box {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      display: block; }
      .short_listing-item--img_box img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
    .short_listing-item--data {
      padding: 8px;
      flex-grow: 1;
      display: flex;
      align-items: flex-start; }
      @media (max-width: 29.99em) {
        .short_listing-item--data {
          padding: 0;
          margin-top: 16px; } }
    .short_listing-item--heading {
      width: 65%;
      padding-right: 8px; }
      .custom_modal .short_listing-item--heading {
        width: 100%; }
    .short_listing-item--title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 4px; }
    .short_listing-item--category {
      font-size: 16px; }
    .short_listing-item--price {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap; }
    .short_listing-item--icons {
      display: flex;
      align-items: center;
      flex-shrink: 0; }
    .short_listing-item--like {
      display: inline-flex;
      width: 24px;
      height: 24px; }
    .short_listing-item--info {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid currentColor;
      color: #B0BEC5;
      background: none;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      transition: all 0.2s; }
      @media screen and (min-width: 1025px) {
        .short_listing-item--info:hover {
          color: #1CB47A; } }

@media (max-width: 64.0525em) {
  .sidebar {
    display: flex;
    flex-direction: column; }
    .sidebar.sticky {
      box-sizing: content-box;
      box-shadow: 0 8px 8px 0 rgba(84, 110, 122, 0.2); } }

@media (min-width: 50em) and (max-width: 64.0525em) {
  .sidebar.sticky {
    margin: 0 -24px;
    padding: 0 24px; } }

@media (max-width: 49.99em) {
  .sidebar.sticky {
    margin: 0 -16px;
    padding: 0 16px; } }

.sidebar-section {
  background-color: #fff; }
  @media (min-width: 88.8125em) {
    .sidebar-section {
      padding: 24px;
      margin-bottom: 8px;
      width: 100%; }
      .sidebar-section.sidebar-section--categories {
        padding-bottom: 4px; } }
  @media (min-width: 64.0625em) and (max-width: 88.8025em) {
    .sidebar-section {
      padding: 16px;
      margin-bottom: 8px;
      width: 100%; }
      .sidebar-section.sidebar-section--categories {
        padding-bottom: 4px; } }
  .sidebar-section--filters {
    position: relative; }
    @media (min-width: 64.0625em) {
      .sidebar-section--filters {
        max-height: calc(100vh - 16px);
        overflow: auto; } }
    .sidebar-section--filters .selected-filters .list [data-filter-item] {
      cursor: pointer;
      padding-right: 30px;
      position: relative;
      display: inline-block;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden; }
      @media screen and (min-width: 1025px) {
        .sidebar-section--filters .selected-filters .list [data-filter-item]:hover {
          color: #1CB47A; } }
      .sidebar-section--filters .selected-filters .list [data-filter-item]:after {
        content: "×";
        position: absolute;
        font-size: 1.5em;
        font-weight: 600;
        bottom: 0;
        height: 20px;
        right: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center; }
    .sidebar-section--filters .open_filters {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      z-index: -2;
      pointer-events: none; }
      @media (max-width: 64.0525em) {
        .sidebar-section--filters .open_filters:checked ~ .form {
          display: block; }
          .no-drops .sidebar-section--filters .open_filters:checked ~ .form {
            display: none; } }
      .sidebar-section--filters .open_filters-btn {
        color: #546E7A;
        background-color: #E4FFF9;
        font-size: 16px;
        line-height: 32px;
        display: block;
        transition: none; }
        @media (min-width: 50em) and (max-width: 64.0525em) {
          .sticky .sidebar-section--filters .open_filters-btn {
            margin: 0 -24px;
            padding: 0 24px; } }
        @media (max-width: 49.99em) {
          .sticky .sidebar-section--filters .open_filters-btn {
            margin: 0 -16px;
            padding: 0 16px; } }
        @media (min-width: 64.0625em) {
          .sidebar-section--filters .open_filters-btn {
            display: none; } }
    @media (max-width: 64.0525em) {
      .sidebar-section--filters .form {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        box-shadow: 0 8px 8px 0 rgba(84, 110, 122, 0.5);
        background: #fff;
        max-height: 50vh;
        overflow: auto;
        padding: 8px 0 16px; }
        .sticky .sidebar-section--filters .form {
          left: -16px;
          right: -16px; } }

@media (max-width: 64.0525em) {
  .sidebar-filters {
    display: flex;
    flex-wrap: wrap; } }

@media (min-width: 30em) and (max-width: 64.0525em) {
  .sidebar-filters--fieldset {
    width: 100%;
    max-width: 600px;
    padding: 0 16px; }
    .sticky .sidebar-filters--fieldset {
      padding: 0 24px; } }

@media (max-width: 29.99em) {
  .sidebar-filters--fieldset {
    padding: 0 16px; }
    .sticky .sidebar-filters--fieldset {
      padding: 0 24px; } }

.sidebar-filters--fieldset:not(:last-child) {
  margin-bottom: 24px; }

@media (min-width: 64.0625em) {
  .sidebar-filters--fieldset:last-child {
    margin-top: 48px; } }

@media (max-width: 64.0525em) {
  .sidebar-filters--fieldset:last-child {
    margin-top: 24px; } }

.sidebar-filters--fieldset .form-checkbox:not(:last-child) {
  margin-bottom: 8px; }

.sidebar-filters--fieldset .colors {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px; }

.sidebar-filters--fieldset .checkbox_color {
  margin-right: 8px;
  margin-bottom: 8px; }

@media (max-width: 64.0525em) {
  .sidebar-filters--checkbox_set {
    display: flex;
    flex-wrap: wrap; }
    .sidebar-filters--checkbox_set .form-checkbox {
      min-width: 50%;
      padding-right: 16px; } }

@media (max-width: 64.0525em) {
  .sidebar-filters--btnset {
    width: 100%; } }

.sidebar-filters--title {
  margin-bottom: 10px; }
  .sidebar-filters--title.fn-expand-btn {
    position: relative;
    padding-right: 24px;
    cursor: pointer; }
    .sidebar-filters--title.fn-expand-btn:before {
      content: "";
      position: absolute;
      right: 0;
      margin: 0 3px 0 0;
      width: 9px;
      height: 9px;
      border-style: solid solid none none;
      border-width: 2px;
      border-color: #b0bec5;
      transform: rotate(135deg) translate(-2px, 2px);
      transition: transform .25s,-webkit-transform .25s;
      top: 8px; }
      .expanded .sidebar-filters--title.fn-expand-btn:before {
        transform: rotate(-45deg) translate(-2px, 2px); }

.sidebar-filters--reset {
  color: #546E7A;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px; }
  @media (min-width: 64.0625em) and (max-width: 88.8025em) {
    .sidebar-filters--reset {
      letter-spacing: 0; } }

.sidebar_categories {
  color: #546E7A; }
  .sidebar_categories-item {
    padding-left: 24px; }
    .sidebar_categories-item.fn-expand-box > .fn-expand-btn {
      position: relative; }
      .sidebar_categories-item.fn-expand-box > .fn-expand-btn:before {
        content: "";
        position: absolute;
        right: 100%;
        margin: 0 12px 0 0;
        width: 9px;
        height: 9px;
        border-style: solid solid none none;
        border-width: 2px;
        border-color: #B0BEC5;
        transform: rotate(45deg) translate(-2px, 2px);
        transition: transform 0.25s;
        top: 8px; }
    .sidebar_categories-item.fn-expand-box.expanded > .fn-expand-btn:before {
      transform: rotate(135deg) translate(-2px, 2px); }
  .sidebar_categories-sublist {
    display: none; }
  @media (min-width: 64.0625em) {
    .sidebar_categories-subitem {
      margin-top: 16px; } }
  .sidebar_categories-title {
    cursor: pointer; }
    .sidebar_categories-title:after {
      content: "";
      display: block;
      width: 100%;
      height: 20px; }
  .sidebar_categories a {
    color: inherit;
    background: linear-gradient(to bottom, #1CB47A 0%, #1CB47A 100%);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    padding: 2px 0;
    transition: background-size .3s;
    position: relative; }
    @media screen and (min-width: 1025px) {
      .sidebar_categories a:hover {
        background-size: 98% 2px; } }
    .sidebar_categories a.active {
      background-size: 98% 2px; }

@media (min-width: 64.0625em) {
  .listing_slider {
    margin-top: 95px; } }

@media (max-width: 64.0525em) {
  .listing_slider {
    margin-top: 60px; } }

@media (min-width: 64.0625em) {
  .listing_slider-title {
    margin-bottom: 48px; } }

@media (max-width: 64.0525em) {
  .listing_slider-title {
    margin-bottom: 24px; } }

.types-banner {
  position: relative;
  margin-top: 24px; }
  .types-banner--img {
    width: 100%; }
    @media (min-width: 50em) {
      .types-banner--img {
        height: 400px; } }
    @media (max-width: 49.99em) {
      .types-banner--img {
        height: 240px; } }
  .types-banner--title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff; }

.types-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 32px 0; }
  .types-nav .link {
    margin-right: 32px;
    color: #546E7A; }
    .types-nav .link:after {
      height: 1px;
      background: none;
      border-bottom: 1px dashed currentColor;
      opacity: 1;
      transform: translateY(0); }
    @media screen and (min-width: 1025px) {
      .types-nav .link:hover:after {
        opacity: 0;
        transform: translateY(4px); } }

@media (min-width: 64.0625em) {
  .types-heading:not(:first-child) {
    margin-top: 64px; } }

@media (max-width: 64.0525em) {
  .types-heading:not(:first-child) {
    margin-top: 32px; } }

.types-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px; }

.types-item {
  padding: 0 4px;
  margin-bottom: 8px;
  width: 100%; }
  @media (min-width: 64.0625em) {
    .types-item {
      max-width: calc( 100%/4); } }
  @media (min-width: 50em) and (max-width: 64.0525em) {
    .types-item {
      max-width: calc( 100%/4); } }
  @media (min-width: 30em) and (max-width: 49.99em) {
    .types-item {
      max-width: calc( 100%/2); } }
  @media (max-width: 29.99em) {
    .types-item {
      margin-bottom: 16px; } }
  .types-item--section {
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.25s;
    color: #546E7A; }
    @media screen and (min-width: 1025px) {
      .types-item--section:hover {
        color: #1CB47A;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.16); }
        .listing-carousel .types-item--section:hover {
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16); } }
  .types-item--img {
    position: relative; }
  .types-item--img_box {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block; }
    .types-item--img_box picture,
    .types-item--img_box img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
  .types-item--data {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 88.8125em) {
      .types-item--data {
        padding: 20px 24px; } }
    @media (max-width: 88.8025em) {
      .types-item--data {
        padding: 16px; } }
  .types-item--title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500; }
    @media (min-width: 64.0625em) {
      .types-item--title {
        margin-bottom: 32px; } }
    @media (max-width: 64.0525em) {
      .types-item--title {
        margin-bottom: 24px; } }
    .types-item--title h3 {
      font: inherit; }
  .types-item--cta {
    margin-top: auto;
    width: 100%; }
    @media (max-width: 64.0525em) {
      .types-item--cta .btn {
        font-size: 14px;
        padding: 0 4px;
        width: 100%; } }

.advantages {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px; }
  .advantages-item {
    width: 100%;
    display: flex; }
    @media (min-width: 50em) {
      .advantages-item {
        max-width: calc( 100%/3);
        padding: 32px calc( 100%/12) 32px 4px; } }
    @media (min-width: 50em) and (max-width: 80.0525em) {
      .advantages-item {
        flex-direction: column; } }
    @media (max-width: 49.99em) {
      .advantages-item {
        padding: 8px 4px; }
        .advantages-item:not(:last-child) {
          margin-bottom: 24px; } }
    @media (max-width: 29.99em) {
      .advantages-item {
        flex-direction: column; } }
  .advantages-icon {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 32px; }
    @media (min-width: 50em) and (max-width: 80.0525em) {
      .advantages-icon {
        margin-bottom: 32px; } }
    @media (max-width: 29.99em) {
      .advantages-icon {
        margin-bottom: 24px; } }
  .advantages-title {
    font-size: 20px; }
    @media (min-width: 50em) {
      .advantages-title {
        margin-bottom: 24px; } }
    @media (max-width: 49.99em) {
      .advantages-title {
        margin-bottom: 16px; } }

@media (min-width: 64.0625em) {
  .page_action {
    margin-top: 80px; } }

@media (min-width: 50em) and (max-width: 64.0525em) {
  .page_action {
    margin-top: 60px; } }

@media (max-width: 49.99em) {
  .page_action {
    margin-top: 40px; } }

@media (min-width: 37.5em) {
  .page_action {
    display: flex;
    align-items: center; } }

@media (min-width: 37.5em) {
  .page_action .pagination {
    margin-left: auto; } }

.desktop .pl-nav-scroll {
  overflow: hidden;
  height: 100%; }

.pl-nav-scroll .mCSB_inside > .mCSB_container {
  margin: 0; }

.desktop .pl-scroll {
  overflow: hidden;
  height: 100%; }

.pl-scroll .mCSB_inside > .mCSB_container {
  margin: 0; }

.sb-scroll .mCSB_scrollTools {
  width: 7px; }

.sb-scroll .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #B0BEC5 !important;
  width: 100%;
  margin: 0;
  border-radius: 0; }

.range-slider--inputs {
  display: flex;
  align-items: center; }
  .range-slider--inputs .dash {
    margin: 0 8px;
    width: 16px;
    height: 1px;
    background-color: #B0BEC5;
    flex-shrink: 0; }
  .range-slider--inputs input {
    appearance: none;
    align-items: center;
    width: 100%;
    height: 32px;
    background: #EEEEEE;
    border: none;
    color: #546E7A;
    padding: 0 16px;
    outline: none; }
    .range-slider--inputs input::-webkit-inner-spin-button, .range-slider--inputs input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }

.range {
  padding-top: 24px; }
  .range .noUi-target {
    height: 4px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: #B0BEC5;
    margin-bottom: 16px; }
  .range .noUi-tooltip {
    border: none;
    border-radius: 0;
    font-size: 12px;
    color: #546E7A;
    padding: 0;
    background: none; }
  .range .noUi-horizontal .noUi-handle {
    width: 4px;
    height: 16px;
    background: #1CB47A;
    border: none;
    box-shadow: none;
    right: -2px !important;
    cursor: pointer; }
    .range .noUi-horizontal .noUi-handle:before, .range .noUi-horizontal .noUi-handle:after {
      display: none; }

.listing-carousel.owl-carousel > .listing-item {
  max-width: calc( 100%/6);
  float: left;
  width: 100%; }

.listing-carousel.owl-carousel .owl-nav button.owl-prev, .listing-carousel.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff; }

@media (min-width: 64.0625em) {
  .listing-carousel.owl-carousel .owl-nav button.owl-prev {
    left: -30px; } }

@media (max-width: 64.0525em) {
  .listing-carousel.owl-carousel .owl-nav button.owl-prev {
    left: -16px; } }

@media (min-width: 64.0625em) {
  .listing-carousel.owl-carousel .owl-nav button.owl-next {
    right: -30px; } }

@media (max-width: 64.0525em) {
  .listing-carousel.owl-carousel .owl-nav button.owl-next {
    right: -16px; } }
