.clearfix:after {content: ""; display: block; clear: both;}
.content {
  margin: 0 auto;
  display: block;
  @include mq($from: desktop) {
    padding: 0 $padding-xl;
  }
  @include mq(tablet, desktop) {
    padding: 0 $padding-lg;
  }
  @include mq($until: tablet) {
    padding: 0 $padding-md;
  }
  &--lg {
    max-width: 1680px;
  }
  &--md {
    max-width: 1128px;
  }
  &--sm {
    max-width: 980px;
  }
  &--xs {
    max-width: 872px;
  }
  &--xxs {
    max-width: 632px;
  }
}

.hide {
  display: none !important;
}
.invalid {
  //color: rgba(255,128,124,.5);
  //input,
  //textarea,
  //select {background-color: $clr-invalid-lt !important;}
  //display: flex;
}

.u-nowrap {
  white-space: nowrap;
}
.u-align-center {
  text-align: center;
}


.desktop-hidden {
  @include mq($from: desktop) {
    display: none !important;
  }
}
.tablet-hidden {
  @include mq($until: desktop) {
    display: none !important;
  }
}
.wide-hidden {
  @include mq($from: tablet) {
    display: none !important;
  }
}
.mobile-hidden {
  @include mq($until: tablet) {
    display: none !important;
  }
}
.small-hidden {
  @include mq($until: mobile_xl) {
    display: none !important;
  }
}