.header {
  flex-shrink: 0;
  background-color: $white;
  z-index: 50;
  box-shadow: 0 2px 2px 0 rgba($text,0.2);
  @include mq($until: desktop) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }
  @include mq($from: tablet) {
    transition: opacity 0.5s;
    .blured & {
      opacity: 0.3;
    }
  }
  &-logo {

    display: flex;
    align-items: center;
    position: relative;
    @include mq($from: desktop_md) {
      //width: 30%;
    }
    @include mq(desktop, desktop_md) {
      //width: 40%;
    }
    .burger {
      @extend %btn_reset;
      flex-shrink: 0;
      @include mq($from: mobile_xl) {
        margin-right: $padding-lg;
      }
      @include mq($until: mobile_xl) {
        margin-right: 16px;
      }
    }
  }
  &-language {
    font-size: $font;
    font-weight: 500;
    position: relative;
    @include mq($from: desktop_md) {
      margin-left: 24px;
      margin-right: 24px;
    }
    @include mq(tablet, desktop_md) {
      margin-right: 24px;
      margin-left: 12px;
    }
    @include mq(mobile, tablet) {
      margin: 0 24px;
    }
    @include mq($until: mobile) {
      margin: 0 16px;
    }
    @include hover(){
      .header-language--list {
        opacity: 1;
        pointer-events: auto;
      }
    }
    @include mq($from: desktop) {
      [data-lang] {
        padding-left: 40px;
        position: relative;
        display: block;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 32px;
          height: 24px;
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% auto;
          border: 1px solid #eee;
        }
      }
      [data-lang="ru"]:before {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2024%22%20enable-background%3D%22new%200%200%2032%2024%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22m0%200v8%208%208h32v-8-8-8z%22/%3E%3Cpath%20fill%3D%22%230052b4%22%20d%3D%22m0%200h32v24h-32z%22/%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22m0%200h32v8h-32z%22/%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m0%2016h32v8h-32z%22/%3E%3C/svg%3E');
      }
      [data-lang="en"]:before {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2024%22%20enable-background%3D%22new%200%200%2032%2024%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%3Cdefs%3E%3Cpath%20id%3D%220%22%20d%3D%22m19.5%2016.2l12.5%207.8v-2.2l-9-5.6z%22/%3E%3Cpath%20id%3D%221%22%20d%3D%22M12.5%207.8%200%200%200%202.2%209%207.8z%22/%3E%3C/defs%3E%3Cpath%20fill%3D%22%23f0f0f0%22%20d%3D%22m0%200h32v24h-32z%22/%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m18%200h-4v9.8h-14v4.5h14v9.7h4v-9.7h14v-4.5h-14z%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22m24.6%2016.2l7.4%204.6v-4.6z%22/%3E%3Cuse%20xlink%3Ahref%3D%22%230%22/%3E%3Cpath%20d%3D%22m28.7%2024l-9.2-5.7v5.7z%22/%3E%3C/g%3E%3Cuse%20fill%3D%22%23f0f0f0%22%20xlink%3Ahref%3D%22%230%22/%3E%3Cuse%20fill%3D%22%23d80027%22%20xlink%3Ahref%3D%22%230%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22m5.6%2016.2l-5.6%203.5v-3.5z%22/%3E%3Cpath%20d%3D%22m12.5%2017.2v6.8h-10.9z%22/%3E%3C/g%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m9%2016.2l-9%205.6v2.2l12.5-7.8z%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22M7.4%207.8%200%203.2%200%207.8z%22/%3E%3Cuse%20xlink%3Ahref%3D%22%231%22/%3E%3Cpath%20d%3D%22m3.3%200l9.2%205.7v-5.7z%22/%3E%3C/g%3E%3Cuse%20fill%3D%22%23f0f0f0%22%20xlink%3Ahref%3D%22%231%22/%3E%3Cuse%20fill%3D%22%23d80027%22%20xlink%3Ahref%3D%22%231%22/%3E%3Cg%20fill%3D%22%230052b4%22%3E%3Cpath%20d%3D%22m26.4%207.8l5.6-3.5v3.5z%22/%3E%3Cpath%20d%3D%22m19.5%206.8v-6.8h10.9z%22/%3E%3C/g%3E%3Cpath%20fill%3D%22%23d80027%22%20d%3D%22m23%207.8l9-5.6v-2.2l-12.5%207.8z%22/%3E%3C/svg%3E');
      }
    }

    &.is-active {
      @include mq($until: desktop) {
        .header-language--list {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
    &--selected {
      cursor: pointer;
    }
    &--list {
      box-shadow: 0 20px 20px 0 rgba($text,0.4);
      padding: $padding-lg;
      position: absolute;
      top: 50%;

      left: 50%;
      background-color: $white;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s;
      @include mq($from: desktop) {
        transform: translate(-50%, 44px);
        &:before {
          position: absolute;
          content: "";
          bottom: 100%;
          left: 0;
          right: 0;
          height: 44px;
        }
      }
      @include mq($until: desktop) {
        transform: translate(-50%, 24px);
      }

      li {
        &:not(:last-child){
          margin-bottom: 16px;
        }
      }
      a {
        color: inherit;
        @include hover(){
          color: $sale;
        }
        &.selected {
          pointer-events: none;
          color: $sale;
        }
      }
    }
  }
  &-currency {
    font-size: $font;
    font-weight: 500;
    position: relative;
    @include mq($from: desktop) {
      margin-left: 0;
      margin-right: 24px;
      .search-enabled & {
        display: none;
      }
    }
    @include mq(tablet, desktop) {
      margin-right: 24px;
      margin-left: 0;
    }
    @include mq(mobile, tablet) {
      margin: 0 24px 0 0;
    }
    @include mq($until: mobile) {
      margin: 0 16px 0 0;
    }
    @include hover(){
      .header-currency--list {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &.is-active {
      @include mq($until: desktop) {
        .header-currency--list {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
    &--selected {
      cursor: pointer;
    }
    &--list {
      box-shadow: 0 20px 20px 0 rgba($text,0.4);
      padding: $padding-lg;
      position: absolute;
      top: 50%;

      left: 50%;
      background-color: $white;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s;
      @include mq($from: desktop) {
        transform: translate(-50%, 44px);
        &:before {
          position: absolute;
          content: "";
          bottom: 100%;
          left: 0;
          right: 0;
          height: 44px;
        }
      }
      @include mq($until: desktop) {
        transform: translate(-50%, 24px);
      }

      li {
        &:not(:last-child){
          margin-bottom: 16px;
        }
      }
      a {
        color: inherit;
        @include hover(){
          color: $sale;
        }
        &.selected {
          pointer-events: none;
          color: $sale;
        }
      }
    }
  }
  &-partials {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mq($from: desktop_md) {
      margin-right: $padding-lg;
      margin-left: $padding-lg;
    }
    @include mq(desktop, desktop_md) {
      margin-right: 20px;
      margin-left: 20px;
    }
    @include mq($from: desktop) {
      position: relative;
      transition: all 0.25s;
      .search-enabled & {
        background-color: $white;
      }
    }
    @include mq($until: desktop) {
      margin-right: 12px;
    }
  }
  &-domain {

    @include mq($from: desktop_md) {
      margin-left: 20px;
      padding-left: 20px;
      position: absolute;
      left: 100%;
      white-space: nowrap;
    }
    @include mq(tablet, desktop_md) {
      margin-left: $padding-md;
      padding-left: $padding-md;
      position: absolute;
      left: 100%;
      width: 25vw;
      font-size: 12px;
    }
    @include mq($until: tablet) {
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background: $icon;
      @include mq($from: desktop_md) {
        height: 48px;
        margin: auto;
      }
      @include mq(desktop, desktop_md) {
        height: 32px;
        margin: auto;
      }
    }
  }
  &-search {
    position: relative;
    transition: all 0.25s;
    @include mq($from: desktop) {
      width: 40px;
      .header-search--fieldset {
        opacity: 0;
        pointer-events: none;
      }
      &.active {
        width: 100%;
        .header-search--opener {
          pointer-events: none;
          opacity: 0;
        }
        .header-search--fieldset {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
    @include mq(tablet, desktop) {
      right: $padding-lg;
      &.active {
        width: calc(100% - #{2*$padding-lg});
      }
    }
    @include mq($until: tablet) {
      right: $padding-md;
      &.active {
        width: calc(100% - #{2*$padding-md});
      }
    }
    @include mq($until: desktop) {
      position: absolute;
      bottom: 4px;
      width: 30px;
      transition: width 0.2s;
      z-index: 1;
      &.active {
        .header-search--opener {
          pointer-events: none;
        }
        .header-search--fieldset input {
          opacity: 1;
          pointer-events: auto;
          padding: 0 60px 0 $padding-lg;
        }
        .header-search--btn {
          background: transparent;
          pointer-events: auto;
        }
        .header-search--reset {
          display: flex;
        }
      }
    }
    @include mq($until: mobile_xl) {
      display: none;
    }
    &--opener {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      color: $link;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq($from: desktop) {
        width: 40px;
        height: 40px;
      }
      @include mq($until: desktop) {
        width: 30px;
        height: 30px;
        opacity: 0;
      }
    }
    &--fieldset {
      width: 100%;
      display: flex;
      position: relative;

      input {
        width: 100%;
        border: none;
        border-radius: 20px;
        background: $bg-gray;
        color: $tag;
        outline: none;
        @include mq($from: desktop) {
          height: 40px;
          padding: 0 130px 0 $padding-lg;
        }
        @include mq($until: desktop) {
          height: 30px;
          padding: 0 0 0 $padding-lg;
          opacity: 0;
          pointer-events: none;
          transition: all 0.2s;
        }
        &:focus {
          & ~ .header-search--reset {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
    &--btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $link;
      color: $white;
      font-weight: 600;
      @extend %btn_reset;
      border-radius: 20px;
      @include mq($from: desktop) {
        width: 96px;
        .icon {
          display: none;
        }
      }
      @include mq($until: desktop) {
        pointer-events: none;
        width: 30px;
        height: 30px;
        background: $white;
        .icon {
          color: $tag;
        }
        .text {
          display: none;
        }
      }
    }
    &--reset {
      @extend %btn_reset;
      color: $text;
      position: absolute;
      width: 28px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 100px;
      top: 0;
      opacity: 0;
      pointer-events: none;
      @include mq($until: desktop) {
        height: 30px;
        right: 30px;
        display: none;
      }
      .icon {
        width: 24px;
        height: 24px;
      }
      .filled & {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  &-profile {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &--auth {
      display: inline-flex;
      @extend %btn_reset;
      position: relative;

      @include hover(){
        .header-profile--auth_drop {
          opacity: 1;
          pointer-events: auto;
        }
        .icon {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            width: 32px;
          }
        }
      }
      .show-auth-menu {
        display: none;
      }
      @include mq($until: desktop) {
        &.is-active {
          .header-profile--auth_drop {
            opacity: 1;
            pointer-events: auto;
          }
        }
        .show-auth-menu {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      &_drop {
        z-index: 1;
        transition: opacity 0.25s;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background: $white;
        top: 100%;

        box-shadow: 0 10px 20px 0 rgba($text,0.5);
        padding: 0 $padding-xl $padding-xl;
        @include mq($from: desktop) {
          width: 528px;
          right: -1*$padding;
        }
        @include mq($until: desktop) {
          width: 92vw;
          max-width: 380px;
          right: -80px;
          max-height: 86vh;
          overflow: auto;
        }
      }
      &_menu {
        a {
          color: $text;
          text-transform: uppercase;
          font-weight: 600;
          display: inline-flex;
          align-items: center;
          @include hover(){
            color: $sale;
          }
          &.active {
            color: $sale;
          }
        }
        li {
          margin-bottom: $padding-xl;
        }
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }
  &-personal_info {
    display: flex;
    align-items: center;
    @include mq($until: mobile) {
      //display: none;
    }
    .link-icon {
      display: inline-flex;
      align-items: center;
      position: relative;
    }
    .count {
      position: absolute;
      top: 0;
      left: 100%;
      transform: translate(-12px,-8px);
      font-weight: 500;
      color: $white;
      background-color: $btn-red;

      border-radius: $padding-lg;

      @include mq($from: desktop_md) {
        line-height: $padding-lg;
        padding: 0 $padding;
      }
      @include mq(mobile_xl, desktop_md) {
        font-size: 12px;
        padding: 0 6px;
        line-height: 20px;
      }
      @include mq($until: mobile_xl) {
        font-size: 10px;
        padding: 0 4px;
        line-height: 16px;
      }
    }
    &--close {
      position: absolute;
      top: 12px;
      right: 12px;
      @extend %btn_reset;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20fill%3D%22%23546e7a%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012%2019%206.41z%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
    }
    &--views,
    &--favorites,
    &--basket {
      display: inline-flex;
      align-items: center;
      position: relative;
    }
    &--item {
      position: relative;

      display: inline-flex;
      align-items: center;
      @include mq($from: desktop_md) {
        margin-left: $padding-xl;
      }
      @include mq(mobile_xl, desktop_md) {
        margin-left: $padding-lg;
      }
      @include mq($until: mobile_xl) {
        margin-left: 12px;
      }
      @include hover(){
        .header-personal_info--short:not(.added-item) {
          opacity: 1;
          pointer-events: auto;
        }
        .link-icon {
          &:before {
            content: "";
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            width: 32px;
          }
        }
      }
    }
    &--short {
      z-index: 1;
      transition: opacity 0.25s;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      background: $white;
      width: 528px;
      top: 100%;
      right: -1*$padding;
      box-shadow: 0 10px 20px 0 rgba($text,0.5);
      padding: 0 $padding-xl $padding-xl;
      &_title {
        height: 48px;
        display: flex;
        align-items: center;
        background-color: $bg-gray;
        margin-left: -32px;
        margin-right: -32px;
        padding: 0 32px;
        &:not(:last-child){
          margin-bottom: $padding-lg;
        }
      }
      &.added-item {
        display: none;
        padding: 0;
        &.active {
          opacity: 1;
          pointer-events: auto;
          padding: 0 $padding-xl $padding-xl;
        }
      }
      &:not(.added-item){
        .added-order & {
          display: none;
        }
      }

      @include mq($until: desktop) {
        display: none !important;
      }
    }
    &--amount {
      margin-top: $padding-lg;
      &_title {
        font-size: $font;
        color: $tag;
      }
      .summ {
        font-weight: 600;
        font-size: 48px;
        margin-right: $padding-lg;
        white-space: nowrap;
      }
    }
    &--actions {
      margin-top: $padding-lg;
      &_title {
        font-size: $font;
        color: $tag;
      }
    }
    &--buttons {
      display: flex;
      align-items: center;
      .btn {
        &:not(:last-child){
          margin-right: 12px;
        }
      }
    }
    &--total {
      margin-left: $padding-xl;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 500;
      cursor: default;
      color: $text;
      @include mq($until: mobile_xl) {
        display: none;
      }
      .summ {
        white-space: nowrap;
        @include mq($from: desktop_md) {
          font-size: $font;
        }
        @include mq($until: desktop_md) {
          font-weight: 600;
        }
      }
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq($from: desktop) {
      height: 88px;
    }
    @include mq($until: desktop) {
      height: 62px;
    }
  }
  &-nav {

    display: flex;
    width: 100%;
    @include mq($from: desktop) {
      height: 48px;
      font-size: $font;
    }
    @include mq(tablet, desktop) {
      height: 38px;
      font-size: 12px;
    }
    @include mq($until: tablet) {
      height: 38px;
      font-size: 9px;
    }
    &--wrap {
      background: linear-gradient(to right, $grad-begin 0%,$grad-end 100%);
      @include mq($until: mobile_xl) {
        display: none;
      }
    }
    &--list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      font-weight: 500;
      @include mq($until: desktop) {
        &:last-child:after {
          content: "";
          width: 30px;
          height: 38px;
        }
      }
      li {
        display: flex;

      }
      a {
        display: inline-flex;
        align-items: center;
        color: $white;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          right: 50%;
          height: 4px;
          background-color: $btn-red;
          transition: all 0.25s;
        }
        @include hover(){
          &:after {
            left: 0;
            right: 0;
          }
        }
        &.active {
          &:after {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
}