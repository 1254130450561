.checkbox_color {
  &-item {
    cursor: pointer;
    position: relative;
    display: block;
    input {
      @extend %hidden_input;
    }
  }
  &-label {
    display: block;
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 4px solid $sale;
      transition: opacity 0.1s;
      opacity: 0;
    }
  }
  input:checked ~ .checkbox_color-label {
    &:after {
      opacity: 1;
    }
  }
}