%fullBlock {
  width: 100%;
  height: 100%;
  display: block;
}
%img_fit {
  @extend %fullBlock;
  object-fit: cover;
}
%valign {
  display: inline-block;
  vertical-align: middle;
}
%hover_underline {
  &:hover {
    @include media(hover,960){
      text-decoration: underline;
    }
  }
}
%cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
%btn_reset {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}
%hidden_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
  opacity: 0;
}
