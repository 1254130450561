@import "home";
@import "cart";
@import "profile";
@import "help";
@import "orders";
@import "catalog-item";
@import "partials/items-listing";
@import "partials/short-items-listing";
@import "partials/listing-sidebar";
@import "partials/listing-slider";
@import "partials/types-listing";
@import "partials/advantages";

.page_action {

  @include mq($from: desktop) {
    margin-top: 80px;
  }
  @include mq(tablet, desktop) {
    margin-top: 60px;
  }
  @include mq($until: tablet) {
    margin-top: 40px;
  }
  @include mq($from: mobile_xl) {
    display: flex;
    align-items: center;
  }

  .pagination {
    @include mq($from: mobile_xl) {
      margin-left: auto;
    }
  }
}
