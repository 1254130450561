.footer {
  flex-shrink: 0;
  background-color: $white;

  position: relative;
  @include mq($from: tablet) {
    transition: opacity 0.5s;
    padding-top: 85px;
    .blured & {
      opacity: 0.3;
    }
  }
  @include mq($until: tablet) {
    padding-top: 60px;
  }
  &-scroll_top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    &--btn {
      margin-left: 100%;
      transform: translateX(-100%);
    }
  }
  &-top {

    width: 100%;

    font-weight: 500;
    color: $tag;
    margin-bottom: 60px;
    @include mq($from: tablet) {
      display: flex;
      font-size: $font-lg;
    }
    @include mq($until: tablet) {
      font-size: $font-md;
    }
    &--slogan {

      @include mq($from: tablet) {
        width: 58.333%;
      }
      @include mq($until: tablet) {
        margin-bottom: $padding-lg;
      }
    }
    &--telephone {
      a {
        color: inherit;
      }
    }
  }
  &-nav {
    display: flex;
    @include mq($until: desktop_md) {
      flex-wrap: wrap;
    }
    @include mq($from: tablet) {
      margin-bottom: 40px;
    }
    &--section {
      padding-right: $padding-md;
      @include mq($from: desktop_md) {
        width: 25%;
      }
      @include mq(tablet, desktop_md) {
        width: 33.33%;
      }
      @include mq(mobile, tablet) {
        width: 50%;
        margin-bottom: $padding-xl;
      }
      @include mq($until: mobile) {
        width: 100%;
        margin-bottom: $padding-xl;
      }
      &.nav-payments {
        @include mq(tablet, desktop_md) {
          width: 100%;
          order: 4;
          margin-top: $padding-xl;
        }
        @include mq($until: tablet) {
          width: 100%;
          order: 4;
        }
      }
      &_title {
        font-weight: 500;
        text-transform: uppercase;

        @include mq($from: tablet) {
          margin-bottom: $padding-xl;
        }
        @include mq($until: tablet) {
          margin-bottom: $padding-lg;
        }
      }
      &_payments {
        margin: 0 -1*$padding-sm;
        display: flex;
        flex-wrap: wrap;
        li {
          padding: $padding-sm;
        }
        a {

          display: flex;
          background-color: $bg-gray;
          align-items: center;
          justify-content: center;
          @include mq($from: desktop_xl) {
            width: 80px;
            height: 80px;
          }
          @include mq($until: desktop_xl) {
            width: 60px;
            height: 60px;
          }
          @include hover(){
            background: none;
          }
        }
        img {
          max-width: 90%;
          max-height: 90%;
        }
      }
      &_links {
        font-size: $font;
        color: $tag;
        li {
          margin-bottom: 12px;
        }
        a {
          color: inherit;
        }
        &.columns {

          @include mq(mobile, tablet) {
            width: 200%;
          }
          @include mq($from: mobile) {
            columns: 2 auto;
          }
          li {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
          }
        }
      }
    }
  }
  &-bottom {
    padding: $padding-md 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    &--web {
      a {
        color: inherit;
      }
    }
  }
}