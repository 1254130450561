.custom_modal {
  display: flex;
  @include mq($from: mobile) {
    max-height: 95vh;
    padding: 40px 56px 32px 48px;
  }
  @include mq($until: mobile) {
    height: 100vh;
    overflow: hidden;
    padding: 95px $padding-xxl $padding-xxl;
  }
  &-main {
    width: 100%;
    overflow: auto;

  }
  &-title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &-text {
    margin-bottom: 40px;
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    .btn-red {
      & + .btn-red {
        @include mq($from: 420px) {
          margin-left: 16px;
        }
        @include mq($until: 420px) {
          margin-top: 16px;
        }
      }
    }
    @include mq($until: 420px) {
      flex-direction: column;
      .btn {
        width: 100%;
      }
    }
  }
  .icon-close {
    width: 28px;
    height: 28px;
  }
  .modal-close {
    @include mq($from: mobile) {
      right: 75px;
      top: 32px;
    }
    @include mq($until: mobile) {
      right: 40px;
      top: 16px;
    }
  }
}