.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include mq($from: mobile_xl) {
    margin-bottom: $padding-xl;
  }
  @include mq($until: mobile_xl) {
    margin-bottom: $padding-lg;
  }
  &-link {

    position: relative;
    color: $tag;
    @include mq($from: tablet) {
      margin-right: 36px;
    }
    @include mq($until: tablet) {
      margin-right: $padding-lg;
    }
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23B0BEC5%22%3E%3Cpath%20d%3D%22m6.49%2020.13l1.77%201.77%209.9-9.9-9.9-9.9-1.77%201.77%208.13%208.13-8.13%208.13%22/%3E%3C/svg%3E');
      background-position: center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      position: absolute;
      left: 100%;
      top: 50%;
      pointer-events: none;
      @include mq($from: tablet) {
        transform: translate(15px, -50%);
      }
      @include mq($until: tablet) {
        transform: translate(9px, -50%);
      }
    }
  }
  &-current {
    color: $tag;
    opacity: 0.7;
    cursor: default;
  }
}