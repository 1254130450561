@import "input-text";
@import "select";
@import "checkbox";
@import "checkbox-color";
@import "spinner";

.form-field {
  width: 100%;
  padding: 7px 0;
  &--md {
    margin-bottom: $padding-md - 15px;
  }
  &--xl {
    margin-bottom: $padding-lg - 15px;
  }
  &--lg {
    margin-bottom: 40px - 15px;
    //margin-bottom: $padding-xl;
  }
}
.form-buttons {
  * {
    margin-right: $padding-xl;
  }
}
.form-text {
  font-size: $font;
  color: $tag;
  line-height: 24px;
}
.form-socials {
  ul {
    margin-top: 28px;
    display: flex;
    align-items: center;
  }
  li {

    @include mq($from: desktop_md) {
      margin-right: $padding-xl;
    }
    @include mq(mobile, desktop_md) {
      margin-right: $padding-lg;
    }
    @include mq($until: mobile) {
      margin-right: $padding-md;
    }
  }
  a {
    display: flex;

    border-radius: 50%;
    background: $icon;
    align-items: center;
    justify-content: center;
    @include mq($from: mobile) {
      height: 56px;
      width: 56px;
    }
    @include mq($until: mobile) {
      height: 40px;
      width: 40px;
    }
    @include hover(){
      background: none;
    }
  }
  img {
    max-width: 75%;
    max-height: 75%;
  }
}