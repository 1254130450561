.sidebar-sticky {
  will-change: transform;
  position: relative;
  z-index: 2;
  @include mq($from: desktop) {
    padding-bottom: $padding;
  }
  @include mq($until: desktop) {
    .sticky-mobile-disable & {
      position: static;
      width: auto !important;
      transform: none !important;
    }
  }
  &.sticky {
    position: fixed;

  }
  &.temp-sticky {
    position: relative;
  }
}

.sticky-mobile-disable {
  @include mq($until: desktop) {
    height: auto !important;
  }
}