.catalog_item {

  &-main {
    display: flex;
    margin: 0 -1*$padding-sm $padding;
    @include mq($from: desktop) {

    }
    @include mq($until: desktop) {
      flex-wrap: wrap;
    }
    @include mq($until: tablet) {
      .catalog_item-data--about_wrap {
        display: none;
      }
    }
  }
  &-data {
    padding: 0 $padding-sm;
    @include mq($from: desktop) {
      width: 75%;
    }
    @include mq($until: desktop) {
      width: 100%;
      margin-bottom: $padding;
    }
    &--general {
      margin: 0 -1*$padding-sm;
      @include mq($from: tablet) {
        display: flex;
      }
    }
    &--about {
      height: 100%;
      background: $white;

      @include mq($from: desktop_xl) {
        padding: $padding-xl $padding-lg $padding-xl $padding-xxl;
      }
      @include mq(mobile, desktop_xl) {
        padding: $padding-xl;
      }
      @include mq($until: mobile) {
        padding: $padding-md;
      }
      &_wrap {


        @include mq($from: tablet) {
          width: calc(4*100%/9);
          padding: 0 $padding-sm;
        }
        @include mq($until: tablet) {
          margin-bottom: 16px;
          margin-top: 16px;
        }
      }
      dl {
        font-size: $font;
        color: $tag;
        display: flex;
        align-items: center;
        margin-bottom: $padding;
        dt {
          margin-right: $padding-sm;
        }
      }
      .adds {
        display: flex;
        align-items: center;
        margin-bottom: $padding-md;
        & > * {
          margin-right: $padding-md;
        }
        &-title {
          margin-top: $padding-lg;
          margin-bottom: $padding-md;
        }
      }
    }
  }
  &-heading {
    background: $white;
    margin-bottom: $padding;
    display: flex;
    align-items: center;

    padding: $padding $padding-md;
    @include mq($from: tablet) {
      min-height: 96px;
    }
    @include mq($until: tablet) {
      min-height: 60px;
    }
    &--like {

      @include mq($from: tablet) {
        width: 54px;
        height: 50px;
        margin-right: $padding-lg;
      }
      @include mq($until: tablet) {
        width: 30px;
        height: 28px;
        margin-right: $padding-md;
      }
    }
  }
  &-gallery {
    background: $white;
    height: 100%;
    &_wrap {
      padding: 0 $padding-sm;

      @include mq($from: tablet) {
        width: calc(5*100%/9);
      }
      @include mq($until: tablet) {
        margin-bottom: $padding;
      }
    }
  }
  &-sidebar {
    background: #FCEBDD;
    height: 100%;

    @include mq($from: desktop_xl) {
      padding: 64px $padding-xl;
    }
    @include mq(desktop, desktop_xl) {
      padding: $padding-xxl $padding-lg;
    }
    @include mq(mobile, desktop) {
      padding: $padding-lg;
    }
    @include mq($until: mobile) {
      padding: $padding-md;
    }
    &--wrap {
      padding: 0 $padding-sm;

      @include mq($from: desktop) {
        width: 25%;
      }
      @include mq($until: desktop) {
        width: 100%;
      }
    }

  }
  &-prices {
    font-size: $font;
    color: $tag;
    margin-bottom: $padding-lg;
    white-space: nowrap;
    .price {
      &-old {
        text-decoration: line-through;
      }
      &-actual {
        font-weight: 600;
        color: $text;
        @include mq($from: desktop_md) {
          font-size: 48px;
        }
        @include mq($until: desktop_md) {
          font-size: $font-lg;
        }
      }
    }
  }
  &-more_info {
    background: $white;
    @include mq($from: desktop_md) {
      padding: $padding-xxl;
    }
    @include mq(mobile, desktop_md) {
      padding: $padding-xl;
    }
    @include mq($until: mobile) {
      padding: $padding-md;
    }
    .content {
      @include mq($until: desktop_md) {
        padding: 0;
      }
    }
    .adds-title {
      &:not(:last-child){
        @include mq($from: desktop_md) {
          margin-bottom: $padding-xxl;
        }
        @include mq(mobile, desktop_md) {
          margin-bottom: $padding-xl;
        }
        @include mq($until: mobile) {
          margin-bottom: $padding-md;
        }
      }
    }
  }
  &-spin {
    .text {
      font-size: 20px;
    }
    .form-spinner {
      padding: $padding-lg 0;
    }
  }
}