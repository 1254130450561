.form-spinner {
  display: flex;
  align-items: center;
  &--btn {
    @extend %btn_reset;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $white;
    color: $text;
    font-size: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    box-shadow: 0 1px 4px 0 rgba($text, 0.5);
    background-repeat: no-repeat;
    background-size: 16px auto;
    background-position: center;
    flex-shrink: 0;
    &.plus {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%3E%3Cg%20fill%3D%22%23546e7a%22%3E%3Cpath%20d%3D%22m0%207h16v2h-16z%22/%3E%3Cpath%20d%3D%22m7%200h2v16h-2z%22/%3E%3C/g%3E%3C/svg%3E');
    }
    &.minus {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23546e7a%22%20d%3D%22m0%207h16v2h-16z%22/%3E%3C/svg%3E');
    }
  }
  &--total {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    margin: 0 12px;
    .total {
      display: block;
      opacity: 0;
      pointer-events: none;
      min-width: 30px;
    }
    .total-value {
      background: transparent;
      outline: none;
      border: none;
      appearance: none;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}