.short_listing {
  margin: 0 -20px 0 0;
  overflow: auto;
  max-height: calc(100vh - 275px);
  &-item {
    display: flex;
    align-items: center;
    padding-right: $padding-md;
    margin-bottom: $padding-sm;
    &--link {
      width: 100%;
      display: flex;
      align-items: center;
      color: $text;
      @include hover(){
        color: $sale;
      }
      @include mq($until: mobile) {
        flex-wrap: wrap;
      }
    }
    &--img {
      position: relative;
      width: 120px;
      flex-shrink: 0;
      margin-right: 8px;
    }
    &--img_box {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      display: block;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
    &--data {
      padding: $padding;
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      @include mq($until: mobile) {
        padding: 0;
        margin-top: 16px;
      }
    }
    &--heading {
      width: 65%;
      padding-right: $padding;
      .custom_modal & {
        width: 100%;
      }
    }
    &--title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: $padding-sm;
    }
    &--category {
      font-size: $font;
    }
    &--price {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
    }
    &--icons {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }
    &--like {
      display: inline-flex;
      width: $font-md;
      height: $font-md;
    }
    &--info {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid currentColor;
      color: $icon;
      background: none;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      transition: all 0.2s;
      @include hover(){
        color: $sale;
      }
    }
  }
}