.auth_menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  pointer-events: none;
  transition: transform 0.4s;
  @include mq($from: tablet) {
    transform: translateX(100%);
  }
  @include mq($until: tablet) {
    transform: translateX(110%);
  }
  &.opened {
    transform: translateX(0);
    pointer-events: auto;
  }
  .content {
    height: 100%;
  }
  &-blur {
    position: absolute;
    right: 0;
    width: 200%;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba($black,0.3);
    transition: opacity 0.35s;
    pointer-events: none;
    .opened & {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &-wrap {
    position: relative;
    margin-left: auto;
    height: 100%;
    @include mq($from: desktop) {
      width: 50%;
    }
    @include mq(tablet, desktop) {
      width: 80vw;
      max-width: 640px;
    }
  }
  &-bg {
    position: absolute;

    top: 0;
    bottom: 0;
    background: $white;

    @include mq($from: 1680px){
      right: calc(800px - 50vw);
      left: 0;
    }
    @include mq(tablet, 1680px){
      right: -1*$padding-xl;
      left: 0;
    }
    @include mq($until: tablet) {
      left: -1*$padding-xl;
      right: -1*$padding-xl;
    }
    @include mq($from: mobile_xl) {
      box-shadow: 0 0 30px 0 rgba($black,0.5);
    }
  }
  &-close {
    position: absolute;
    z-index: 1;
    @extend %btn_reset;
    color: $text;
    @include mq($from: mobile_xl) {
      top: 12px;
      right: $padding;
    }
    @include mq($until: mobile_xl) {
      top: $padding;
      right: 0;
    }
    .icon {
      color: inherit;
    }
  }
  &-main {
    position: relative;
    height: 100vh;

    @include mq($from: desktop_md) {
      padding: $padding-xxl 8% $padding-xxl 16.7%;
    }
    @include mq(mobile_xl, desktop_md) {
      padding: $padding-xxl;
    }
    @include mq(mobile, mobile_xl) {
      padding: $padding-xxl $padding-xl $padding-xl;
    }
    @include mq($until: mobile) {
      padding: $padding-xxl $padding $padding-xl;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mobile &,
    .tablet & {
      overflow-y: auto;
      display: block;
    }
  }
  &-radio_button {
    @extend %hidden_input;
  }
  &-selects {
    display: flex;
    margin-bottom: $padding-xl;
    flex-shrink: 0;

    @include mq($from: mobile_xl) {
      padding-right: $padding-lg;
    }
    &--option {
      height: 64px;
      display: inline-flex;
      align-items: center;
      padding: 0 $padding-lg;
      font-weight: 500;
      text-transform: uppercase;
      color: $tag;
      cursor: pointer;
      background: $white;
      transition: all 0.25s;
      position: relative;
      user-select: none;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background: $grad-begin;
      }
      &:first-child {
        flex-shrink: 0;
      }
      &:not(:first-child) {
        width: 100%;
      }
    }
  }
  &-forms {
    overflow: hidden;
    display: flex;
    width: 100%;
    [data-auth] {
      width: 100%;
      min-width: 100%;
      .mobile &,
      .tablet & {
        max-height: 50vh;
      }
    }
    &--section {
      width: 100%;
      @include mq($from: mobile_xl) {
        padding-right: $padding-lg;
      }
      .mobile &,
      .tablet & {
        margin-bottom: 60px;
      }
    }
  }
  input[data-auth="signin"]:checked ~ .auth_menu-forms [data-auth="signin"],
  input[data-auth="signup"]:checked ~ .auth_menu-forms [data-auth="signup"] {
    order: -1;
    .mobile &,
    .tablet & {
      max-height: none;
    }
  }
  input[data-auth="signin"]:checked ~ .auth_menu-selects [data-auth="signin"],
  input[data-auth="signup"]:checked ~ .auth_menu-selects [data-auth="signup"] {
    &:after {
      height: 4px;
    }
  }
}