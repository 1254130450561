.sidebar {
  @include mq($until: desktop) {
    display: flex;
    flex-direction: column;
    &.sticky {
      box-sizing: content-box;

      box-shadow: 0 8px 8px 0 rgba($text,0.2);
    }
  }
  @include mq(tablet, desktop) {
    &.sticky {
      margin: 0 -1*$padding-lg;
      padding: 0 $padding-lg;
    }
  }
  @include mq($until: tablet) {
    &.sticky {
      margin: 0 -1*$padding-md;
      padding: 0 $padding-md;
    }
  }
  &-section {
    background-color: $white;
    @include mq($from: desktop_xl) {
      padding: $padding-lg;
      margin-bottom: $padding;
      width: 100%;
      &.sidebar-section--categories {
        padding-bottom: 4px;
      }
    }
    @include mq(desktop, desktop_xl) {
      padding: $padding-md;
      margin-bottom: $padding;
      width: 100%;
      &.sidebar-section--categories {
        padding-bottom: 4px;
      }
    }

    &--categories {
      @include mq($until: desktop) {

      }
    }
    &--filters {
      position: relative;
      @include mq($from: desktop) {
        max-height: calc(100vh - 16px);
        overflow: auto;
      }
      .selected-filters {
        .list {
          [data-filter-item] {
            cursor: pointer;
            padding-right: 30px;
            position: relative;
            display: inline-block;
            white-space: nowrap;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            @include hover(){
              color: $sale;
            }
            &:after {
              content: "×";
              position: absolute;
              font-size: 1.5em;
              font-weight: 600;
              bottom: 0;
              height: 20px;
              right: 10px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .open_filters {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
        z-index: -2;
        pointer-events: none;
        @include mq($until: desktop) {
          &:checked ~ .form {
            display: block;
            .no-drops & {
              display: none;
            }
          }
        }
        &-btn {
          color: $tag;
          background-color: $bg-green;
          font-size: $font;
          line-height: $font-lg;
          display: block;
          transition: none;
          .sticky & {
            @include mq(tablet, desktop) {
              margin: 0 -1*$padding-lg;
              padding: 0 $padding-lg;
            }
            @include mq($until: tablet) {
              margin: 0 -1*$padding-md;
              padding: 0 $padding-md;
            }
          }
          @include mq($from: desktop) {
            display: none;
          }
        }
      }
      @include mq($from: desktop) {

      }
      @include mq($until: desktop) {
        .form {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          box-shadow: 0 8px 8px 0 rgba($text,0.5);
          background: $white;
          max-height: 50vh;
          overflow: auto;
          padding: $padding 0 $padding-md;
          .sticky & {
            left: -1*$padding-md;
            right: -1*$padding-md;
          }
        }
      }

    }
  }
  &-filters {
    @include mq($until: desktop) {
      display: flex;
      flex-wrap: wrap;
    }
    &--fieldset {
      @include mq(mobile, desktop) {
        width: 100%;
        max-width: 600px;
        padding: 0 $padding-md;
        .sticky & {
          padding: 0 $padding-lg;
        }
      }
      @include mq($until: mobile) {
        padding: 0 $padding-md;
        .sticky & {
          padding: 0 $padding-lg;
        }
      }
      &:not(:last-child){
        margin-bottom: $padding-lg;
      }
      &:last-child{
        @include mq($from: desktop) {
          margin-top: $padding-xxl;
        }
        @include mq($until: desktop) {
          margin-top: $padding-lg;
        }
      }
      .form-checkbox {
        &:not(:last-child){
          margin-bottom: $padding;
        }
      }
      .colors {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1*$padding;
      }
      .checkbox_color {
        margin-right: $padding;
        margin-bottom: $padding;
      }
    }
    &--checkbox_set {
      @include mq($until: desktop) {
        display: flex;
        flex-wrap: wrap;
        .form-checkbox {
          min-width: 50%;
          padding-right: $padding-md;
        }
      }
    }
    &--btnset {
      @include mq($until: desktop) {
        width: 100%;
      }
    }
    &--title {
      margin-bottom: 10px;
      &.fn-expand-btn {
        position: relative;
        padding-right: 24px;
        cursor: pointer;
        &:before {
          content: "";
          position: absolute;
          right: 0;
          margin: 0 3px 0 0;
          width: 9px;
          height: 9px;
          border-style: solid solid none none;
          border-width: 2px;
          border-color: #b0bec5;
          transform: rotate(135deg) translate(-2px,2px);
          transition: transform .25s,-webkit-transform .25s;
          top: 8px;
          .expanded & {
            transform: rotate(-45deg) translate(-2px,2px);
          }
        }
      }
    }
    &--reset {
      @extend %btn_reset;
      color: $tag;
      text-transform: uppercase;
      font-size: $font;
      font-weight: 600;
      letter-spacing: 2px;
      @include mq(desktop, desktop_xl) {
        letter-spacing: 0;
      }
    }
  }
}

.sidebar_categories {
  color: $tag;
  &-item {
    padding-left: $padding-lg;
    &.fn-expand-box {
      & > .fn-expand-btn {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          right: 100%;

          margin: 0 12px 0 0;
          width: 9px;
          height: 9px;
          border-style: solid solid none none;
          border-width: 2px;
          border-color: $icon;
          transform: rotate(45deg) translate(-2px, 2px);
          transition: transform 0.25s;
          top: 8px;
        }
      }
      &.expanded {
        & > .fn-expand-btn {
          &:before {
            transform: rotate(135deg) translate(-2px, 2px);
          }
        }

      }
    }

  }
  &-sublist {
    display: none;
  }
  &-subitem {
    @include mq($from: desktop) {
      margin-top: $padding-md;
    }
  }
  &-title {
    //display: inline-block;
    cursor: pointer;
    //@include mq($from: desktop) {
    //  margin-bottom: 20px;
    //}
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 20px;
    }
  }
  a {
    color: inherit;
    background: linear-gradient(to bottom, $sale 0%, $sale 100%);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    padding: 2px 0;
    transition: background-size .3s;
    position: relative;
    @include hover(){
      background-size: 98% 2px;
    }
    &.active {
      background-size: 98% 2px;
    }
  }
}