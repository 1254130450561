.listing {
  &-columns {
    display: flex;
    margin: 0 -1*$padding-sm;
    @include mq($until: desktop) {
      flex-wrap: wrap;
    }
  }
  &-sidebar {
    padding: 0 $padding-sm;

    width: 100%;
    flex-shrink: 0;
    @include mq($from: desktop_md) {
      max-width: calc( 100%/6 );
    }
    @include mq(desktop, desktop_md) {
      max-width: calc( 100%/5 );
    }
    @include mq($until: desktop) {
      margin-bottom: $padding;
    }
  }
  &-main {
    padding: 0 $padding-sm;
    width: 100%;
  }
  &-filters {
    background-color: $white;
    margin-bottom: $padding;
    white-space: nowrap;
    display: flex;
    align-items: center;

    @include mq($from: tablet) {
      padding: 0 $padding-lg;
      min-height: 56px;
    }
    @include mq($until: tablet) {
      padding: 0 $padding-md;
      min-height: 40px;
    }
    @include mq($until: mobile_xl) {
      display: none;
    }
    a {
      text-transform: lowercase;
      color: $tag;
      margin-right: 36px;
    }
  }
  &-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1*$padding-sm;
  }
  &-item {
    padding: 0 $padding-sm;
    margin-bottom: $padding;
    &.col-1-6 {
      width: 100%;
      @include mq($from: desktop_md) {
        max-width: calc( 100%/6 );
      }
      @include mq(desktop, desktop_md) {
        max-width: calc( 100%/5 );
      }
      @include mq(tablet, desktop) {
        max-width: calc( 100%/4 );
      }
      @include mq(mobile_xl, tablet) {
        max-width: calc( 100%/3 );
      }
      @include mq(mobile, mobile_xl) {
        max-width: calc( 100%/2 );
      }
    }
    &.col-1-5 {
      width: 100%;

      @include mq($from: desktop_md) {
        max-width: calc( 100%/5 );
      }
      @include mq(tablet, desktop_md) {
        max-width: calc( 100%/4 );
      }
      @include mq(mobile_xl, tablet) {
        max-width: calc( 100%/3 );
      }
      @include mq(mobile, mobile_xl) {
        max-width: calc( 100%/2 );
      }
    }
    @include mq($until: mobile) {
      margin-bottom: 16px;
    }
    &--section {
      background-color: $white;
      height: 100%;
      display: flex;
      flex-direction: column;
      transition: all 0.25s;
      @include hover(){
        box-shadow: 0 20px 20px 0 rgba($black, 0.16);
        .listing-carousel & {
          box-shadow: 0 4px 4px 0 rgba($black, 0.16);
        }
        .listing-item--prices,
        .listing-item--title {
          color: $sale;
        }
      }
    }
    &--img {
      @include mq($from: desktop_xl) {
        padding: $padding-xl;
      }
      @include mq($until: desktop_xl) {
        padding: $padding-lg;
      }
      position: relative;
    }
    &--img_box {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      display: block;
      picture,
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
    &--sale {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      background: $sale;
      color: $white;
      font-size: $font-md;
      font-weight: 500;
      .listing-item & {
        position: absolute;

        @include mq($from: desktop_xl) {
          left: $padding-md;
          top: $padding-md;
        }
        @include mq($until: desktop_xl) {
          left: $padding;
          top: $padding;
        }
      }
    }
    &--marks {
      position: absolute;
      left: $padding-md;

      display: flex;
      align-items: center;
      @include mq($from: desktop_xl) {
        bottom: 0;
      }
      @include mq($until: desktop_xl) {
        bottom: -1*$padding;
      }
      .mark {
        margin-right: $padding;
      }
    }
    &--mark {
      text-transform: uppercase;
      font-size: $font;
      font-weight: 600;
      line-height: $font-md;
      padding: 0 6px;
      display: inline-flex;
      &.mark {
        &-new {
          background: $tag;
          color: $white;
        }
        &-top {
          background: $btn-red;
          color: $white;
        }
      }
    }
    &--data {

      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mq($from: desktop_xl) {
        padding: 20px $padding-lg;
      }
      @include mq($until: desktop_xl) {
        padding: $padding-md;
      }
    }
    &--title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: $padding-md;
      color: $text;
      a {
        color: inherit;
      }
    }
    &--descr {
      margin-top: auto;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
    }
    &--prices {
      color: $text;
      display: flex;
      flex-direction: column;
      .old-price {
        font-size: $font;
        text-decoration: line-through;
      }
      .actual-price {
        font-size: 20px;
        font-weight: 600;
      }
    }
    &--icons {
      display: flex;
      align-items: center;
      margin-bottom: $padding;
      & > * {
        margin-left: $padding-lg;
      }
    }
    &--like {
      display: inline-flex;
      width: $font-md;
      height: $font-md;
    }
    &--info {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid currentColor;
      color: $icon;
      background: none;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      transition: all 0.2s;
      outline: none;
      @include hover(){
        color: $sale;
      }
    }
  }
  &-cta {
    text-align: center;
    @include mq($from: desktop) {
      margin-top: 64px;
    }
    @include mq(mobile_xl, desktop) {
      margin-top: $padding-xxl;
    }
    @include mq($until: mobile_xl) {
      margin-top: $padding-lg;
    }
    .btn {
      width: 100%;
      max-width: 500px;
    }
  }
}