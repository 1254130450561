@import "modal/modal-catalog-item";
@import "modal/custom-modal";
@import "modal/alerts";
@import "modal/notification";

.modal {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  &.opened {
    z-index: 100;
    opacity: 1;
    pointer-events: auto;
  }
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black,0.3);
  }
  &-container {
    position: absolute;
    width: 100vw;
    @include mq($from: desktop) {
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
    @include mq($until: desktop) {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include mq($until: mobile) {
      padding: 0;
    }
  }
  &-wrap {
    background: $white;
    box-shadow: 0 20px 20px 0 rgba($text,0.3);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include mq($until: desktop) {
      position: relative;
      align-self: center;
      width: 100%;
    }
    @include mq($from: mobile) {
      max-height: 95vh;
      [data-modal="popup"] & {
        border-radius: 40px 0 40px 40px;
      }
    }
    @include mq($until: mobile) {
      max-height: 100vh;
    }
  }
  &-close {
    position: absolute;
    top: 4px;
    z-index: 1;

    @extend %btn_reset;
    color: $text;
    @include mq($from: mobile) {
      right: $padding-lg;
    }
    @include mq($until: mobile) {
      right: 4px;
    }
    .icon {
      color: inherit;
    }
  }
  &-section {
    &--box {
      &:not(.active){
        display: none;
      }
    }
  }
}