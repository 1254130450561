$ph-color: inherit;
$ph-opacity: 0.8;
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $ph-color;
  opacity: $ph-opacity;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $ph-color;
  opacity: $ph-opacity;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $ph-color;
  opacity: $ph-opacity;
}
:-moz-placeholder { /* Firefox 18- */
  color: $ph-color;
  opacity: $ph-opacity;
}