.listing_slider {

  @include mq($from: desktop) {
    margin-top: 95px;
  }
  @include mq($until: desktop) {
    margin-top: 60px;
  }
  &-title {

    @include mq($from: desktop) {
      margin-bottom: $padding-xxl;
    }
    @include mq($until: desktop) {
      margin-bottom: $padding-lg;
    }
  }

}