.cart {
  &-columns {
    display: flex;
    margin: 0 -1*$padding-sm;
    &:not(:last-child){
      @include mq($from: desktop_xl) {
        margin-bottom: 90px;
      }
      @include mq(desktop, desktop_xl) {
        margin-bottom: 80px;
      }
      @include mq($until: desktop) {
        margin-bottom: 40px;
      }
    }
    @include mq($from: desktop) {

    }
    @include mq($until: desktop) {
      flex-direction: column;
    }
  }
  &-sidebar {
    padding: 0 $padding-sm;

    width: 100%;
    flex-shrink: 0;
    @include mq($from: desktop) {
      max-width: calc( 100%/3 );
    }
    @include mq($until: desktop) {
      margin-top: $padding-lg;
    }
    &--box {
      background: $white;

      @include mq($from: desktop_md) {
        padding: $padding-xxl $padding-xl 1px;
      }
      @include mq(mobile, desktop_md) {
        padding: $padding-xl $padding-lg 1px;
      }
      @include mq($until: mobile) {
        padding: $padding-md $padding-md 1px;
      }
    }
    &--total {
      display: flex;
      align-items: flex-end;
      margin-bottom: $padding-lg;
      dt, dd {
        width: 100%;
        max-width: 50%;
        padding-right: $padding;
      }
      dt {
        line-height: 24px;
      }
      dd {
        font-size: 20px;
        color: $text;
        font-weight: 600;
        &.summary {

          line-height: 1;
          @include mq($from: desktop_md) {
            font-size: 48px;
          }
          @include mq(desktop, desktop_md) {
            font-size: $font-lg;
          }
          @include mq(mobile, desktop) {
            font-size: 48px;
          }
          @include mq($until: mobile) {
            font-size: $font-lg;
          }
        }
      }
    }
    &--promocode {
      display: flex;
      width: 100%;
      @include mq(desktop, desktop_md) {
        flex-wrap: wrap;
      }
      @include mq($until: mobile) {
        flex-wrap: wrap;
      }
      &_btn {
        flex-shrink: 0;
        @include mq(desktop, desktop_md) {
          width: 100%;
        }
        @include mq($until: mobile) {
          width: 100%;
        }
      }
      .form-input-simple {
        width: 100%;
      }
    }
  }
  &-main {
    padding: 0 $padding-sm;
    width: 100%;
  }
  &-section {
    &:not(:last-child){

      @include mq($from: desktop_xl) {
        margin-bottom: 90px;
      }
      @include mq(desktop, desktop_xl) {
        margin-bottom: 80px;
      }
      @include mq($until: desktop) {
        margin-bottom: 40px;
      }
    }
    &--title {
      margin-bottom: $padding-md;
    }
    &--box {
      background: $white;

      @include mq($from: desktop_xl) {
        padding: $padding-xxl;
      }
      @include mq(tablet, desktop_xl) {
        padding: $padding-xl;
      }
      @include mq(mobile, tablet) {
        padding: $padding-lg;
      }
      @include mq($until: mobile) {
        padding: $padding-md;
      }
    }
    &--columns {

      @include mq($from: tablet) {
        display: flex;
      }
    }
    &--col {

      @include mq($from: tablet) {
        width: 50%;
      }
      &.has-padding {

        @include mq($from: desktop_xl) {
          padding-right: 10%;
        }
        @include mq(tablet, desktop_xl) {
          padding-right: 8%;
        }
      }
    }
  }
  &-submit {
    margin-top: $padding-xl;
    .btn {

      align-items: center;
      @include mq($from: tablet) {
        line-height: 88px;
      }
      @include mq($until: tablet) {
        line-height: 60px;
      }
      i {
        position: relative;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        &:after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
          border-style: solid solid none none;
          border-color: currentColor;
          border-width: 3px;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;
          transform: rotate(45deg) translate(-2px, 2px);
        }
      }
    }
  }
}

.cart_table {
  &-item {
    display: flex;
    align-items: center;

    @include mq($from: desktop_md) {
      margin-left: -1*$padding-lg;
    }
    @include mq(tablet, desktop_md) {
      margin-left: -12px;
    }
    @include mq(mobile_xl, tablet) {
      margin-top: -1*$padding-md;
      margin-bottom: -1*$padding-md;
      margin-left: -28px;
    }
    @include mq($until: mobile_xl) {
      margin-top: 12px;
      margin-left: 0;
      margin-bottom: 24px;
    }
    @include mq($until: mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
    &--img {
      position: relative;
      flex-shrink: 0;
      @include mq($from: tablet) {
        height: 96px;
        width: 96px;
      }
      @include mq(mobile_xl, tablet) {
        height: 128px;
        width: 128px;
      }
      @include mq($from: mobile_xl) {
        margin: 16px;
      }
      @include mq(mobile, mobile_xl) {
        margin-right: 24px;
      }
      @include mq($until: mobile_xl) {
        height: 128px;
        width: 128px;
        margin-bottom: 12px;

      }
      @include mq($until: mobile) {

      }
    }
    &--img_box {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      display: block;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
    &--title {
      font-size: $font;
      font-weight: 500;
      margin-bottom: $padding-sm;
      a {
        color: $text;
        background: linear-gradient(to bottom, $btn-red 0%, $btn-red 100%);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 0 2px;
        padding: 2px 0;
        transition: background-size .3s;
        position: relative;
        @include hover(){
          background-size: 98% 2px;
        }
      }
    }
    &--category {
      font-size: $font;
      color: $tag;
    }
    &--count {
      display: flex;
      align-items: center;
      &_btn {
        @extend %btn_reset;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $bg-gray;
        color: $text;
        font-size: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        &.minus {
          padding-bottom: 4px;
        }
      }
      &_total {
        font-size: 20px;
        font-weight: 600;
        margin: 0 12px;
        &:after {
          content: attr(data-total);
          display: inline-flex;
          width: 24px;
          justify-content: center;
        }
      }
    }
    &--old_price {
      font-size: $font;
      text-decoration: line-through;
      white-space: nowrap;
    }
    &--actual_price {
      font-size: 20px;
      font-weight: 600;
      white-space: nowrap;
    }
    &--remove {
      width: $padding-lg;
    }
  }
  &-cell {
    &--part {
      @include mq(mobile_xl, tablet) {
        float: left;
        width: 50%;
        display: block;
      }
      @include mq($until: mobile_xl) {
        display: block;
      }
    }
    &--count {
      @include mq(mobile_xl, tablet) {
        margin-left: 50%;
        display: block;
      }
      @include mq($until: mobile_xl) {
        display: block;
      }
    }
    &--price {
      @include mq(mobile_xl, tablet) {
        margin-left: 50%;
        margin-top: $padding;
      }
      @include mq($until: mobile_xl) {
        display: block;
        margin-top: $padding;
      }
    }
    &--term {
      @include mq(mobile_xl, tablet) {
        display: flex;
        align-items: center;
      }
      @include mq($until: mobile_xl) {
        display: flex;
        align-items: center;
      }
      &_title {
        margin-right: $padding;
        font-weight: 300;
      }
    }
    &--action {
      @include mq($until: tablet) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    @include mq($until: tablet) {
      display: block;
    }
  }
  thead {
    color: $white;
    background: $tag;
    text-align: left;
    font-size: $font;
    @include mq($until: tablet) {
      display: none;
    }
    th {
      height: 40px;
      @include mq($from: desktop_md) {
        padding: 0 $padding-lg;
      }
      @include mq($until: desktop_md) {
        padding: 0 12px;
      }
    }
  }
  tbody {
    @include mq($until: tablet) {
      display: block;
    }
    tr {
      border-top: 6px solid $bg-gray;
      background: $white;
      @include mq($until: tablet) {
        overflow: hidden;
        display: block;
        position: relative;

      }
      @include mq(mobile, tablet) {
        padding: $padding-md $padding-xl $padding-md $padding-md;
      }
      @include mq($until: mobile) {
        padding: $padding $padding-xl $padding-md $padding;
      }
    }
  }
  td {

    @include mq($from: desktop_md) {
      padding: 0 $padding-lg;
    }
    @include mq($until: desktop_md) {
      padding: 0 12px;
    }
  }
}