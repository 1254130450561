.logo {
  display: inline-flex;
  align-items: center;
  color: $text-dark;
  font-weight: 600;
  @include mq($from: mobile) {
    font-size: $font-md;
  }
  @include mq($until: mobile) {
    font-size: 12px;
  }
  &-img {

    @include mq($from: desktop) {
      width: 156px;
      height: 40px;
    }
    @include mq(mobile, desktop) {
      width: 120px;
      height: 32px;
    }
    @include mq($until: mobile) {
      width: 80px;
      height: 22px;
    }
  }
}