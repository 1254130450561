.burger_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  transition: transform 0.4s;
  will-change: transform;
  @include mq($from: tablet) {
    transform: translateX(-100%);
  }
  @include mq($until: tablet) {
    transform: translateX(-110%);
  }
  &.opened {
    transform: translateX(0);
    pointer-events: auto;
  }
  .content {
    height: 100%;
  }
  &-blur {
    position: absolute;
    left: 0;
    width: 220%;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba($black,0.1);
    transition: opacity 1s;
    pointer-events: none;
    .opened & {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &-wrap {
    height: 100%;
    position: relative;
    @include mq($from: desktop) {
      width: 66.6667%;
    }
    @include mq(tablet, desktop) {
      width: 80vw;
      max-width: 640px;
    }
  }
  &-bg {
    position: absolute;

    top: 0;
    bottom: 0;
    background: linear-gradient(45deg, $grad-end 0%,$grad-begin 100%);

    @include mq($from: 1680px){
      left: calc(800px - 50vw);
      right: 0;
    }
    @include mq(tablet, 1680px){
      left: -1*$padding-xl;
      right: 0;
    }
    @include mq($until: tablet) {
      left: -1*$padding-xl;
      right: -1*$padding-xl;
    }
    @include mq($from: mobile_xl) {
      box-shadow: 0 0 30px 0 rgba($black,0.5);
    }
  }
  &-main {
    position: relative;
    display: flex;
    flex-direction: column;
    color: $white;
    height: 100%;
    min-height: 100vh;
    padding-bottom: $padding-xxl;
    .mobile &,
    .tablet & {
      padding-bottom: 60px;
    }
  }
  &-close {

    display: flex;
    align-items: center;
    flex-shrink: 0;
    @include mq($from: desktop) {
      height: 88px;
      margin-bottom: 96px;
    }
    @include mq($until: desktop) {
      height: 62px;
      margin-bottom: 24px;
    }
    .burger_close {
      @extend %btn_reset;
    }
  }
  &-invite {

    font-weight: 300;
    @include mq($until: wide) {
      margin-left: $padding-lg;
    }
    @include mq($from: desktop_md) {
      font-size: 3*$font;
    }
    @include mq(mobile, desktop_md) {
      font-size: $font-lg;
    }
    @include mq($from: mobile_xl) {
      padding-bottom: $padding-xxl;
    }
    @include mq($until: mobile_xl) {
      padding-bottom: $padding-xl;
    }
    @include mq($until: mobile) {
      font-size: $font-md;
    }
    &:not(.no-auth) {
      flex-shrink: 0;
      @include mq($from: mobile_xl) {
        min-height: 96px;
      }
    }
    &.no-auth {
      @include mq($from: mobile_xl) {
        height: 96px;
      }
      .mobile &,
      .tablet & {
        padding-bottom: $padding-lg;
        height: 0;
      }
    }
  }
  &-nav {
    font-weight: 500;
    display: flex;
    margin-left: $padding-xxl;
    @include mq($from: mobile_xl) {

    }
    @include mq($until: mobile_xl) {
      flex-direction: column;
    }
    &--wrap {
      flex-grow: 1;
      overflow: hidden;
      @include mq($from: wide) {
        margin-left: -1*$padding-xxl;
      }
      @include mq($until: wide) {
        margin-left: -1*$padding-lg;
      }
      .mobile &,
      .tablet & {
        overflow: auto;
        margin-bottom: 40px;
      }
    }
    a:not(.btn) {
      color: inherit;
    }
  }
  &-site_menu {


    @include mq($from: mobile_xl) {
      width: 62.5%;
      padding-right: $padding-md;
    }
    @include mq($until: mobile_xl) {

    }
    &--item {

      @include mq($from: mobile_xl) {
        margin-bottom: $padding-xxl;
      }
      @include mq($until: mobile_xl) {
        margin-bottom: $padding-lg;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &--link {

      cursor: pointer;
      @include mq($from: desktop_md) {
        font-size: $font-lg;
      }
      @include mq(tablet, desktop_md) {
        font-size: $font-md;
      }
      @include mq($until: tablet) {
        font-size: 20px;
      }
      &.fn-expand-btn {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          right: 100%;
          top: 0;
          bottom: 0;

          width: 12px;
          height: 12px;
          border-style: solid solid none none;
          border-width: 3px;
          border-color: currentColor;
          transform: rotate(45deg) translate(-2px, 2px);
          transition: transform 0.25s;
          @include mq($from: desktop) {
            margin: auto 24px auto 0;
          }
          @include mq($until: desktop) {
            margin: auto 12px auto 0;
          }
          .expanded & {
            transform: rotate(135deg) translate(-2px, 2px);
          }
        }
      }
    }
    &--subitem {

      @include mq($from: mobile_xl) {
        margin-top: $padding-xl;
      }
      @include mq($until: mobile_xl) {
        margin-top: $padding-lg;
      }
    }
    &--sublink {
      font-size: $font;
      text-transform: uppercase;
    }
  }
  &-auth_menu {
    text-transform: uppercase;
    @include mq($from: desktop_md) {
      padding-top: $padding-md;
    }
    @include mq(mobile_xl, desktop_md) {
      padding-top: $padding;
    }
    @include mq($from: mobile_xl) {
      width: 37.5%;
      padding-right: $padding-md;
      font-size: $font;
    }
    @include mq($until: mobile_xl) {
      font-size: 14px;
      margin-top: $padding-xxl;
    }
    &--item {

      @include mq($from: mobile_xl) {
        margin-bottom: $padding-xl;
      }
      @include mq($until: mobile_xl) {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}