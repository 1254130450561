.gallery {
  &-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }
  &-dots {
    width: 72%;
    height: 12%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    overflow: hidden;
    display: flex;
    &--item {
      cursor: pointer;
      min-width: calc(100%/6);
      width: calc(100%/6);
      height: 100%;
      background: $white;
      border: 1px solid $bg-gray;
      position: relative;
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        opacity: 0;
        height: 4px;
        background: $btn-red;
      }
      &.current{
        &:after {
          opacity: 1;
        }
        .gallery-dots--img {
          opacity: 1;
        }
      }
      @include hover(){
        .gallery-dots--img {
          opacity: 1;
        }
      }
    }
    &--img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0.6;
      transition: all 0.18s;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &--wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      @include mq($from: tablet) {
        .catalog_item & {
          cursor: pointer;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $white;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22none%22%20stroke%3D%22%23546E7A%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22/%3E%3Cpath%20d%3D%22m21%2021l-4.35-4.35%22/%3E%3Cpath%20d%3D%22m11%208v6%22/%3E%3Cpath%20d%3D%22m8%2011h6%22/%3E%3C/svg%3E');
            background-repeat: no-repeat;
            background-size: 14% auto;
            background-position: center;
            opacity: 0;
            transition: all 0.15s;
          }
        }
      }
      @include hover(){
        &:after {
          opacity: 0.4;
        }
      }
    }
    &--item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: transform 0.25s;
      &.new {
        opacity: 0;
        &.active {
          z-index: 1;
        }
      }
    }
  }
  &-nav {
    @include mq($until: mobile) {
      display: none;
    }
    &--button {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      &.prev {
        left: 8px;
      }
      &.next {
        right: 8px;
      }
    }
  }
}