.form-input {
  position: relative;
  input {
    display: block;
    width: 100%;
    //height: 40px;
    height: 56px;
    font-size: 20px;
    font-weight: 500;
    background: $white;
    border: none;
    border-radius: 4px 4px 0 0;
    //color: $tag;
    color: $black;
    padding: 0 $padding-md 0;
    outline: none;
    transition: background-color 0.2s, box-shadow 0.2s;
    box-shadow: 0 0 0 1px $icon inset;
    appearance: none;
    .invalid & {
      box-shadow: 0 0 0 2px $btn-red_hover inset !important;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m11%207h2v2h-2m0%202h2v6h-2m1-15a10%2010%200%201%200%2010%2010%2010%2010%200%200%200%20-10%20-10m0%2018a8%208%200%201%201%208%20-8%208.011%208.011%200%200%201%20-8%208%22%20fill%3D%22%23e94040%22/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-size: 24px auto;
      background-position: calc(100% - 16px) center;
      padding-right: 44px;
    }
    &:focus {
      //background: darken($bg-gray,5);
      box-shadow: 0 0 0 2px $grad-begin inset;
      & ~ .form-input--label {
        color: $grad-begin;
        top: 0;
        transform: translateY(-50%) scale(.75);
      }
    }
    &[readonly]{
      pointer-events: none;
      background-color: #f3f3f3;
      color: rgba($tag, 0.8);
    }
    &:-internal-autofill-selected {
      color: $tag !important;
    }
  }
  &--label {
    position: absolute;
    color: $tag;
    left: 10px;
    right: initial;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transform-origin: left center;
    background-color: $white;
    padding: 0 6px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &--message {
    font-size: 12px;
    position: absolute;
    top: 60px;

    @include mq($from: tablet) {
      left: $padding-md;
    }
    @include mq($until: tablet) {
      left: 0;
    }
  }
  label.invalid {
    font-size: 12px;
    position: absolute;
    top: 60px;
    color: $btn-red_hover;
    @include mq($from: tablet) {
      left: $padding-md;
    }
    @include mq($until: tablet) {
      left: 0;
    }
    &:not(.valid):not([style*='display: none']){
      & ~ .form-input--message {
        display: none;
      }
    }

  }
  &.filled {
    input {
      .valid & {
        box-shadow: 0 0 0 2px $sale inset;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22m11%207h2v2h-2m0%202h2v6h-2m1-15a10%2010%200%201%200%2010%2010%2010%2010%200%200%200%20-10%20-10m0%2018a8%208%200%201%201%208%20-8%208.011%208.011%200%200%201%20-8%208%22%20fill%3D%22%231cb47a%22/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-size: 24px auto;
        background-position: calc(100% - 16px) center;
        padding-right: 44px;
      }
    }
    .form-input--label {
      top: 0;
      transform: translateY(-50%) scale(.75);
    }
  }
  &-simple {
    input {
      display: block;
      width: 100%;
      height: 40px;
      background: $bg-gray;
      border: none;
      border-radius: 0;
      color: $tag;
      padding: 0 $padding-md;
      outline: none;
    }
  }
}