.btn {
  border: none;
  border-radius: 0;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  transition: all 0.18s;
  outline: none;
  letter-spacing: 2px;
  white-space: nowrap;
  &-link {
    color: $tag;
    @extend %btn_reset;
    text-transform: uppercase;
    font-size: $font;
    font-weight: 600;
    letter-spacing: 2px;
  }
  &-grey {
    color: $white;
    text-transform: uppercase;
    background-color: $icon;
    font-size: $font;
    font-weight: 600;
    padding: 0 $padding-lg;
  }
  &-red {
    color: $btn-red;
    text-transform: lowercase;
    background-color: transparent;
    font-size: $font;
    font-weight: 600;
    padding: 0 $padding-lg;
    border: 2px solid $btn-red;
    &.large-padding {
      padding: 0 $padding-xl;
    }
    @include hover(){
      color: $white;
      background-color: $btn-red;
    }
  }
  &-white {
    color: $btn-red;
    text-transform: lowercase;
    background-color: transparent;
    font-size: $font;
    font-weight: 600;
    padding: 0 $padding-lg;
    border: 2px solid transparent;
    @include hover(){
      color: darken($btn-red, 10);
    }
  }
  &-green {
    color: $tag;
    background-color: $bg-green;
    font-size: $font;
    padding: 0 $padding-lg;
    @include mq($until: mobile) {
      .modal_catalog-link & {
        height: 56px;
        line-height: 1.2;
        align-items: center;
        font-size: 14px;
      }
    }
  }
  &-acid {
    text-transform: uppercase;
    color: $white;
    background-color: $sale;
    font-size: $font;
    line-height: 40px;
    padding: 0 $padding-lg;
    font-weight: 600;
    @include hover(){
      background-color: darken($sale,5);
    }
  }
  &-red-fill {
    color: $white;
    text-transform: uppercase;
    background-color: $btn-red;
    font-size: $font;
    font-weight: 600;
    padding: 0 $padding-lg;
    border: 2px solid $btn-red;
    @include hover(){
      border-color: $btn-red_hover;
      background-color: $btn-red_hover;
    }
  }
  &-expand {
    @extend %btn_reset;
    background: $tag;
    position: relative;
    display: block;
    color: $white;
    @include mq($from: tablet) {
      width: 56px;
      height: 56px;
    }
    @include mq(mobile, tablet) {
      width: 40px;
      height: 40px;
    }
    @include mq($until: mobile) {
      width: 32px;
      height: 32px;
    }
    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-style: solid solid none none;
      border-color: currentColor;
      border-width: 3px;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      transition: all 0.25s;
      transform: rotate(135deg) translate(-2px, 2px);
      @include mq($until: mobile) {
        width: 12px;
        height: 12px;
      }
    }
    .expanded &,
    .opened & {
      &:after {
        transform: rotate(315deg) translate(-2px, 2px);
      }
    }
  }
  &--sm {
    line-height: 44px;
  }
  &--md {
    line-height: 48px;
  }
  &--lg {
    line-height: 56px;
  }
  &--full {
    width: 100%;
  }


}