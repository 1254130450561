html {
  min-height: 100%;
  font-size: $font;
  &.ovh {
    width: 100%;
    position: fixed;
    overflow-y: scroll;
  }
  //@include media(hover,1201){font-size: 16px;}
  //@include breakpoints(1025,1200){font-size: 16px;}
  //@include breakpoints(768,1024){font-size: 16px;}
  //@include media(screen,767){font-size: 16px;}
}
body {
  line-height: 1.4;
  font-weight: 400;
  color: $text;
  font-family: PTRoot, sans-serif;
}
*, *:after, *:before {
  box-sizing: border-box;
}
a {
  outline: none;
  transition: all 0.3s;
}
svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.main {
  background: $bg-gray;
  position: relative;
  width: 100%;
  flex: 1 0 auto;


  @include mq($from: tablet) {
    transition: opacity 0.5s;
    .blured & {
      opacity: 0.3;
    }
  }

  @include mq($from: mobile_xl) {
    padding: $padding-xl 0 80px;
  }
  @include mq($until: mobile_xl) {
    padding: $padding-md 0 $padding-xxl;
  }
}
.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  @include mq($from: tablet) {
    transition: all 0.3s;
    &.right-origin {
      transform-origin: top right;
    }
    &.left-origin {
      transform-origin: top left;
    }
    &.blured {
      transition: transform 0.4s ease 0.1s;
      transform: scale(0.9);
    }
  }
 }

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  overflow: hidden;
  @include mq(mobile_xl, desktop) {
    padding-top: 100px;
  }
  @include mq($until: mobile_xl) {
    padding-top: 62px;
  }
}