.listing-carousel {
  &.owl-carousel {
    & > .listing-item {
      max-width: calc( 100%/6 );
      float: left;
      width: 100%;
    }
    .owl-nav {
      button {
        &.owl-prev,
        &.owl-next {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background: $white;
        }
        &.owl-prev {
          @include mq($from: desktop) {
            left: -30px;
          }
          @include mq($until: desktop) {
            left: -16px;
          }
        }
        &.owl-next {
          @include mq($from: desktop) {
            right: -30px;
          }
          @include mq($until: desktop) {
            right: -16px;
          }
        }
      }
    }
  }
}