.form-select {
  select {
    display: block;
    width: 100%;
    height: 40px;
    background-color: $bg-gray;
    border: none;
    color: $tag;
    padding: 0 $padding-md;
    outline: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%227.41%22%20viewBox%3D%220%200%2012%207.41%22%3E%0D%0A%20%20%3Cpath%20id%3D%22Path_54%22%20data-name%3D%22Path%2054%22%20d%3D%22M8.59%2C16.59%2C13.17%2C12%2C8.59%2C7.41%2C10%2C6l6%2C6-6%2C6Z%22%20transform%3D%22translate%2818%20-8.59%29%20rotate%2890%29%22%20fill%3D%22%23546e7a%22/%3E%0D%0A%3C/svg%3E%0D%0A');
    background-repeat: no-repeat;
    background-position: 96% center;
    background-size: auto 10px;
    appearance: none;
    border-radius: 0;
    &.has-border {
      border: 1px solid $icon;
    }
  }
  &--white {
    select {
      background-color: $white;
    }
  }
}