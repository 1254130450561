.profile {
  &-columns {
    display: flex;
    margin: 0 -1*$padding-sm;
    &:not(:last-child){
      @include mq($from: desktop_xl) {
        margin-bottom: 90px;
      }
      @include mq(desktop, desktop_xl) {
        margin-bottom: 80px;
      }
      @include mq($until: desktop) {
        margin-bottom: 40px;
      }
    }
    @include mq($from: desktop) {

    }
    @include mq($until: desktop) {
      flex-direction: column;
    }
  }
  &-main {
    padding: 0 $padding-sm;
    width: 100%;
    @include mq($from: desktop) {
      max-width: calc( 2*100%/3 );
    }
  }
  &-section {
    &:not(:last-child){
      margin-bottom: 8px;
    }
    &--box {
      background: $white;

      @include mq($from: desktop_xl) {
        padding: $padding-xxl $padding-xxl 8px;
      }
      @include mq(tablet, desktop_xl) {
        padding: $padding-xl $padding-xl 8px;
      }
      @include mq(mobile, tablet) {
        padding: $padding-lg;
      }
      @include mq($until: mobile) {
        padding: $padding-md;
      }
    }
    &--columns {

      @include mq($from: tablet) {
        display: flex;
      }
    }
    &--col {

      @include mq($from: tablet) {
        width: 50%;
      }
      &.has-padding {

        @include mq($from: desktop_xl) {
          padding-right: 10%;
        }
        @include mq(tablet, desktop_xl) {
          padding-right: 8%;
        }
      }
    }
  }
}