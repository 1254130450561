.orders {

}

.orders_table {
  @include mq($until: mobile) {
    margin: 0 -1*$padding-md;
  }
  position: relative;

  &-head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &.sticky {
      position: fixed;
    }
    @include mq($from: tablet) {
      height: 52px;
    }
    @include mq($until: tablet) {
      height: 40px;
    }
    &--row {
      display: flex;
      align-items: center;
      height: 100%;
      @include mq($from: desktop) {
        margin: 0 -1*$padding-xl;
        .sticky & {
          margin: 0;
        }
      }
      @include mq(tablet, desktop) {
        margin: 0 -1*$padding-lg;
        .sticky & {
          margin: 0;
        }
      }
      @include mq(mobile, tablet) {
        margin: 0 -1*$padding-md;
        .sticky & {
          margin: 0;
        }
      }
      @include mq($until: mobile) {
        margin: 0 -1*$padding-md;
      }
      &:before {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: linear-gradient(to right, $grad-end 0%, $grad-begin 100%);
      }
      div {
        font-weight: 500;
        position: relative;
        line-height: 1.2;
        color: $white;
        text-align: left;
        @include mq($from: tablet) {
          padding: 0 $padding-xl;
          font-size: $font;
        }
        @include mq(mobile, tablet) {
          padding: 0 $padding-md;
          font-size: 12px;;
        }
        @include mq($until: mobile) {
          padding: 0 $padding;
          font-size: 10px;
        }
      }
    }
    .content {
      height: 100%;
    }
  }
  &-row {
    border-top: 8px solid $bg-gray;
    background: $white;
    td {

      @include mq($from: tablet) {
        height: 56px;
        padding: 0 $padding-xl;
      }
      @include mq(mobile, tablet) {
        height: 40px;
        padding: 0 $padding-md;
        font-size: 12px;
      }
      @include mq($until: mobile) {
        height: 32px;
        padding: 0 $padding;
        font-size: 10px;
      }
    }
  }
  &-table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
  }
  &-thead {
    color: $white;

    text-align: left;

    @include mq($from: tablet) {
      font-size: $font;
    }
    @include mq(mobile, tablet) {
      font-size: 12px;;
    }
    @include mq($until: mobile) {
      font-size: 10px;
    }
    th {
      opacity: 0;
      font-weight: 500;
      line-height: 1.2;
      @include mq($from: tablet) {
        height: 56px;
        padding: 0 $padding-xl;
      }
      @include mq(mobile, tablet) {
        height: 40px;
        padding: 0 $padding-md;
      }
      @include mq($until: mobile) {
        height: 40px;
        padding: 0 $padding;
      }
    }
  }
  &-cart {
    .cart_table {
      display: none;
      width: 100%;

      margin: 0 auto;
      @include mq($from: desktop) {
        max-width: calc(200%/3);
      }
      @include mq(tablet, desktop) {
        max-width: calc(200%/2.5);
      }
      table {

        @include mq($from: tablet) {
          margin: $padding-lg 0;
        }
        @include mq($until: tablet) {
          margin: $padding 0;
        }
      }
    }
  }
  &-expand_btn {

    margin-left: auto;

    @include mq($from: tablet) {
      margin-right: -1*$padding-xl;
    }
    @include mq(mobile, tablet) {
      margin-right: -1*$padding-md;
    }
    @include mq($until: mobile) {
      margin-right: -1*$padding;
    }
    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-style: solid solid none none;
      border-color: $white;
      border-width: 3px;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      transition: all 0.25s;
      transform: rotate(135deg) translate(-2px, 2px);
    }
    .opened & {
      &:after {
        transform: rotate(315deg) translate(-2px, 2px);
      }
    }
  }
}