.range-slider {
  &--inputs {
    display: flex;
    align-items: center;
    .dash {
      margin: 0 $padding;
      width: 16px;
      height: 1px;
      background-color: $icon;
      flex-shrink: 0;
    }
    input {
      appearance: none;
      align-items: center;
      width: 100%;
      height: 32px;
      background: $bg-gray;
      border: none;
      color: $tag;
      padding: 0 $padding-md;
      outline: none;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

}

.range {
  padding-top: $padding-lg;
  .noUi-target {
    height: 4px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: $icon;
    margin-bottom: $padding-md;
  }
  .noUi-tooltip {
    border: none;
    border-radius: 0;
    font-size: 12px;
    color: $tag;
    padding: 0;
    background: none;
  }
  .noUi-horizontal .noUi-handle {
    width: 4px;
    height: 16px;
    background: $sale;
    border: none;
    box-shadow: none;
    right: -2px !important;
    cursor: pointer;
    &:before,
    &:after {
      display: none;
    }
  }
}