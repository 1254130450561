.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &-auth {
    color: $link;
    @include mq($from: desktop_md) {
      width: $font-lg;
      height: $font-lg;
    }
    @include mq(mobile, desktop_md) {
      width: 28px;
      height: 28px;
    }
    @include mq($until: mobile) {
      width: 20px;
      height: 20px;
    }
  }
  &-view {
    color: $link;
    @include mq($from: desktop_md) {
      width: $font-lg;
      height: $font-lg;
    }
    @include mq($until: desktop_md) {
      width: 28px;
      height: 28px;
    }
  }
  &-favorite {
    color: $link;
    @include mq($from: desktop_md) {
      width: $font-lg;
      height: $font-lg;
    }
    @include mq(mobile, desktop_md) {
      width: 28px;
      height: 28px;
    }
    @include mq($until: mobile) {
      width: 20px;
      height: 20px;
    }
  }
  &-basket {
    color: $link;

    @include mq($from: desktop_md) {
      width: 28px;
      height: 28px;
    }
    @include mq(mobile, desktop_md) {
      width: $padding-lg;
      height: $padding-lg;
    }
    @include mq($until: mobile) {
      width: 18px;
      height: 18px;
    }
  }
  &-search {
    color: $link;
    @include mq($from: desktop_md) {
      width: 40px;
      height: 40px;
    }
    @include mq(desktop, desktop_md) {
      width: 32px;
      height: 32px;
    }
    @include mq($until: desktop) {
      width: 20px;
      height: 20px;
    }
  }
  &-like {
    color: transparent;
    width: $font-md;
    height: $font-md;
    transition: all 0.2s;
    @include hover(){
      color: $sale;
    }
    &.liked {
      color: $sale;
    }
  }
  &-like-grey {
    width: $font-md;
    height: $font-md;
    transition: all 0.2s;
    color: $icon;
    @include hover(){
      color: $sale;
    }
    &.liked {
      color: $sale;
    }
  }
  &-like-lg {
    color: transparent;

    transition: all 0.2s;
    @include mq($from: tablet) {
      width: 54px;
      height: 50px;
    }
    @include mq($until: tablet) {
      width: 30px;
      height: 28px;
    }
    @include hover(){
      color: $sale;
    }
    &.liked {
      color: $sale;
    }
  }
  &-close {
    color: $white;
    width: 40px;
    height: 40px;
  }
  &-remove {
    color: $icon;
    width: $font-md;
    height: $font-md;
  }
}